import {
    geti18nText,
    NyDataEdit,
    NyFileUpload,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, message, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import DeviceLogSelectTableIndex from './deviceTable';

const AdminDeviceLogEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('firmware.device.log.request.table.header'));
    const [uploadKey, setUploadKey] = useState<any>(Date.now());

    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [formId, setFormId] = useState<any>(null);
    const [selectedDevices, setSelectedDevices] = useState<any>(null);

    const onModalClose = () => {
        setFormId(null);
        setLoading(false);
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setFormId(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    function normalize(values: any) {
        let normalized = { ...values };
        normalized.device = { id: selectedDevices[0] };
        return normalized;
    }

    function onBeforeSave() {
        if (selectedDevices != undefined && selectedDevices.length > 0) {
            return true;
        }

        return false;
    }
    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE_LOG_REQUEST.EDIT}
            setValues={setValues}
            width={1600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalize}
            hideSubmitButton={false}
            hideActivationButtons={true}
            checkBeforeSave={onBeforeSave}
            {...props}
            hideButtons={false}
        >
            <DeviceLogSelectTableIndex setSelectedDevices={setSelectedDevices}></DeviceLogSelectTableIndex>
        </NyDataEdit>
    );
};

export default AdminDeviceLogEdit;
