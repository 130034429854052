import React, { useState, useEffect, ReactText, useRef, isValidElement } from 'react';
import { Table, Col, Row, Button, Divider, Typography, Tooltip } from 'antd';

import { useHistory, useLocation } from 'react-router-dom';
import { HotKeys } from 'react-hotkeys';
import { configure } from 'react-hotkeys';
import { ClearOutlined } from '@ant-design/icons';
import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { IModalComponent } from '@nybble/nyreact/build/ny-data-table/types';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

configure({
    ignoreTags: [],
});

export interface INyDataTable1 {
    rowKey?: string;
    url: string;
    columns: { [index: string]: any }[];
    headerTitle?: string;
    addNewButtonText?: string;
    showRecordModal?: boolean;
    readonly?: boolean;
    hidePagination?: boolean;
    hideButtons?: boolean;
    hideNewButton?: boolean;
    showRowSelection?: boolean;
    rowSelectionType?: 'checkbox' | 'radio';
    exportCSV?: boolean;
    colCSVCustomization?: {
        [index: string]: (a: any) => void;
    }[];
    colCSVHeaderType?: 'TITLE' | 'DATA_INDEX';
    CSVFileName?: string;
    csvColumns?: { title: string; dataIndex: string | string[] }[];
    addedData?: { [index: string]: any };
    scroll?: { [index: string]: any };
    fetchWhenChange?: number;
    exportRowsNumber?: number;
    setDefaultFilterValue?: () => { [index: string]: any }[];
    setDefaultCurrentPage?: number;
    setDefaultPageSize?: number;
    setDefaultSortOrder?: { order: string; orderType: string };
    setDefaultSelectedRow?: number;
    onNewItem?: () => void;
    modalComponent?: ({
        isModal,
        visible,
        setVisible,
        value,
        onSave,
        onSaveAndGetID,
        addedData,
    }: IModalComponent) => React.ReactElement;
    addedButtons?: (onPopupSave: () => void) => React.ReactNode;
    buttonsClassName?: string;
    rowSelectionModal?: (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => React.ReactNode;
    onRowSelect?: (record: { [index: string]: any }) => void;
    onRowMouseEnterHandler?: (record: { [index: string]: any }) => void;
    onRowMouseLeaveHandler?: (record: { [index: string]: any }) => void;
    onRowClickHandler?: (record: { [index: string]: any }) => void;
    onSaveAndGetID?: (record: React.Key) => void;
    onDataLoaded?: (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => void;
    antdTableProps?: { [index: string]: any };
    tableClassName?: string;
    setRowClassName?: (record: { [index: string]: any }) => string;
    defaultFilterDropdown?: string;
    onRowSelected?: (data: number) => void;
    setDefaultSelectedRowKeys?: ReactText[];
    shortcuts?: boolean;
    nyTestId?: string;
    selectOnSave?: boolean;
    rowSelectionProps?: any;
    tableRowSize?: SizeType;
    expandeble?: any;
    editProps?: any;
    idOpenLink?: boolean;
    expandedRows?: boolean;
    childrenColumnName?: string;
    showCleanTableFilters?: boolean;
    mustGetPopupContainer?: any;
    showSizeChanger?: boolean;
}

const NyDataTable = ({
    rowKey = 'id',
    url,
    columns,
    headerTitle,
    addNewButtonText = geti18nText('app.default.button.add'),
    addedData,
    scroll,
    showRecordModal = false,
    showRowSelection = false,
    rowSelectionType = 'checkbox',
    readonly = false,
    hidePagination = false,
    hideButtons = false,
    hideNewButton = false,
    exportCSV = false,
    addedButtons,
    buttonsClassName,
    rowSelectionModal,
    setDefaultFilterValue,
    setDefaultSortOrder,
    onNewItem,
    onRowSelect,
    onRowMouseEnterHandler,
    onRowMouseLeaveHandler,
    onRowClickHandler,
    onSaveAndGetID,
    modalComponent,
    onDataLoaded,
    fetchWhenChange = 0,
    setDefaultCurrentPage = 1,
    setDefaultPageSize = 10,
    antdTableProps = {},
    exportRowsNumber = 1000,
    tableClassName,
    setRowClassName,
    defaultFilterDropdown,
    setDefaultSelectedRow = 0,
    onRowSelected,
    setDefaultSelectedRowKeys,
    shortcuts = false,
    nyTestId = undefined,
    selectOnSave = true,
    rowSelectionProps,
    colCSVHeaderType = 'TITLE',
    colCSVCustomization = undefined,
    csvColumns = undefined,
    CSVFileName = undefined,
    tableRowSize = 'small',
    expandeble = undefined,
    editProps,
    idOpenLink = true,
    expandedRows = false,
    childrenColumnName = 'children',
    showCleanTableFilters = true,
    mustGetPopupContainer = false,
    showSizeChanger = true,
}: INyDataTable1) => {
    const [pagination, setPagination] = useState<any>({
        defaultCurrent: setDefaultCurrentPage,
        defaultPageSize: setDefaultPageSize,
        showSizeChanger: showSizeChanger,
        pageSizeOptions: ['10', '20', '50', '100'],
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });
    const [hasAddedData, setHasAddedData] = useState<any>(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupValue, setPopupValue] = useState(undefined);
    const [current, setCurrent] = useState<any>(undefined);
    const [pageSize, setPageSize] = useState<any>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
    const [fetchParams, setFetchParams] = useState({});
    const [selectedRow, setSelectedRow] = useState(setDefaultSelectedRow);
    const [showTooltip, setShowTooltip] = useState(false);
    const [dynamicColumns, setDynamicColumns] = useState<any>([...columns]);
    const [visible, setVisible] = useState(false);
    const initFetch = useRef(false);
    const { Title } = Typography;
    let history = useHistory();
    let location = useLocation();
    const timer = useRef<any>(null);
    const preventClick = useRef<any>(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
    const [expandAllRows, setExpandAllRows] = useState<boolean>(true);

    const onKeyDown = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
            setShowTooltip(true);
        }
    };

    const onKeyUp = (event: any) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            event.stopPropagation();
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        if (columns.find((column: any) => column.dataIndex == 'id') && idOpenLink) {
            dynamicColumns.map((item: any) => {
                if (item.dataIndex == 'id') {
                    item.render = (text: any, record: any) => {
                        if (record[rowKey]) {
                            return (
                                <Button
                                    type="link"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => {
                                        onRowSelectHandler(record);
                                    }}
                                    record-id={record[rowKey]}
                                    id={record[rowKey]}
                                >
                                    {text}
                                </Button>
                            );
                        }
                    };
                }
            });
        }

        /* table column title - zakomentirano zbog problemi */
        // dynamicColumns.map((item: any) => {
        //     if (item.title) {
        //         const tempTitle = item.title;
        //         item.title = (
        //             <>
        //                 <Tooltip placement="bottom" overlayStyle={{ maxWidth: '300px' }} title={tempTitle}>
        //                     <div
        //                         style={{
        //                             maxWidth: item.width
        //                                 ? item.width.includes('%')
        //                                     ? item.width < '10%'
        //                                         ? '40px'
        //                                         : `calc(105% - ${item.width})`
        //                                     : item.width <= '120px' || item.width <= 120
        //                                     ? isNaN(parseInt(item.width))
        //                                         ? `calc(${item.width} - 40px)` < '100px'
        //                                             ? `calc(${item.width} - 40px)`
        //                                             : `calc(${item.width} - 60px)`
        //                                         : `calc(${item.width}px - 40px)`
        //                                     : item.width >= '150px' || item.width >= 150
        //                                     ? item.width
        //                                     : '140px'
        //                                 : columns.length >= 5
        //                                 ? columns.length > 10
        //                                     ? '80px'
        //                                     : '190px'
        //                                 : '230px',
        //                         }}
        //                     >
        //                         <p
        //                             style={{
        //                                 whiteSpace: 'nowrap',
        //                                 overflow: 'hidden',
        //                                 textOverflow: 'ellipsis',
        //                                 marginBottom: '0rem',
        //                             }}
        //                         >
        //                             {tempTitle}
        //                         </p>
        //                     </div>
        //                 </Tooltip>
        //             </>
        //         );
        //     }
        // });
    }, []);

    useEffect(() => {
        if (setDefaultSelectedRowKeys) {
            setSelectedRowKeys(setDefaultSelectedRowKeys);
        }
    }, [setDefaultSelectedRowKeys]);

    useEffect(() => {
        if (shortcuts) {
            window.addEventListener('keydown', onKeyDown);
            window.addEventListener('keyup', onKeyUp);
            return () => {
                window.removeEventListener('keydown', onKeyDown);
                window.removeEventListener('keyup', onKeyUp);
            };
        }
    }, []);

    useEffect(() => {
        if (addedData) {
            setHasAddedData(true);
        }
    }, [addedData]);

    useEffect(() => {
        fetch(getDefaultFilterValue());
        initFetch.current = true;
    }, [hasAddedData]);

    useEffect(() => {
        if (fetchWhenChange > 0) {
            fetch(getDefaultFilterValue());
        }
        initFetch.current = false;
    }, [fetchWhenChange]);

    useEffect(() => {
        if (visible == false) {
            setVisible(true);
        }
    }, [visible]);

    function getDefaultFilterValue(reset?: boolean) {
        let sSearch: any = {};
        if (setDefaultFilterValue) {
            const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));
            if (stringUri.length > 0) {
                sSearch = { search: stringUri };
            }
        }

        if (setDefaultCurrentPage != undefined) {
            if (Object.keys(sSearch).length !== 0) {
                sSearch.offset = setDefaultCurrentPage - 1;
            } else {
                sSearch = { offset: setDefaultCurrentPage - 1 };
            }
        }

        if (setDefaultPageSize != undefined) {
            if (Object.keys(sSearch).length !== 0) {
                sSearch.max = setDefaultPageSize;
            } else {
                sSearch = { max: setDefaultPageSize };
            }
        }

        if (reset != undefined) {
            setVisible(false);
            setDefaultFilterValue
                ? (sSearch = { search: encodeURI(JSON.stringify(setDefaultFilterValue())) })
                : (sSearch = {
                      search: encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }])),
                  });
            sSearch.offset = setDefaultCurrentPage - 1;
            sSearch.max = setDefaultPageSize;
        }

        return sSearch;
    }

    function onPopupSave() {
        fetch(getDefaultFilterValue(true));
    }

    function handleTableChange(
        pagination: any,
        filters: Record<string, Object[] | null>,
        sorter: { [index: string]: any }
    ) {
        setPageSize(pagination.pageSize);
        setCurrent(pagination.current - 1);
        let params = {
            max: pagination.pageSize,
            offset: pagination.current - 1,
            ...getSort(sorter),
            ...getFilters(filters),
        };
        initFetch.current = true;
        fetch(params);
    }

    function getFilters(filters: Record<string, Object[] | null>) {
        let transformedFilters: any = [];

        if (filters) {
            let deleteKey: any = [];
            for (var key in filters) {
                if (filters.hasOwnProperty(key)) {
                    const ff: any = filters[key];
                    if (ff != null && ff.length > 0) {
                        if (ff[0].value === '') {
                            deleteKey.push(key);
                        } else {
                            transformedFilters.push({ field: key, ...ff[0] });
                        }
                    }
                }
            }
            if (setDefaultFilterValue) {
                const defaultFilter = setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    if (transformedFilters.find(({ field }: any) => field == dFilter['field']) === undefined) {
                        transformedFilters.push(dFilter);
                    }
                }
            }
            deleteKey.forEach((key: any) => {
                transformedFilters = transformedFilters.filter((filter: any) => filter.field !== key);
            });
            return { search: encodeURI(JSON.stringify(transformedFilters)) };
        } else {
            if (setDefaultFilterValue) {
                const defaultFilter = setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    transformedFilters.push(dFilter);
                }
                return { search: encodeURI(JSON.stringify(transformedFilters)) };
            }
            return undefined;
        }
    }

    function getSort(sorter: { [index: string]: any }) {
        if (sorter.field) {
            const sort = { order: undefined, orderType: '' };
            sort.order = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field;
            sort.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            return sort;
        } else {
            return undefined;
        }
    }

    function fetch(params: { [index: string]: any } = {}) {
        if (initFetch.current === true) {
            console.log('fetch return');
            //  return;
        }
        if (setDefaultSortOrder && !params.hasOwnProperty('order')) {
            params.order = setDefaultSortOrder.order;
            params.orderType = setDefaultSortOrder.orderType;
        }
        if (pageSize != null && params.max === undefined) {
            params.max = pageSize;
        }

        if (current != null && params.offset === undefined) {
            params.offset = current;
        }
        setFetchParams(params);
        setLoading(true);
        NyRequestResolver.requestGet(url, { ...params, ...addedData }).then((response) => {
            if (response && response.status === RESPONSE.OK) {
                const pageable = { ...pagination };
                if (response.data && typeof response.data !== 'string') {
                    const responseData: { [index: string]: any } | undefined = response.data;
                    pageable.total = responseData['totalSize'];
                    pageable.current = responseData['pageNumber'] + 1;
                    pageable.pageSize = responseData['size'];
                    setLoading(false);

                    setData(responseData.content);
                    if (expandedRows) {
                        setExpandedRowKeys(getExpandedAllRowKeys(responseData.content, []));
                    }
                    if (responseData.content && responseData.content.length > 0) {
                        if (responseData.content.filter((item: any) => item.id === selectedRow)[0] === undefined) {
                            setSelectedRow(responseData.content[0].id);

                            if (onRowSelected != undefined) {
                                onRowSelected(responseData.content[0].id);
                            }
                        }
                    }
                    setPagination(pageable);
                    if (onDataLoaded != undefined) {
                        onDataLoaded(responseData.content, params);
                    }
                }
                setVisible(true);
            } else {
                setData([]);
                if (expandedRows) {
                    setExpandedRowKeys([]);
                }
                setLoading(false);
                setVisible(true);
            }
        });
    }

    function onNewItemHandler() {
        if (onNewItem) {
            onNewItem();
        } else {
            if (showRecordModal) {
                setPopupValue(undefined);
                setPopupVisible(true);
            } else {
                if (history) {
                    history.push(location.pathname + '/create');
                }
            }
        }
    }

    function onRowSelectHandler(record: any) {
        if (readonly === true) {
            return;
        }

        if (onRowSelect) {
            onRowSelect(record);
        } else {
            if (record && record[rowKey]) {
                if (showRecordModal) {
                    setPopupValue(record);
                    setPopupVisible(true);
                } else {
                    if (history) {
                        history.push(location.pathname + '/' + record[rowKey]);
                    }
                }
            } else {
                console.log('Record has no id field, no handler set');
            }
        }
    }

    const onSelectChange = (selectedRowKeys: ReactText[], selectedRows: any) => {
        const list = selectedRows.filter(
            (item: any, i: any, a: any) => a.findIndex((item1: any) => item1.id === item.id) === i
        );
        const listKeys = selectedRowKeys.filter((item, pos, self) => {
            return self.indexOf(item) == pos;
        });
        setSelectedRowKeys(listKeys);
        setSelectedRows(list);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: rowSelectionType,
        getCheckboxProps: rowSelectionProps ? rowSelectionProps : undefined,
        preserveSelectedRowKeys: true,
    };

    function clearSelectedRowKeys() {
        setSelectedRowKeys([]);
    }

    const modalComponentOnSaveAndGetData = (data: any) => {
        if (showRowSelection && data && data?.id && selectOnSave) {
            setSelectedRowKeys([data.id]);
            setSelectedRows([data]);
        }
    };

    const selectRowOnClick = (data: any) => {
        if (showRowSelection && data) {
            if (rowSelectionType == 'checkbox') {
                setSelectedRowKeys([...selectedRowKeys, data.id]);
                setSelectedRows([...selectedRows, data]);
            } else {
                setSelectedRowKeys([data.id]);
                setSelectedRows([data]);
            }
        }
    };

    const getExpandedAllRowKeys = (data: any, keys: any) => {
        if (data && keys) {
            data.map((object: any) => {
                if (object.children) {
                    object.children.map((child: any) => {
                        keys.push(getExpandedAllRowKeys([child], keys));
                    });
                }
                keys.push(object.id);
            });
            return keys;
        }
    };

    useEffect(() => {
        if (!expandedRows) {
            setExpandedRowKeys(getExpandedAllRowKeys(data, []));
        } else {
            if (expandAllRows) {
                setExpandedRowKeys(getExpandedAllRowKeys(data, []));
            } else {
                setExpandedRowKeys([]);
            }
        }
    }, [expandedRows, expandAllRows]);

    const expand = (expanded: any, record: any) => {
        let newData: any = [...expandedRowKeys];
        if (expanded) {
            newData.push(record.id);
        } else {
            const index = newData.findIndex((item: any) => item === record.id);
            if (index > 1) {
                newData.splice(index, 1);
            }
        }
        setExpandedRowKeys(newData);
    };
    return (
        <>
            <>
                <HotKeys
                    keyMap={
                        shortcuts
                            ? {
                                  ADD_NEW_ITEM_BUTTON: ['F1', 'alt+n'],
                                  OPEN_FILTER_BUTTON: ['F3', 'alt+f'],
                                  TABLE_ARROW_UP: ['ArrowUp'],
                                  TABLE_ARROW_DOWN: ['ArrowDown'],
                                  TABLE_ARROW_LEFT: ['ArrowLeft'],
                                  TABLE_ARROW_RIGHT: ['ArrowRight'],
                                  TABLE_ENTER: ['Enter'],
                              }
                            : {}
                    }
                    handlers={{
                        ADD_NEW_ITEM_BUTTON: () => {
                            onNewItemHandler();
                        },
                        OPEN_FILTER_BUTTON: (event: any) => {
                            event.preventDefault();
                            if (
                                defaultFilterDropdown !== null &&
                                defaultFilterDropdown !== undefined &&
                                defaultFilterDropdown != ''
                            ) {
                                let filterButton: any = document.querySelector(
                                    `th.${defaultFilterDropdown} span.ant-dropdown-trigger`
                                );

                                let activeTh: any = document.querySelector(
                                    'span.ant-table-filter-trigger-container-open'
                                );
                                if (activeTh) {
                                    activeTh.querySelector('span.ant-dropdown-trigger').click();
                                    activeTh = activeTh.parentNode.parentNode;
                                    let allTh: any = document.querySelectorAll(
                                        'thead.ant-table-thead th.ant-table-cell:not(.ant-table-cell-scrollbar)'
                                    );
                                    if (allTh) {
                                        let index = Array.from(allTh).indexOf(activeTh);
                                        if (index >= 0) {
                                            index++;
                                            if (index > Array.from(allTh).length - 1) {
                                                index = 0;
                                            }
                                        }

                                        filterButton = allTh[index].querySelector('span.ant-dropdown-trigger');
                                    }
                                }

                                if (filterButton) {
                                    filterButton.click();
                                    filterButton.focus();
                                }
                            }
                        },
                        TABLE_ENTER: (event: any) => {
                            if (event.target.nodeName !== 'INPUT') {
                                let currRow: any = document.querySelector(
                                    'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                                );
                                if (currRow === null) {
                                    currRow = document.querySelector(
                                        'tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                                    );
                                }
                                if (currRow !== undefined && currRow !== null) {
                                    onRowSelectHandler({ id: parseInt(currRow.dataset.rowKey) });
                                }
                            }
                        },
                        TABLE_ARROW_UP: () => {
                            let list: any = document.querySelectorAll(
                                'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-table-row'
                            );
                            if (list.length === 0) {
                                list = document.querySelectorAll('tbody.ant-table-tbody > tr.ant-table-row');
                            }
                            let currRow: any = document.querySelector(
                                'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                            );
                            if (currRow === null) {
                                currRow = document.querySelector(
                                    'tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                                );
                            }
                            if (list.length > 0 && currRow !== undefined && currRow !== null) {
                                let nextRowId = 0;
                                Array.prototype.forEach.call(list, function (element, index) {
                                    if (element.dataset.rowKey === currRow.dataset.rowKey) {
                                        nextRowId = index;
                                    }
                                });
                                nextRowId--;
                                if (nextRowId < 0) {
                                    nextRowId = list.length - 1;
                                }
                                let nexRowKey = parseInt(list[nextRowId].dataset.rowKey);
                                setSelectedRow(nexRowKey);

                                if (onRowSelected != undefined) {
                                    onRowSelected(nexRowKey);
                                }
                            }
                        },
                        TABLE_ARROW_DOWN: () => {
                            let list: any = document.querySelectorAll(
                                'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-table-row'
                            );
                            if (list.length === 0) {
                                list = document.querySelectorAll('tbody.ant-table-tbody > tr.ant-table-row');
                            }

                            let currRow: any = document.querySelector(
                                'div.ant-tabs-tabpane-active tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                            );
                            if (currRow === null) {
                                currRow = document.querySelector(
                                    'tbody.ant-table-tbody > tr.ant-select-item-option-selected'
                                );
                            }

                            if (list.length > 0 && currRow !== undefined && currRow !== null) {
                                let nextRowId = 0;
                                Array.prototype.forEach.call(list, function (element, index) {
                                    if (element.dataset.rowKey === currRow.dataset.rowKey) {
                                        nextRowId = index;
                                    }
                                });
                                nextRowId++;
                                if (nextRowId > list.length - 1) {
                                    nextRowId = 0;
                                }
                                let nexRowKey = parseInt(list[nextRowId].dataset.rowKey);

                                setSelectedRow(nexRowKey);

                                if (onRowSelected != undefined) {
                                    onRowSelected(nexRowKey);
                                }
                            }
                        },
                        TABLE_ARROW_LEFT: () => {
                            let prevButton: any = document.querySelector(
                                'div.ant-tabs-tabpane-active li.ant-pagination-prev'
                            );
                            if (prevButton === null) {
                                prevButton = document.querySelector('li.ant-pagination-prev');
                            }
                            if (prevButton !== null) {
                                if (!prevButton.className.includes('ant-pagination-disabled')) {
                                    if (prevButton.children && prevButton.children.length > 0) {
                                        prevButton.children[0].click();
                                    }
                                }
                            }
                        },
                        TABLE_ARROW_RIGHT: () => {
                            let nextButton: any = document.querySelector(
                                'div.ant-tabs-tabpane-active li.ant-pagination-next'
                            );
                            if (nextButton === null) {
                                nextButton = document.querySelector('li.ant-pagination-next');
                            }
                            if (nextButton !== null) {
                                if (!nextButton.className.includes('ant-pagination-disabled')) {
                                    if (nextButton.children && nextButton.children.length > 0) {
                                        nextButton.children[0].click();
                                    }
                                }
                            }
                        },
                    }}
                >
                    {headerTitle && (
                        <Divider orientation="left" plain>
                            <Title level={5}>{headerTitle}</Title>
                        </Divider>
                    )}

                    {showCleanTableFilters && (
                        <Button
                            style={{
                                float: 'right',
                                marginRight: '8px',
                                marginBottom: '10px',
                            }}
                            size={'small'}
                            onClick={() => {
                                fetch(getDefaultFilterValue(true));
                            }}
                        >
                            {geti18nText('app.default.filter.clear.filters')}
                            <ClearOutlined />
                        </Button>
                    )}
                    {visible && (
                        <Table
                            className={tableClassName}
                            size={tableRowSize}
                            columns={dynamicColumns}
                            rowKey={(record: any) => record[rowKey]}
                            rowSelection={showRowSelection ? rowSelection : undefined}
                            pagination={hidePagination ? false : pagination}
                            dataSource={data}
                            expandable={expandeble}
                            loading={loading}
                            scroll={scroll}
                            onChange={handleTableChange}
                            defaultExpandAllRows={expandedRows ? expandAllRows : false}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded, record) => expand(expanded, record)}
                            childrenColumnName={childrenColumnName}
                            getPopupContainer={
                                mustGetPopupContainer ? (trigger: any) => trigger.parentElement : undefined
                            }
                            onRow={(record: any) => {
                                return {
                                    onDoubleClick: () => {
                                        clearTimeout(timer.current);
                                        preventClick.current = true;
                                        onRowSelectHandler(record);
                                    },
                                    onClick: () => {
                                        timer.current = setTimeout(() => {
                                            if (!preventClick.current) {
                                                setSelectedRow(record[rowKey]);
                                                if (onRowSelected != undefined) {
                                                    onRowSelected(record[rowKey]);
                                                }
                                                selectRowOnClick(record);
                                                return onRowClickHandler ? onRowClickHandler(record) : undefined;
                                            }
                                            preventClick.current = false;
                                        }, 200);
                                    },
                                    onMouseEnter: () => {
                                        return onRowMouseEnterHandler ? onRowMouseEnterHandler(record) : undefined;
                                    },
                                    onMouseLeave: () => {
                                        return onRowMouseLeaveHandler ? onRowMouseLeaveHandler(record) : undefined;
                                    },
                                };
                            }}
                            rowClassName={(record) => {
                                return record[rowKey] != null && record[rowKey] === selectedRow
                                    ? 'ant-select-item-option-selected'
                                    : setRowClassName != null
                                    ? setRowClassName(record)
                                    : '';
                            }}
                            ny-test-id={nyTestId}
                            {...antdTableProps}
                        />
                    )}
                </HotKeys>
                {showRowSelection &&
                    rowSelectionModal != undefined &&
                    rowSelectionModal(
                        selectedRowKeys.length > 0,
                        selectedRowKeys,
                        selectedRows,
                        onPopupSave,
                        clearSelectedRowKeys
                    )}
                {hideButtons !== true && (
                    <Row gutter={[0, 24]} className={buttonsClassName}>
                        <Col span={24}>
                            {hideNewButton !== true && (
                                <Tooltip
                                    placement="top"
                                    visible={showTooltip}
                                    title={geti18nText('app.default.shortcuts.addNewItem')}
                                >
                                    <Button
                                        ny-test-id={nyTestId ? nyTestId + '-btn-new' : undefined}
                                        type="primary"
                                        onClick={() => onNewItemHandler()}
                                    >
                                        {addNewButtonText}
                                    </Button>
                                </Tooltip>
                            )}
                            {expandedRows && (
                                <Button
                                    onClick={() => {
                                        setExpandAllRows(!expandAllRows);
                                    }}
                                    type="primary"
                                    style={{ float: 'left', marginRight: '8px' }}
                                >
                                    {geti18nText(
                                        expandAllRows ? 'businessUnit.table.expand.not' : 'businessUnit.table.expand'
                                    )}
                                </Button>
                            )}
                            {addedButtons != undefined && addedButtons(onPopupSave)}
                        </Col>
                    </Row>
                )}
                {showRecordModal
                    ? modalComponent != undefined &&
                      popupVisible &&
                      React.createElement(
                          modalComponent,
                          {
                              isModal: true,
                              visible: popupVisible,
                              setVisible: setPopupVisible,
                              value: popupValue,
                              onSave: onPopupSave,
                              onSaveAndGetID: onSaveAndGetID,
                              onSaveAndGetData: modalComponentOnSaveAndGetData,
                              addedData: addedData,
                              editProps: editProps,
                          },
                          null
                      )
                    : null}
            </>
        </>
    );
};

export default NyDataTable;
