import { geti18nText, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, Row, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormatedDatePicker } from '../../../components/formated-date-picker/formatedDatePicker';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum } from '../../../utils/Enums';

export interface IHnbDeviceEdit {
    deviceId: number;
    useParser: boolean;
    refresh: any;
}

const HnbDeviceEdit: React.FC<IHnbDeviceEdit> = ({ deviceId, useParser, refresh }: IHnbDeviceEdit) => {
    const [contStateValid, setCountStateValid] = useState<any>(null);
    const [contStateValidMsg, setCountStateValidMsg] = useState<string>('');
    const [currentCountState, setCurrentCountState] = useState<number>(0);
    useEffect(() => {
        if (deviceId != null && useParser == false) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.VALIDATE_COUNT_STATE + '/' + deviceId).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data != null) {
                            const responseData: number = result.data;
                            setCurrentCountState(responseData);
                        }
                    }
                }
            );
        }
    }, [useParser, deviceId, refresh]);

    const deviceLoction = () => {
        const aTypes = GetEnum({ enumName: 'HNB_DEVICE_LOCATION' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: '', text: '' };
                ret.id = aTypes[key];
                ret.text = geti18nText('hnb.device.location.' + aTypes[key]);
                types.push(ret);
            }
        }
        return types;
    };

    function validateCountState(e: any) {
        if (e.target.value != null && e.target.value != '') {
            const value = parseInt(e.target.value);

            if (currentCountState > value) {
                const d = currentCountState - value;
                setCountStateValidMsg(geti18nText('device.edit.hnb.reduced.validator.msg') + ' ' + d);
                setCountStateValid(false);
            } else if (currentCountState == value) {
                setCountStateValidMsg('');
                setCountStateValid(null);
            } else {
                const d = value - currentCountState;
                setCountStateValidMsg(geti18nText('device.edit.hnb.increased.validator.msg') + ' ' + d);
                setCountStateValid(true);
            }
        } else {
            setCountStateValidMsg('');
            setCountStateValid(null);
        }
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('device.edit.hnb.daily_report')}
                        name="dailyReport"
                        valuePropName="checked"
                        style={{}}
                    >
                        <Switch></Switch>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('device.edit.hnb.monthly_report')}
                        name="monthlyReport"
                        valuePropName="checked"
                        style={{ marginLeft: '-25px' }}
                    >
                        <Switch></Switch>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('hnb.device.table.hnb_device_code')}
                        name={['hnbDevice', 'hnbDeviceCode']}
                    >
                        <Input
                            disabled={
                                NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                            }
                        />
                    </Form.Item>
                </Col>

                <Col span={0}>
                    <Form.Item
                        label={geti18nText('hnb.device.table.device.id')}
                        name={['hnbDevice', 'id']}
                        hidden={true}
                    >
                        <Input readOnly={true} disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('hnb.device.table.hnb_device_type')}
                        name={['hnbDevice', 'hnbDeviceType']}
                    >
                        <NySearchField
                            disabled={
                                NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                            }
                            //options={deviceTypes()}
                            url={CONSTANTS_REQ.HNB_DEVICE_TYPE.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('hnb.device.table.hnb_device_location')}
                        name={['hnbDevice', 'hnbDeviceLocation']}
                    >
                        <NySearchField
                            disabled={
                                NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                            }
                            options={deviceLoction()}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('hnb.device.table.software_eur')} name={['hnbDevice', 'softwareEur']}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={geti18nText('hnb.device.table.software_hr')} name={['hnbDevice', 'softwareHr']}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('hnb.device.table.count_state')} name={'countState'}>
                        <Input readOnly={!useParser} disabled={!useParser} />
                    </Form.Item>
                </Col>
                {useParser && (
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('hnb.device.table.program_count_state')}
                            name={'programCountState'}
                        >
                            <Input readOnly={true} disabled={true} />
                        </Form.Item>
                    </Col>
                )}

                {!useParser && currentCountState && (
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('hnb.device.table.program_count_state')}
                            name={'countState'}
                            // rules={[{ validator: validateCountState }]}
                        >
                            <Input onChange={(value) => validateCountState(value)} defaultValue={currentCountState} />
                        </Form.Item>
                        <div style={{ textAlign: 'center', marginLeft: '-44%', marginTop: -5 }}>
                            {contStateValid != null ? (
                                contStateValid == true ? (
                                    <span style={{ color: '#52c41a' }}>{contStateValidMsg}</span>
                                ) : (
                                    <span style={{ color: 'red' }}>{contStateValidMsg}</span>
                                )
                            ) : undefined}
                        </div>
                    </Col>
                )}
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('device.edit.hnb.count_state_change')}
                        name={'countStateChange'}
                        required={true}
                    >
                        <FormatedDatePicker
                            showTime={true}
                            defaultValue={moment()}
                            readOnly={useParser}
                            disabled={useParser}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={geti18nText('hnb.device.maintenance_date')}
                        name={['hnbDevice', 'maintenanceDate']}
                    >
                        <FormatedDatePicker showTime={true} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default HnbDeviceEdit;
