import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import TableSettings from '../../table_settings';
import HnbDeviceTypeEdit from './edit';

const HnbDeviceTypeIndex = () => {
    const { TabPane } = Tabs;
    const table = TableSettings();
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('hnb.device.type.table.header')}
            url={CONSTANTS_REQ.HNB_DEVICE_TYPE.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('hnb.device.type.table.new.btn')}
            hideButtons={!NySession.hasAnyRole(['RULE_ADD_DEVICE', 'RULE_UPDATE_LIMITED_DEVICE', 'RULE_UPDATE_DEVICE'])}
            modalComponent={HnbDeviceTypeEdit}
            exportCSV={true}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            columns={[
                {
                    title: geti18nText('hnb.device.type.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('hnb.device.type.table.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('hnb.device.type.table.code'),
                    dataIndex: 'code',
                    width: '5%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },

                {
                    title: geti18nText('hnb.device.type.tabl.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]}
        />
    );
};

export default HnbDeviceTypeIndex;
