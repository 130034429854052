import { getColumnDateOption, getColumnSearch, geti18nText, NyDataEdit, NyDataTable, NyUtils } from '@nybble/nyreact';
import { Form, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { formatCurrency, formatCurrencyById, getTimezone } from '../../utils/Utils';
import CountSerialIndex from '../serial';

const CountingDetailSerialIndex = (props: any) => {
    function onCancelCountDetailsVisible() {
        props.setVisible(false);
    }

    function csvFileName() {
        const date = new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return geti18nText('counting.details.header') + '_' + props.id + '_' + formatDate;
    }

    return (
        <Modal
            visible={props.visible}
            title={
                geti18nText('counting.details.header') +
                (props.id ? ' / ' + props.id : '') +
                (props.operator && props.operator != '' ? ' / ' + props.operator : '')
            }
            width={1500}
            footer={null}
            onCancel={onCancelCountDetailsVisible}
            destroyOnClose={true}
        >
            <NyDataTable
                url={CONSTANTS_REQ.COUNT_SERIAL.COUNT_LIST_BY_SERIAL}
                showRecordModal={false}
                hideButtons={true}
                setDefaultPageSize={20}
                readonly
                exportCSV={true}
                colCSVCustomization={DetailsCountingCSVColCustomization()}
                CSVFileName={csvFileName()}
                addedData={{ deviceId: props.deviceId, serial: props.serial }}
                columns={[
                    {
                        title: geti18nText('counting.details.table.countDataId'),
                        dataIndex: 'countDataId',
                        width: '5%',
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.value'),
                        dataIndex: 'valueExport',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.value) {
                                return (
                                    <>
                                        <div>{formatCurrencyById(record.value, record.currency_id)}</div>
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.sum'),
                        dataIndex: 'sumExport',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.sum) {
                                return (
                                    <>
                                        <div>{formatCurrencyById(record.sum, record.currency_id)}</div>
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.fit'),
                        dataIndex: 'fit',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.atm'),
                        dataIndex: 'atm',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.tlr'),
                        dataIndex: 'tlr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.unfit'),
                        dataIndex: 'unfit',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.manual'),
                        dataIndex: 'manual',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.unsorted'),
                        dataIndex: 'unsorted',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.rejected'),
                        dataIndex: 'rejected',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.net'),
                        dataIndex: 'net',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('counting.details.table.series'),
                        dataIndex: 'series',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },

                    {
                        title: geti18nText('counting.details.table.start.time'),
                        dataIndex: 'startTime',
                        width: '15%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.startTime) {
                                return (
                                    <div>
                                        {new Date(record.startTime).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('counting.details.table.end.time'),
                        dataIndex: 'endTime',
                        width: '15%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.endTime) {
                                return (
                                    <div>
                                        {new Date(record.endTime).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('count.data.table.created'),
                        dataIndex: 'created',

                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.created) {
                                return (
                                    <div>
                                        {new Date(record.created).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                ]}
            />
        </Modal>
    );
};

export const DetailsCountingCSVColCustomization: any = () => {
    return [
        {
            valueExport: (value: any) => {
                return formatCurrencyById(value.value, value.currency_id);
            },
        },
        {
            sumExport: (value: any) => {
                return formatCurrencyById(value.sum, value.currency_id);
            },
        },
        {
            fit: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            unsorted: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            atm: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            tlr: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            unfit: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            rejected: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            startTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
        {
            endTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
    ];
};

export default CountingDetailSerialIndex;
