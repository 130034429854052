//TODO: change server url
export const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/' : '/api/';

export const APPLICATION_KEY = 'BTCMonitor';

export const CONSTANTS_REQ = {
    DEVICE: {
        LIST: URL_PREFIX + 'device/listDevice',
        LIST_BY_COMPANY: URL_PREFIX + 'device/listByCompany',
        EDIT: URL_PREFIX + 'device/edit',
        CHECK_PORT_IP: URL_PREFIX + 'device/checkIpPort',
        CHECK_IP: URL_PREFIX + 'device/checkIp',
        CHECK_SERIAL_INVENTORY: URL_PREFIX + 'device/checkSerialInventory',
        SEARCH: URL_PREFIX + 'device/search',
        GET_DEVICE_INFO: URL_PREFIX + 'device/deviceInfoUpdated',
        VALIDATE_INVENTORY_NUMBER: URL_PREFIX + 'device/validateInventoryNumber',
        EXPORT_SERVICE_INFORMATION: URL_PREFIX + 'device/exportServiceInformation',
        VALIDATE_COUNT_STATE: URL_PREFIX + 'device/validateCountState',
        IMPORT_DEVICE: URL_PREFIX + 'device/importDevice',
        DEVICE_LIVE_COUNTING: URL_PREFIX + 'device/deviceLiveCounting',
        DEVICE_STOP_COUNTING: URL_PREFIX + 'device/deviceStopCounting',
        LIVE_START_TRANSACTION: URL_PREFIX + 'device/startTransactionCommand',
        LIVE_END_TRANSACTION: URL_PREFIX + 'device/endTransactionCommand',
        LIVE_CANCEL_TRANSACTION: URL_PREFIX + 'device/cancelTransactionCommand',
        LIVE_START_COUNT: URL_PREFIX + 'device/startCountCommand',
        SET_CURRENCY: URL_PREFIX + 'device/setCurrency',
        SET_PATTERN: URL_PREFIX + 'device/setPattern',
        CHANGE_PATTERN: URL_PREFIX + 'device/changePattern',
        COUNTING_TYPE: URL_PREFIX + 'device/setCountingType',
        

    },
    DEVICE_ARCHIVE: {
        LIST: URL_PREFIX + 'device_archive/list',
        LIST_PER_DAY: URL_PREFIX + 'device_archive/listPerDay',
        IP_FROM_RANGE: URL_PREFIX + 'device_archive/devicesLocationIp',
    },
    DEVICE_GROUP: {
        LIST: URL_PREFIX + 'deviceGroup/list',
        EDIT: URL_PREFIX + 'deviceGroup',
        SEARCH: URL_PREFIX + 'deviceGroup/search',
        DELETE_DEVICES_FROM_GROUP: URL_PREFIX + 'deviceGroup/deleteFromGroup',
        ADD_DEVICES_TO_GROUP: URL_PREFIX + 'deviceGroup/addToGroup',
    },
    USER: {
        LIST: URL_PREFIX + 'users/listAllUsers',
        LIST_BY_GROUP: URL_PREFIX + 'users/listByGroup',
        EDIT: URL_PREFIX + 'users/edit',
        RESET: URL_PREFIX + 'users/reset',
        FIND_BY_USERNAME: URL_PREFIX + 'users/by/',
        DISABLE_2FA: URL_PREFIX + 'users/disable',
        USERNAME_EXIST: URL_PREFIX + 'users/username_validate',
        SEARCH: URL_PREFIX + 'users/search',
        WIDGETS: URL_PREFIX + 'users/widgets',
    },
    ROLE: {
        LIST: URL_PREFIX + 'role/list',
        EDIT: URL_PREFIX + 'role/edit',
        SEARCH: URL_PREFIX + 'role/search',
    },
    USER_GROUP: {
        LIST: URL_PREFIX + 'user_group/list',
        EDIT: URL_PREFIX + 'user_group/edit',
        SEARCH: URL_PREFIX + 'user_group/search',
    },
    COMPANY: {
        LIST: URL_PREFIX + 'company/list',
        EDIT: URL_PREFIX + 'company/edit',
        SEARCH: URL_PREFIX + 'company/search',
    },
    DEVICE_COMPANY: {
        DELETE_DEVICES_FROM_COMPANY: URL_PREFIX + 'deviceCompany/deleteFromCompany',
        ADD_DEVICES_TO_COMPANY: URL_PREFIX + 'deviceCompany/addToCompany',
    },
    LOCATION: {
        LIST: URL_PREFIX + 'location/list',
        EDIT: URL_PREFIX + 'location',
        SEARCH: URL_PREFIX + 'location/search',
        LIST_ALL: URL_PREFIX + 'location/listAll',
    },
    DEVICE_LOCATION_HISTORY: {
        LIST: URL_PREFIX + 'deviceLocationHistory/listByDevice',
    },
    REFERENCES: {
        LIST_BY_DEVICE: URL_PREFIX + 'references/listByDevice',
        EDIT: URL_PREFIX + 'references',
        DELETE: URL_PREFIX + 'references',
    },
    ENUMS: URL_PREFIX + 'enums/',
    NOTIFICATION_ALARM: {
        LIST: URL_PREFIX + 'notification-alarm/list',
        EDIT: URL_PREFIX + 'notification-alarm/saveAlarm',
    },
    MANUFACTURER: {
        LIST: URL_PREFIX + 'manufacturer/list',
        EDIT: URL_PREFIX + 'manufacturer',
        SEARCH: URL_PREFIX + 'manufacturer/search',
    },
    CURRENCY: {
        LIST: URL_PREFIX + 'currency/list',
        EDIT: URL_PREFIX + 'currency',
        SEARCH: URL_PREFIX + 'currency/search',
        SEARCH_FOR_SETTINGS: URL_PREFIX + 'currency/searchForSettings',
        EXPORT: URL_PREFIX + 'currency/export',
        IMPORT: URL_PREFIX + 'currency/import',
    },
    CURRENCY_DENOMINATIONS: {
        LIST: URL_PREFIX + 'currencyDenominations/list',
        EDIT: URL_PREFIX + 'currencyDenominations',
        SEARCH: URL_PREFIX + 'currencyDenominations/search',
    },
    DEVICE_MODEL: {
        LIST: URL_PREFIX + 'deviceModel/list',
        EDIT: URL_PREFIX + 'deviceModel',
        EDIT_IMAGE: URL_PREFIX + 'deviceModel/edit',
        SEARCH: URL_PREFIX + 'deviceModel/search',
    },
    COUNTING: {
        LIST: URL_PREFIX + 'counting/list',
        LIST_GROUPING: URL_PREFIX + 'counting/listGroup',
    },
    REPORT_COUNTING: {
        LIST: URL_PREFIX + 'reportContData/list',
        EDIT: URL_PREFIX + 'reportContData',
    },
    DEVICE_INFORMATION: {
        LIST: URL_PREFIX + 'device_information/list',
    },
    COUNTING_DETAILS: {
        LIST: URL_PREFIX + 'countingDetails/list',
        LIST_GROUPED: URL_PREFIX + 'countingDetails/listGrouped',
    },
    NOTIFICATION: {
        LIST: URL_PREFIX + 'notification/list',
        EDIT: URL_PREFIX + 'notification',
        SEARCH: URL_PREFIX + 'notification/search',
        COUNT: URL_PREFIX + 'notification/count',
        DELETE: URL_PREFIX + 'notification/delete',
        DELETE_ALL: URL_PREFIX + 'notification/delete/all',
        READ_ALL: URL_PREFIX + 'notification/read/all',
        GET_ALL: URL_PREFIX + 'notification/getNotification',
        GET_BY_TYPE: URL_PREFIX + 'notification/getNotificationByType',
    },
    INSTANCES: {
        LIST: URL_PREFIX + 'instances/list',
        INIT: URL_PREFIX + 'instances/init',
        REINIT: URL_PREFIX + 'instances/reInit',
        SEND_NOTIFICATION: URL_PREFIX + 'instances/sendNotification/',
        INIT_INSTANCE: URL_PREFIX + 'instances/initInstance',
        CHANGE_INSTANCE_NUMBER: URL_PREFIX + 'instances/changeInstanceNumber',
        RESTART_CONTAINER: URL_PREFIX + 'instances/restartContainer',
    },
    DEVICE_ERROR: {
        LIST: URL_PREFIX + 'device_error/list',
    },

    HNB_DEVICE: {
        LIST: URL_PREFIX + 'hnb_device/list',
        EDIT_BY_DEVICE: URL_PREFIX + 'hnb_device/byDevice',
    },
    HNB_DEVICE_HISTORY: {
        LIST: URL_PREFIX + 'hnb_device_history/list',
        EDIT: URL_PREFIX + 'hnb_device_history',
        UPDATE_COUNT_STATE: URL_PREFIX + 'hnb_device_history/updateCountState',
    },

    HNB_SNAPSHOT_HISTORY: {
        LIST: URL_PREFIX + 'hnb_snapshot/list',
        EDIT: URL_PREFIX + 'hnb_snapshot',
        LAST_UPDATE: URL_PREFIX + 'hnb_snapshot/last_update',
    },

    DASHBOARD: {
        DEVICE_NUMBER: URL_PREFIX + 'dashboard/deviceNumber',
        COUNTING_STATS_BY_PERIOD: URL_PREFIX + 'dashboard/countingStatsByPeriod',
        ERROR_STATS_BY_PERIOD: URL_PREFIX + 'dashboard/errorStatsByPeriod',
        DEVICE_ERROR: URL_PREFIX + 'dashboard/deviceError',
        DEVICES_INFO: URL_PREFIX + 'dashboard/devicesInfo',
    },
    STATISTIC: {
        COUNT: URL_PREFIX + 'statistic/count',
        SUM: URL_PREFIX + 'statistic/sum',
        SUM_BY_LIST: URL_PREFIX + 'statistic/sumByList',
        TIME: URL_PREFIX + 'statistic/time',
        ERROR: URL_PREFIX + 'statistic/error',
        ERROR_BY_LIST: URL_PREFIX + 'statistic/errorByList',
        TIME_LIST: URL_PREFIX + 'statistic/timeByList',
        COUNT_BY_LIST: URL_PREFIX + 'statistic/countByList',
    },
    VENDOR: {
        LIST: URL_PREFIX + 'vendor/list',
        EDIT: URL_PREFIX + 'vendor',
        SEARCH: URL_PREFIX + 'vendor/search',
    },
    SERVICE_VENDOR: {
        LIST: URL_PREFIX + 'serviceVendor/list',
        EDIT: URL_PREFIX + 'serviceVendor',
        SEARCH: URL_PREFIX + 'serviceVendor/search',
    },
    HNB_DEVICE_TYPE: {
        LIST: URL_PREFIX + 'hnbDeviceType/list',
        EDIT: URL_PREFIX + 'hnbDeviceType',
        SEARCH: URL_PREFIX + 'hnbDeviceType/search',
        LIST_ALL: URL_PREFIX + 'hnbDeviceType/listAll',
    },
    DEVICE_MODEL_SETTINGS_DENOMINATIONS: {
        LIST: URL_PREFIX + 'deviceSettingDenominations/list',
        EDIT: URL_PREFIX + 'deviceSettingDenominations',
        SEARCH: URL_PREFIX + 'deviceSettingDenominations/search',
        DELETE: URL_PREFIX + 'deviceSettingDenominations/delete',
    },
    DEVICE_SETTINGS_DENOMINATIONS_HISTORY: {
        LIST: URL_PREFIX + 'deviceSettingDenominationsHistory/list',
    },

    SETTINGS: {
        MAIL: URL_PREFIX + 'mailSettings/edit',
        TEST_MAIL: URL_PREFIX + 'mailSettings/testMail',
        DEVICES_WORK_TIME: URL_PREFIX + 'mailSettings/workTime',
    },
    LOGS: {
        LIST: URL_PREFIX + 'logs/list',
        DOWNLOADALL: URL_PREFIX + 'logs/downloadAllLogsAsZip',
        DOWNLOADLOGFILE: URL_PREFIX + 'logs/downloadLogFile',
    },
    SERVICE_INFORMATION: {
        IMPORT_DATA: URL_PREFIX + 'device/importServiceInformation',
    },
    MODS: {
        LIST: URL_PREFIX + 'mods/list',
        EDIT: URL_PREFIX + 'mods',
        DELETE: URL_PREFIX + 'mods/delete',
        FIND_BY_NAME: URL_PREFIX + 'mods/findByName',
        SEARCH: URL_PREFIX + 'mods/search',
    },
    INIT: {
        VALID_DB: URL_PREFIX + 'init/valid_db',
        LANG_INIT: URL_PREFIX + 'init/lang',
    },
    LICENSE: {
        LICENSE_INFO: URL_PREFIX + 'license/info',
        LICENSE_INFO_READ: URL_PREFIX + 'license/info-read',
        CERT_UPLOAD: URL_PREFIX + 'license/upload',
    },
    APPLICATION_SETTINGS:{
        LIST: URL_PREFIX + "applicationSettings/list",
        UPLOAD_IMAGE: URL_PREFIX + "applicationSettings/uploadAppLogo",
        APP_LOGO: URL_PREFIX + "applicationSettings/appLogo"
    },
    FIRMWARE: {
        LIST: URL_PREFIX + 'firmware/file/list',
        EDIT: URL_PREFIX + 'firmware/file/edit', 
        DOWNLOAD: URL_PREFIX + 'firmware/file/download', 
        SEARCH: URL_PREFIX + 'firmware/file/searchFirmwareFiles',
        DELETE: URL_PREFIX + "firmware/file/deleteFile"
    },
    FIRMWARE_UPDATE: {
        LIST: URL_PREFIX + 'firmware/update/list',
        EDIT: URL_PREFIX + 'firmware/update', 
        START: URL_PREFIX + 'firmware/update/start',
        DEVICE_LIST: URL_PREFIX + "firmware/update/listDevice",
        DEVICE_UPDATE_LIST: URL_PREFIX + "firmware/update/listDeviceUpdates",
        CHANGE_STATUS: URL_PREFIX + 'firmware/update/changeStatus',
        CHANGE_STATUS_DEVICE: URL_PREFIX + 'firmware/update/changeStatus/device',
        LIVE_PROGRESS_COUNTING: URL_PREFIX + 'firmware/update/firmwareUpdateProgress',
        STOP_PROGRESS_COUNTING: URL_PREFIX + 'devfirmware/update/stopFirmwareUpdateProgress',
    },
    DEVICE_LOG_REQUEST: {
        LIST: URL_PREFIX + 'device/logRequest/list',
        EDIT: URL_PREFIX + 'device/logRequest',
        DEVICE_LIST: URL_PREFIX + "device/listDevice", 
        DOWNLOAD: URL_PREFIX + 'device/logRequest/download', 
    },
    COUNT_SERIAL: {
        LIST: URL_PREFIX + 'countSerial/list',
        LIST_BY_DEVICE: URL_PREFIX + 'countSerial/listByDevice',
        LIST_BY: URL_PREFIX + 'countSerial/listBy',
        COUNT_LIST_BY_SERIAL: URL_PREFIX + 'countSerial/countsBySerial',
        COUNTING_DATA_CSV: URL_PREFIX + 'countSerial/countDataCsv',
        
    }

};
