const DEFAULT_NOTIFICATION_SETTINGS = [
    {
        color: '#EE887A',
        sort: 8,
        notificationType: 8,
        id: 8,
    },
    {
        color: '#EE887A',
        sort: 7,
        notificationType: 7,
        id: 7,
    },
    {
        color: '#ecc079',
        sort: 6,
        notificationType: 6,
        id: 6,
    },
    {
        mail: false,
        color: '#edf051',
        sort: 4,
        notificationType: 5,
        id: 5,
    },
    {
        color: '#a6d4ae',
        sort: 5,
        notificationType: 4,
        id: 4,
    },
    {
        mail: false,
        color: '#80d4ff',
        sort: 1,
        notificationType: 3,
        id: 3,
    },
    {
        color: '#80d4ff',
        sort: 2,
        notificationType: 2,
        id: 2,
    },
    {
        color: '#80d4ff',
        sort: 3,
        notificationType: 1,
        id: 1,
    },
];

export default DEFAULT_NOTIFICATION_SETTINGS;
