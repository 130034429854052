import { NyDataTable, geti18nText } from '@nybble/nyreact';
import table from 'antd/lib/table';

import { ReactText, useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import TableSettings from '../../table_settings';
import DeviceColumns from '../device/columns';

const DeviceLogSelectTableIndex = ({ setSelectedDevices }: any) => {
    let clearKeys: any;

    function setDefaultFilterValue() {
        let list = [
            { field: '_d.active', condition: 'equals_bool', value: 1 },
            { field: '_d.useParser', condition: 'equals_bool', value: 1 },
        ];

        return list;
    }
    const table = TableSettings();

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setSelectedDevices(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('firmware.device.log.request.edit.device')}
            url={CONSTANTS_REQ.DEVICE.LIST}
            showRecordModal={false}
            hideButtons={false}
            hideNewButton={true}
            readonly={true}
            scroll={{ x: 1200 }}
            columns={DeviceColumns({ isAdmin: true })}
            showRowSelection={true}
            rowSelectionModal={setSelectedValuesFunc as any}
            rowSelectionType={'radio'}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            //   addedData={{ firmwareUpdateId: firmwareUpdateId }}
        />
    );
};
export default DeviceLogSelectTableIndex;
