import {
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Alert, Button, Col, Descriptions, Form, Input, message, notification, Row } from 'antd';
import React, { ReactText, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../../utils/Enums';
import NyFileUpload from '../../../../components/file-upload/index_file';
import FirmwareFileSelect from './selectFirmwareFile';
import DeviceColumns from '../../device/columns';
import TableSettings from '../../../table_settings';
import DeviceTableIndex from './deviceTable';
import { getTimezone } from '../../../../utils/Utils';
import SSE from '../../../../components/layout-main/see';
import { v4 as uuidv4 } from 'uuid';
import FirmwareUploadProgress from './progress';
const AdminFirmwareUpdateEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('firmware.update.table.header'));

    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [formId, setFormId] = useState<any>(null);
    const [selectedDevices, setSelectedDevices] = useState<any>(null);
    const [showSelectedDeviceError, setShowSelectedDeviceError] = useState<any>(false);
    const [deviceModel, setDeviceModel] = useState<any>(null);
    const [valuesForm, setValuesForm] = useState<any>(null);
    const [deleted, setDeleted] = useState(false);
    const closeMolda = useRef<any>(false);
    const eventSourceRef = useRef<any>(null);
    const deviceLivePreviewId = useRef<any>(null);

    useEffect(() => {
        if (formId != undefined) {
            //  startListeningForUpdateProgressCounting();
        }
        return () => {
            //closeMolda.current = true;
            //eventSourceRef.current?.close();
            //stopLiveCounting(deviceLivePreviewId?.current);
        };
    }, [formId]);
    const onModalClose = () => {
        setFormId(null);
        setLoading(false);
        setEditHeader(geti18nText('firmware.update.table.header'));
        setDeviceModel(null);
        setSelectedDevices([]);
        setValuesForm(null);
        setDeleted(false);
        form.resetFields();
    };
    function setValues(dataForm: any) {
        // dataForm.communicationType = {
        //     id: dataForm.communicationType,
        //     text: GetEnumNameForValue({ enumName: 'COMMUNICATION_TYPE', value: dataForm.communicationType }),
        // };
        if (dataForm.deviceModel != null) {
            dataForm.deviceModel = { id: dataForm.deviceModel.id, name: dataForm.deviceModel.name };
        }
        dataForm.fileName = {
            id: dataForm.firmwareFile.id,
            name: dataForm.firmwareFile.name,
        };
        if (dataForm.firmwareFile?.status == 1) {
            setDeleted(true);
        }
        setFormId(dataForm.id);
        setValuesForm(dataForm);
        form.setFieldsValue(dataForm);
    }

    function normalize(values: any) {
        let normalized = { ...values };
        if (values.deviceModel) {
            normalized.deviceModel = { id: values.deviceModel.id };
        }
        normalized.deviceIds = selectedDevices;
        normalized.firmwareFile = { id: normalized.fileName.id };
        return normalized;
    }

    function customButtons() {
        if (formId != null) return null;
        return [
            {
                text: geti18nText('firmware.update.table.start.btn'),

                style: { backgroundColor: '#4096ff', color: 'white', marginRight: '1em' },
                onClick: () => {
                    if (selectedDevices == undefined || selectedDevices.length == 0) {
                        setShowSelectedDeviceError(true);
                        return;
                    }
                    form.validateFields().then((val: any) => {
                        NyRequestResolver.requestPost(
                            CONSTANTS_REQ.FIRMWARE_UPDATE.START,
                            undefined,
                            normalize(val)
                        ).then((result: any) => {
                            if (result.status === RESPONSE.OK) {
                                notification.success({
                                    message: geti18nText('app.default.save.ok'),

                                    duration: 3,
                                });
                                onModalClose();
                                props.setVisible(false);
                                props.onSave();
                            } else {
                                notification.error({
                                    message: geti18nText('app.default.save.nok'),

                                    duration: 3,
                                });
                            }
                        });
                    });
                },
                addPopconfirm: false,
                popConfirmOk: '',
                popConfirmCancel: '',
            },
        ];
    }

    const onStopFirmwareDeviceUpdate = (record: any) => {
        console.log(record);
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.FIRMWARE_UPDATE.CHANGE_STATUS_DEVICE + '/' + record.updateId,
            undefined,
            {
                status: 3,
            }
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('firmware.update.table.stop.btn.message.ok'),
                    duration: 3,
                });
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: geti18nText('firmware.update.table.stop.btn.message.nok'),
                    duration: 3,
                });
            }
        });
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.FIRMWARE_UPDATE.EDIT}
            setValues={setValues}
            width={1600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalize}
            hideSubmitButton={true}
            hideActivationButtons={true}
            customButtons={customButtons()}
            {...props}
            hideButtons={false}
        >
            <FirmwareFileSelect
                {...props}
                form={form}
                setDeviceModel={setDeviceModel}
                deleted={deleted}
                isCreate={isCreate}
            ></FirmwareFileSelect>
            {valuesForm != undefined && (
                <Row style={{ marginLeft: 110 }}>
                    <Col span={18}>
                        <Descriptions column={1}>
                            {valuesForm.startTime && (
                                <Descriptions.Item label={geti18nText('firmware.update.table.edit.startJob')}>
                                    {new Date(valuesForm.startTime).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </Descriptions.Item>
                            )}
                            {valuesForm.endTime && (
                                <Descriptions.Item label={geti18nText('firmware.update.table.edit.endJob')}>
                                    {new Date(valuesForm.endTime).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Col>
                </Row>
            )}

            {deviceModel != undefined && formId == null && (
                <>
                    {(selectedDevices == undefined || selectedDevices.length == 0) &&
                        showSelectedDeviceError == true && (
                            <Alert
                                type="error"
                                message={geti18nText('firmware.update.table.error.devices')}
                                banner
                                style={{ marginLeft: 30 }}
                            />
                        )}
                    <DeviceTableIndex
                        setSelectedValues={setSelectedDevices}
                        firmwareUpdateId={formId}
                        deviceModel={deviceModel}
                        showRowSelection={true}
                    ></DeviceTableIndex>
                </>
            )}
            {formId != null && (
                <DeviceTableIndex
                    setSelectedValues={setSelectedDevices}
                    firmwareUpdateId={formId}
                    onStopFirmwareDeviceUpdate={onStopFirmwareDeviceUpdate}
                    progres={<FirmwareUploadProgress formId={formId} />}
                ></DeviceTableIndex>
            )}
        </NyDataEdit>
    );
};

export default AdminFirmwareUpdateEdit;
