import { InfoCircleOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Button, Descriptions, Popover } from 'antd';
import React from 'react';

const InfoMessage = ({ message }: any) => {
    const displayAdditionalInfo = (
        <div style={{ width: '500px' }}>
            <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                <Descriptions.Item>{message}</Descriptions.Item>
            </Descriptions>
        </div>
    );

    return (
        <React.Fragment>
            <div>
                <Popover
                    content={displayAdditionalInfo}
                    title={geti18nText('app.default.btn.info')}
                    placement="top"
                    style={{ width: '50%' }}
                >
                    <Button icon={<InfoCircleOutlined />} />
                </Popover>
            </div>
        </React.Fragment>
    );
};

export default InfoMessage;
