import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySearchField,
} from '@nybble/nyreact';

import TableSettings from '../table_settings';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import CountingDetailSerialIndex from '../counting/detailsSerial';
import { formatCurrencyById } from '../../utils/Utils';
import { serialColumns } from './columns';
import { useDispatch } from 'react-redux';
import { setModalOpen } from '../../slices/modalOpenSlice';
import moment from 'moment';
import { FormatedDatePicker } from '../../components/formated-date-picker/formatedDatePicker';

const DeviceCountSerialIndex = (props: any) => {
    const table = TableSettings();

    const [detailsVisible, setDetailsVisible] = useState<any>(false);
    const [detailDeviceVisible, setDetailDeviceVisible] = useState<any>(false);
    const [serial, setSerial] = useState<any>(undefined);
    const [deviceId, setDeviceId] = useState<any>(undefined);
    const dispatch = useDispatch();

    function onRowDetails(record: any) {
        if (props.deviceId) {
            setSerial(record.serial);
            setDetailsVisible(true);
        } else {
            dispatch(
                setModalOpen({
                    modal: 'deviceEdit',
                    visible: true,
                    item: record.deviceId,
                })
            );
        }
    }

    function setDefaultFilterValue() {
        if (props?.deviceId) {
            return [
                { field: 'device_id', condition: 'equals', value: props.deviceId },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('serial.table.header')}
                url={CONSTANTS_REQ.COUNT_SERIAL.LIST_BY_DEVICE}
                hideButtons
                setDefaultPageSize={table.setDefaultPageSize(20)}
                onDataLoaded={table.onLoadData}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                exportCSV={true}
                onRowSelect={onRowDetails}
                columns={serialColumns()}
            />

            {props.deviceId && (
                <CountingDetailSerialIndex
                    deviceId={props.deviceId}
                    serial={serial}
                    visible={detailsVisible}
                    setVisible={setDetailsVisible}
                ></CountingDetailSerialIndex>
            )}
        </>
    );
};

export default DeviceCountSerialIndex;
