import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, notification, Popconfirm, Tabs } from 'antd';
import React, { useState } from 'react';
import TableSettings from '../../../table_settings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getTimezone } from '../../../../utils/Utils';
import { GetEnum, GetEnumNameForValue } from '../../../../utils/Enums';
import AdminFirmwareFileEdit from './edit';
const AdminFirmwareFilesIndex = () => {
    const [refresh, setRefresh] = useState<any>(null);
    const table = TableSettings();
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }
    const communicationType = () => {
        const aTypes = GetEnum({ enumName: 'COMMUNICATION_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = {};
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const onDeleteFirmwareFile = (record: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.FIRMWARE.DELETE + '/' + record.id, undefined, undefined).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    setRefresh(new Date().getTime());
                    notification.success({
                        message: geti18nText('app.default.delete.ok'),
                        description: geti18nText('app.default.delete.ok.desc'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.delete.nok'),

                        duration: 3,
                    });
                }
            }
        );
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('firmware.file.table.header')}
            url={CONSTANTS_REQ.FIRMWARE.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('firmware.file.table.add')}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_FIRMWARE_FILES'])}
            modalComponent={AdminFirmwareFileEdit}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            fetchWhenChange={refresh}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            columns={[
                {
                    title: geti18nText('firmware.file.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('firmware.file.table.device.model'),
                    dataIndex: ['deviceModel', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('firmware.file.table.communication.type'),
                    dataIndex: 'communicationType',
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                {GetEnumNameForValue({
                                    enumName: 'COMMUNICATION_TYPE',
                                    value: record.communicationType,
                                })}
                            </div>
                        );
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchOption(communicationType()),
                },
                {
                    title: geti18nText('firmware.file.table.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('firmware.file.table.created'),
                    dataIndex: 'created',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.created) {
                            return (
                                <div>
                                    {new Date(record.created).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                },
                {
                    title: geti18nText('firmware.file.table.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
                {
                    title: '',
                    dataIndex: 'action',
                    //  sorter: (a: any, b: any) => {},
                    //...getColumnSearch('number'),
                    width: '5%',
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <Popconfirm
                                title={geti18nText('firmware.file.edit.btn.info.delete.msg')}
                                onConfirm={() => {
                                    onDeleteFirmwareFile(record);
                                }}
                            >
                                <Button type="primary" danger>
                                    {geti18nText('firmware.file.edit.btn.info.delete')}
                                </Button>
                            </Popconfirm>
                        );
                    },
                },
            ]}
        />
    );
};

export default AdminFirmwareFilesIndex;
