import { NyRequestResolver, NySession } from '@nybble/nyreact';
import { Progress } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import SSE from '../../../../components/layout-main/see';
import { v4 as uuidv4 } from 'uuid';
const FirmwareUploadProgress = ({ formId }: any) => {
    const [percent, setPercent] = useState(0);
    const [total, setTotal] = useState<any>(0);
    const [count, setCount] = useState<any>(0);
    const closeMolda = useRef<any>(false);
    useEffect(() => {
        console.log('init');
        startListeningForUpdateProgressCounting();

        return () => {
            closeMolda.current = true;
            eventSourceRef.current?.close();
            stopLiveCounting(deviceLivePreviewId?.current);
        };
    }, []);

    const eventSourceRef = useRef<any>(null);
    const deviceLivePreviewId = useRef<any>(null);
    function startListeningForUpdateProgressCounting() {
        if (closeMolda.current == true) return;
        console.log('Open conection... ');
        if (NySession.isUserAuthenticated()) {
            let previewId = uuidv4();
            deviceLivePreviewId.current = previewId;
            eventSourceRef.current = new SSE(
                CONSTANTS_REQ.FIRMWARE_UPDATE.LIVE_PROGRESS_COUNTING +
                    '?firmwareUpdateId=' +
                    formId +
                    '&previewId=' +
                    previewId,
                {
                    headers: {
                        Authorization: 'Bearer ' + NySession.getUserToken(),
                    },
                }
            );

            eventSourceRef.current.onmessage = (e: any) => {
                if (e && e.data) {
                    let data = JSON.parse(e.data);
                    console.log('Data ', data);
                    setCount(data.count);
                    setTotal(data.total);
                    const p = (data.count / data.total) * 100;
                    setPercent(p);
                }
            };

            eventSourceRef.current.onerror = (e: any) => {
                console.log('sse error');
                console.log(e);
                eventSourceRef.current.close();
                //   startListeningForUpdateProgressCounting();
            };

            eventSourceRef.current.stream();
        }
    }

    function stopLiveCounting(deviceLivePreviewId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.FIRMWARE_UPDATE.STOP_PROGRESS_COUNTING, {
            firmwareUpdateId: formId,
            previewId: deviceLivePreviewId,
        }).then((res: any) => {
            // console.log('Stop live count ', deviceLivePreviewId);
        });
    }
    return (
        <Progress
            percent={percent}
            status={'active'}
            showInfo={true}
            format={(percent, success) => {
                return (
                    <div>
                        <>{count + '/' + total}</>
                    </div>
                );
            }}
        />
    );
};
export default FirmwareUploadProgress;
