import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Popover, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';
import HnbDeviceHistoryEdit from './history_edit';

const HnbDeviceHistoryIndex = (props: any) => {
    const { TabPane } = Tabs;
    const [deviceTypes, setDeviceTypes] = useState<any>([]);
    useEffect(() => {
        getDeviceTypes();
    }, []);

    const getDeviceTypes = async () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.HNB_DEVICE_TYPE.LIST_ALL, undefined).then((res: any) => {
            let types: any = [];
            if (res.status === RESPONSE.OK && res.data) {
                const aTypes: any = res.data;
                aTypes.forEach((element: any) => {
                    let ret: any = {};
                    ret.id = element.id;
                    ret.text = element.name;
                    types.push(ret);
                });
            }
            setDeviceTypes(types);
        });
    };

    function setDefaultFilterValue() {
        console.log(props);
        return [{ field: 'hnb_device.id', condition: 'equals', value: props.hnb_device }];
    }

    const Imported = (props: any) => {
        return (
            <React.Fragment>
                <Popover content={geti18nText('hnb.device.history.import')}>
                    <ArrowLeftOutlined style={{ fontSize: '14px', color: '#3498DB' }} />
                </Popover>
            </React.Fragment>
        );
    };

    return (
        <NyDataTable
            // headerTitle={geti18nText('device.edit.device_info.card.title')}
            url={CONSTANTS_REQ.HNB_DEVICE_HISTORY.LIST}
            showRecordModal={true}
            hideButtons={true}
            setDefaultPageSize={10}
            exportCSV={true}
            setDefaultFilterValue={setDefaultFilterValue}
            modalComponent={HnbDeviceHistoryEdit}
            // readonly={!NySession.hasAnyRole(['RULE_UPDATE_MANUAL_COUNT'])}
            readonly={true}
            columns={[
                {
                    title: geti18nText('hnb.device.history.user'),
                    dataIndex: 'users',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.users && record.users.firstName) {
                            return (
                                <div>
                                    <div style={{ display: 'inline' }}>
                                        {record.users.firstName +
                                            ' ' +
                                            (record.users.lastName != undefined ? record.users.lastName : '')}
                                    </div>
                                    <div style={{ display: 'inline', marginLeft: '5px' }}>
                                        {record.imported == true ? <Imported /> : null}
                                    </div>
                                </div>
                            );
                        }
                        return '';
                    },
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('hnb.device.table.hnb_device_code'),
                    dataIndex: 'hnbDeviceCode',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('hnb.device.table.hnb_device_type'),
                    dataIndex: 'hnbDeviceType',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.hnbDeviceType) {
                            if (deviceTypes != undefined) {
                                const typeItemIndex = deviceTypes.findIndex((x: any) => x.id == record.hnbDeviceType);

                                if (typeItemIndex > -1) {
                                    return <div>{deviceTypes[typeItemIndex].text}</div>;
                                }
                            } else {
                                return <div>{geti18nText('hnb.device.type.' + record.hnbDevice.hnbDeviceType)}</div>;
                            }
                        }
                    },
                    ...getColumnSearchOption(deviceTypes),
                },
                {
                    title: geti18nText('hnb.device.table.software_eur'),
                    dataIndex: 'softwareEur',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('hnb.device.table.software_hr'),
                    dataIndex: 'softwareHr',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('hnb.device.table.count_state'),
                    dataIndex: 'countState',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.countState) {
                            return <div>{NyUtils.formatNumber(record.countState, 0)}</div>;
                        }
                    },
                    ...getColumnSearch('string'),
                },

                {
                    title: geti18nText('hnb.device.maintenance_date'),
                    dataIndex: 'maintenanceDate',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.maintenanceDate) {
                            return (
                                <div>
                                    {new Date(record.maintenanceDate).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                    ...getColumnDateOption(true, undefined, undefined, true),
                },
                {
                    title: geti18nText('hnb.device.history.creadted'),
                    dataIndex: 'created',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.created) {
                            return (
                                <div>
                                    {new Date(record.created).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                    ...getColumnDateOption(true, undefined, undefined, true),
                },
            ]}
            colCSVHeaderType={'DATA_INDEX'}
            colCSVCustomization={[
                {
                    users: (value: any) => {
                        var res = value != null ? value.firstName + ' ' + value.lastName : '';
                        return res;
                    },
                },
                {
                    maintenanceDate: (value: string) => {
                        return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
                    },
                },
                {
                    created: (value: string) => {
                        return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
                    },
                },
                {
                    hnbDeviceType: (value: any) => {
                        return geti18nText('hnb.device.type.' + value);
                    },
                },
            ]}
        />
    );
};

export default HnbDeviceHistoryIndex;
