import {
    geti18nText,
    NyDataEdit,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, message, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../../utils/Enums';
import NyFileUpload from '../../../../components/file-upload/index_file';
import InfoMessage from '../../../info';

const AdminFirmwareFileEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('firmware.file.table.header'));
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [files, setFiles] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [formId, setFormId] = useState<any>(null);
    const [firmwareDownloadFile, setFirmwareDownloadFile] = useState<any>(null);

    useEffect(() => {
        console.log(form.getFieldValue('name'));
        if (files != undefined) {
            const file = files[0];
            form.setFieldsValue({ name: file.name });
        }
    }, [files]);

    const onModalClose = () => {
        setFiles(null);
        setFirmwareDownloadFile(null);
        setFormId(null);
        setLoading(false);
        setEditHeader(geti18nText('firmware.file.table.header'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        dataForm.communicationType = {
            id: dataForm.communicationType,
            text: GetEnumNameForValue({ enumName: 'COMMUNICATION_TYPE', value: dataForm.communicationType }),
        };
        if (dataForm.deviceModel != null) {
            dataForm.deviceModel = { id: dataForm.deviceModel.id, name: dataForm.deviceModel.name };
        }
        setFormId(dataForm.id);
        setFirmwareDownloadFile(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    function downloadFile() {
        if (firmwareDownloadFile == null) return;
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.FIRMWARE.DOWNLOAD, { id: firmwareDownloadFile }, false, true).then(
            (result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.OK) {
                    result.data.filename = form.getFieldValue('name');
                    NyUtils.downloadFile(result);
                    setLoading(false);
                    // okNotification(geti18nText('currency.denomination.export.OK'));
                } else {
                    notification['error']({
                        message: geti18nText('app.default.error'),
                        description: message,
                        duration: 0,
                    });
                }
            }
        );
    }

    function normalize(values: any) {
        let normalized = { ...values };

        if (files != undefined) {
            normalized.file = files[0];
        }
        if (values.deviceModel != null) {
            normalized.deviceModel = { id: values.deviceModel.id };
        }

        return normalized;
    }

    function getFormData(values: any): any {
        const formData = new FormData();
        if (values.name) {
            formData.append('name', values.name);
        }
        if (values.communicationType) {
            formData.append('communicationType', values.communicationType.id);
        }
        if (values.deviceModel != null) {
            formData.append('deviceModel.id', values.deviceModel.id);
        }

        if (files.length === 1) {
            files.forEach((file: any) => {
                formData.append('file', file, file.name);
            });
        }
        console.log(formData);
        return formData;
    }
    function onBeforeSave() {
        if (formId != undefined) {
            return true;
        }
        form.validateFields().then((val: any) => {
            setLoading(true);
            fetch(CONSTANTS_REQ.FIRMWARE.EDIT, {
                method: 'POST',
                body: getFormData(val),
                headers: {
                    Authorization: 'Bearer ' + NySession.getUserToken(),
                },
            })
                .then((result: any) => {
                    setLoading(false);
                    if (result && result.status === RESPONSE.OK) {
                        notification['success']({
                            message: geti18nText('app.default.save.ok'),
                            duration: 10,
                        });
                        onModalClose();
                        props.setVisible(false);
                        props.onSave();
                    }
                })
                .catch(() => {
                    message.destroy();
                    notification['error']({
                        message: geti18nText('app.default.save.nok'),
                        description: message,
                        duration: 0,
                    });
                })
                .finally(() => {});
        });

        return false;
    }
    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.FIRMWARE.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalize}
            checkBeforeSave={onBeforeSave}
            hideSubmitButton={formId != null}
            {...props}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_FIRMWARE_FILES'])}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input readOnly={true} />
                </Form.Item>
                {/* <Col span={18}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        label={geti18nText('firmware.file.table.communication.type')}
                        name="communicationType"
                    >
                        <NySearchField
                                                    options={communicationTypes()}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                        />
                    </Form.Item>
                </Col> */}
                <Col span={18}>
                    <Form.Item
                        label={geti18nText('firmware.file.table.device.model')}
                        name="deviceModel"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            {(files != undefined || formId != undefined) && (
                <Row>
                    <Col span={18}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            label={geti18nText('firmware.file.table.name')}
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}></Col>
                </Row>
            )}

            {formId == undefined && (
                <Row>
                    <Col span={18}>
                        <Form.Item
                            label={geti18nText('firmware.file.edit.file.upload')}
                            name="f_file"
                            rules={[
                                {
                                    required: files == undefined,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyFileUpload
                                key={uploadKey}
                                files={files}
                                setFiles={setFiles}
                                multiple={false}
                                accept="zip"
                            />
                        </Form.Item>
                    </Col>

                    <InfoMessage message={geti18nText('firmware.file.edit.btn.info')}></InfoMessage>
                </Row>
            )}

            {firmwareDownloadFile != undefined && (
                <Row gutter={18}>
                    <Col span={18} style={{ textAlign: 'right' }}>
                        <Button onClick={() => downloadFile()} loading={loading}>
                            {geti18nText('firmware.file.edit.file.download.btn')}
                        </Button>
                    </Col>
                </Row>
            )}
        </NyDataEdit>
    );
};

export default AdminFirmwareFileEdit;
