import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyFileUpload,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row, Tabs, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { formatCurrency, formatCurrencyById, getTimezone } from '../../utils/Utils';
import CountSerialIndex from '../serial';

const CountingDetailsIndex = (props: any) => {
    const [countSerialVisible, setCountSerialVisible] = useState<any>(false);
    const [countId, setCountId] = useState<any>(undefined);
    function setDefaultFilterValue() {
        return [{ field: 'count_data_id', condition: 'equals', value: props.id }];
    }

    function onCancelCountDetailsVisible() {
        props.setVisible(false);
    }

    function csvFileName() {
        const date = new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return geti18nText('counting.details.header') + '_' + props.id + '_' + formatDate;
    }
    function onRowDetails(record: any) {
        setCountId(record.id);
        setCountSerialVisible(true);
    }
    function downloadFile() {
        //  setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.COUNT_SERIAL.COUNTING_DATA_CSV + '/' + props.id,
            {
                countDataId: props.id,
                lang: NyUtils.getSelectedLanguage(),
                timezone: getTimezone().timeZone,
                deviceId: props.deviceId,
            },
            false,
            true
        ).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                result.data.filename = csvFileName() + '.csv';
                NyUtils.downloadFile(result);
            } else {
                notification['error']({
                    message: geti18nText('app.default.error'),
                    duration: 0,
                });
            }
        });
    }

    return (
        <Modal
            visible={props.visible}
            title={
                geti18nText('counting.details.header') +
                (props.id ? ' / ' + props.id : '') +
                (props.operator && props.operator != '' ? ' / ' + props.operator : '')
            }
            width={1500}
            footer={null}
            onCancel={onCancelCountDetailsVisible}
            destroyOnClose={true}
        >
            <Row gutter={24}>
                <Col span={23} style={{ textAlign: 'right', marginBottom: '-5px' }}>
                    <Button type="primary" onClick={() => downloadFile()} style={{ marginBottom: '-250px' }}>
                        {geti18nText('serial.table.counting.csv')}
                    </Button>
                </Col>
            </Row>
            <NyDataTable
                url={CONSTANTS_REQ.COUNTING_DETAILS.LIST}
                showRecordModal={true}
                hideButtons={true}
                setDefaultPageSize={20}
                onRowSelect={onRowDetails}
                exportCSV={true}
                colCSVCustomization={DetailsCountingCSVColCustomization()}
                CSVFileName={csvFileName()}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('counting.details.table.value'),
                        dataIndex: 'valueExport',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.value) {
                                return (
                                    <>
                                        <div>{formatCurrencyById(record.value, record.currency_id)}</div>
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.sum'),
                        dataIndex: 'sumExport',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.sum) {
                                return (
                                    <>
                                        <div>{formatCurrencyById(record.sum, record.currency_id)}</div>
                                    </>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.fit'),
                        dataIndex: 'fit',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.atm'),
                        dataIndex: 'atm',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.tlr'),
                        dataIndex: 'tlr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.unfit'),
                        dataIndex: 'unfit',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.manual'),
                        dataIndex: 'manual',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.unsorted'),
                        dataIndex: 'unsorted',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.rejected'),
                        dataIndex: 'rejected',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('counting.details.table.net'),
                        dataIndex: 'net',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('counting.details.table.series'),
                        dataIndex: 'series',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },

                    {
                        title: geti18nText('counting.details.table.start.time'),
                        dataIndex: 'startTime',
                        width: '15%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.startTime) {
                                return (
                                    <div>
                                        {new Date(record.startTime).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('counting.details.table.end.time'),
                        dataIndex: 'endTime',
                        width: '15%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.endTime) {
                                return (
                                    <div>
                                        {new Date(record.endTime).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('count.data.table.created'),
                        dataIndex: 'created',

                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.created) {
                                return (
                                    <div>
                                        {new Date(record.created).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                ]}
            />
            ;
            <CountSerialIndex
                countId={countId}
                deviceId={props.deviceId}
                visible={countSerialVisible}
                setVisible={setCountSerialVisible}
            ></CountSerialIndex>
        </Modal>
    );
};

export const DetailsCountingCSVColCustomization: any = () => {
    return [
        {
            valueExport: (value: any) => {
                return formatCurrencyById(value.value, value.currency_id);
            },
        },
        {
            sumExport: (value: any) => {
                return formatCurrencyById(value.sum, value.currency_id);
            },
        },
        {
            fit: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            unsorted: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            atm: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            tlr: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            unfit: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            rejected: (value: any) => {
                return NyUtils.formatNumber(value);
            },
        },
        {
            startTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
        {
            endTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
    ];
};

export default CountingDetailsIndex;
