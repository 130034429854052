import { geti18nText } from '@nybble/nyreact';
import table from 'antd/lib/table';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import DeviceColumns from '../../device/columns';
import { ReactText, useEffect, useRef, useState } from 'react';
import TableSettings from '../../../table_settings';
import NyDataTable from './custom_table';
import DeviceFirmwareColumns from './device_column';

const DeviceTableIndex = ({
    setSelectedValues,
    firmwareUpdateId,
    deviceModel,
    showRowSelection,
    onStopFirmwareDeviceUpdate,
    progres,
}: any) => {
    let clearKeys: any;
    const [refresh, setRefresh] = useState<any>(null);
    const timerRef = useRef<any>(null);
    useEffect(() => {
        if (deviceModel != null) {
            setRefresh(new Date().getTime());
        }
    }, [deviceModel]);

    useEffect(() => {
        if (firmwareUpdateId != undefined) {
            if (timerRef.current != null) {
                clearInterval(timerRef.current);
            }
            timerRef.current = setInterval(() => {
                console.log('refreshTable');
                setRefresh(new Date().getTime());
            }, 10 * 1000);

            return function cleanup() {
                clearTimeout(timerRef.current);
            };
        }
    }, []);

    function setDefaultFilterValue() {
        let list = [
            { field: '_d.active', condition: 'equals_bool', value: 1 },
            { field: '_d.useParser', condition: 'equals_bool', value: 1 },
        ];
        if (deviceModel != null) {
            list = list.concat([{ field: 'model.id', condition: 'equals', value: deviceModel }]);
        }
        if (firmwareUpdateId != undefined) {
            return [];
        }
        return list;
    }
    const table = TableSettings();

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('device.table.header')}
            url={CONSTANTS_REQ.FIRMWARE_UPDATE.DEVICE_LIST}
            showRecordModal={false}
            hideButtons={false}
            hideNewButton={true}
            readonly={true}
            fetchWhenChange={refresh && refresh}
            scroll={{ x: 1200 }}
            columns={DeviceFirmwareColumns(firmwareUpdateId != undefined, onStopFirmwareDeviceUpdate, progres)}
            showRowSelection={showRowSelection}
            rowSelectionModal={setSelectedValuesFunc as any}
            rowSelectionType={'checkbox'}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={setDefaultFilterValue}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            addedData={{ firmwareUpdateId: firmwareUpdateId }}
        />
    );
};
export default DeviceTableIndex;
