import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SSE from '../../components/layout-main/see';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { formatCurrency, getTimezone } from '../../utils/Utils';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button, Modal } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { ReactComponent as CheckSvg } from '../../assets/img/check-solid.svg';
import { ReactComponent as ExclamationSvg } from '../../assets/img/exclamation-solid.svg';
const LiveCountingPreview = (props: any) => {
    const [liveDataCount, setLiveDataCount] = useState<any>(0);
    const [liveDataSum, setLiveDataSum] = useState<any>(0);
    const [time, setTime] = useState<any>(undefined);
    const [liveDataCurrency, setLiveDataSumCurrency] = useState<any>(undefined);
    const [selectedCurrency, setSelectedCurrency] = useState<any>(undefined);
    const [pattern, setPattern] = useState<any>('');
    const [patternIndex, setPatternIndex] = useState<any>(1);
    const [selectedPatternIndex, setSelectedPatternIndex] = useState<any>(undefined);
    const [selectedPatternSettings, setSelectedPatternSettings] = useState<any>([]);
    const [selectedPattern, setSelectedPattern] = useState<any>(undefined);
    const [status, setStatus] = useState<any>('');
    const [rejectExist, setRejectExist] = useState<boolean>(false);
    const [disabledStop, setDisabledStop] = useState<boolean>(false);
    const [disabledAccept, setDisabledAccept] = useState<boolean>(true);
    const [disabledClear, setDisabledClear] = useState<boolean>(true);
    const [rejectedCount, setRejectedCount] = useState<number>(0);
    const [visibleStop, setVisibleStop] = useState<boolean>(false);
    const [visibleStart, setVisibleStart] = useState<boolean>(false);
    const [visibleStartTransaction, setVisibleStartTransaction] = useState<boolean>(true);
    const eventSourceRef = useRef<any>(null);
    const deviceLivePreviewId = useRef<any>(null);
    const connectionRetryCount = useRef<number>(0);
    const closeMolda = useRef<any>(false);
    const [showConnectionError, setShowConnectionError] = useState<boolean>(false);
    const [visibleCurrencyModal, setVisibleCurrencyModal] = useState<boolean>(false);
    const [visiblePatternModal, setVisiblePatternModal] = useState<boolean>(false);
    const [visibleRefreshModal, setVisibleRefreshModal] = useState<boolean>(false);
    const [visibleClearModal, setVisiblClearModal] = useState<boolean>(false);
    const [currencyList, setCurrencyList] = useState<any>([]);
    const [patternList, setPatternList] = useState<any>([]);
    const [applicationError, setApplicationError] = useState<boolean>(false);
    const [autoConting, setAutoConting] = useState<boolean>(false);
    const [selectedAutoConting, setSelectedAutoConting] = useState<any>(undefined);
    const [visibleCountingTypeModal, setVisibleCountingTypeModal] = useState<boolean>(false);
    let history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        startListeningForDeviceLiveCounting();
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            closeMolda.current = true;
            eventSourceRef.current.close();
            stopLiveCounting(deviceLivePreviewId?.current);
            clearInterval(intervalId);
        };
    }, []);

    function getDeviceParam() {
        console.log('DEVICE ID ', id);
        if (id) return '&deviceId=' + id;
        else return '';
    }

    function startListeningForDeviceLiveCounting() {
        if (closeMolda.current == true) return;
        console.log('Open conection... ');

        let previewId = uuidv4();
        deviceLivePreviewId.current = previewId;
        setShowConnectionError(false);
        eventSourceRef.current = new SSE(
            CONSTANTS_REQ.DEVICE.DEVICE_LIVE_COUNTING + '?previewId=' + previewId + getDeviceParam(),
            {
                headers: {
                    //    Authorization: 'Bearer ' + NySession.getUserToken(),
                },
            }
        );

        eventSourceRef.current.onmessage = (e: any) => {
            if (e && e.data) {
                let data = JSON.parse(e.data);
                console.log(e.data);
                if (data.event == 'MONITORING_DATA' || data.event == undefined) {
                    setLiveDataSum(data.sum);
                    setLiveDataCount(data.quantity);
                }
                if (data.event == 'START_TRANSACTION') {
                    setVisibleStartTransaction(false);
                    setVisibleStart(true);
                    setVisibleStop(false);
                    setDisabledClear(false);
                    setDisabledAccept(false);
                }
                if (data.event == 'CANCEL_TRANSACTION') {
                    setVisibleStartTransaction(true);
                    setVisibleStart(false);
                    setVisibleStop(false);
                }
                if (data.event == 'END_TRANSACTION') {
                    setVisibleStartTransaction(true);
                    setVisibleStart(false);
                    setVisibleStop(false);
                    setDisabledAccept(true);
                }
                if (data.event == 'START_COUNT') {
                    setVisibleStartTransaction(false);
                    setVisibleStart(true);
                    setDisabledClear(false);
                    setDisabledAccept(false);
                }
                if (data.event == 'DEVICE_PATTERN') {
                    setPattern(data.patternName);
                    setPatternIndex(data.patternIndex);
                    setSelectedPatternSettings(data.patternSettings);
                }
                if (data.event == 'CHANGE_PATTERN') {
                    console.log('CHANGE_PATTERN ', data);
                    setSelectedPattern(data.patternName);
                    setSelectedPatternIndex(data.patternIndex);
                    setSelectedPatternSettings(data.patternSettings);
                }
                if (data.event == 'DEVICE_CURRENCY') {
                    setLiveDataSumCurrency(data.currency);
                    console.log('Curency index', data.currencyIndex);
                }
                if (data.event == 'STATUS_CHANGE') {
                    setStatus(data.status);
                    setApplicationError(false);
                    setRejectExist(data.rejectExist);
                    if (data.status != undefined) {
                        let error = data.status.toLowerCase().includes('error');
                        setApplicationError(error);
                    }
                }
                if (data.currencyList != undefined) {
                    setCurrencyList(data.currencyList);
                }
                if (data.patternList != undefined) {
                    setPatternList(data.patternList);
                }
                if (data.autoCounting != undefined) {
                    setAutoConting(data.autoCounting);
                }
            }
        };

        eventSourceRef.current.onerror = (e: any) => {
            console.log('sse error');
            console.log(e);
            eventSourceRef.current.close();
            setTimeout(() => {
                restartListeningForDeviceLiveCounting();
            }, 5000);
        };

        eventSourceRef.current.stream();
    }

    function restartListeningForDeviceLiveCounting() {
        if (eventSourceRef.current && eventSourceRef.current.isOpen()) {
            return;
        }
        if (connectionRetryCount.current > 30) {
            setShowConnectionError(true);
            return;
        }
        startListeningForDeviceLiveCounting();
        connectionRetryCount.current++;
    }

    function stopLiveCounting(deviceLivePreviewId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.DEVICE_STOP_COUNTING, {
            deviceId: props.deviceId,
            previewId: deviceLivePreviewId,
        }).then((res: any) => {
            // console.log('Stop live count ', deviceLivePreviewId);
        });
    }

    function setCountingType(autoCounting: boolean) {
        console.log('setCountingType ', autoCounting);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.COUNTING_TYPE, {
            deviceId: id,
            autoCounting: autoCounting,
        }).then((res: any) => {
            if (res.status === RESPONSE.OK && res.data != undefined) {
                setAutoConting(res.data);
            }
        });
    }

    function liveCountingCommandHandler(
        deviceLivePreviewId: any,
        command: string,
        currencyIndex: any = undefined,
        patternIndex: any = undefined,
        patternIncrement: any = undefined
    ) {
        let path = '';
        switch (command) {
            case 'START_TRANSACTION':
                path = CONSTANTS_REQ.DEVICE.LIVE_START_TRANSACTION;
                break;
            case 'CANCEL_TRANSACTION':
                path = CONSTANTS_REQ.DEVICE.LIVE_CANCEL_TRANSACTION;
                break;
            case 'END_TRANSACTION':
                path = CONSTANTS_REQ.DEVICE.LIVE_END_TRANSACTION;
                break;
            case 'START_COUNT':
                path = CONSTANTS_REQ.DEVICE.LIVE_START_COUNT;
                break;
            case 'SET_CURRENCY':
                path = CONSTANTS_REQ.DEVICE.SET_CURRENCY;
                break;
            case 'SET_PATTERN':
                path = CONSTANTS_REQ.DEVICE.SET_PATTERN;
                break;
            case 'CHANGE_PATTERN':
                path = CONSTANTS_REQ.DEVICE.CHANGE_PATTERN;
        }
        path += '?previewId=' + deviceLivePreviewId + getDeviceParam();
        console.log('> ', path);
        if (currencyIndex != undefined) {
            path += '&index=' + currencyIndex;
        }
        if (patternIndex != undefined) {
            path += '&patternIndex=' + patternIndex;
        }
        if (patternIncrement != undefined) {
            path += '&patternIncrement=' + patternIncrement;
        }
        console.log(path);
        NyRequestResolver.requestGet(path).then((res: any) => {
            // console.log('Stop live count ', deviceLivePreviewId);
        });
    }

    const handleOnClickClear = () => {
        setDisabledClear(true);
        setDisabledAccept(true);
        liveCountingCommandHandler(deviceLivePreviewId?.current, 'CANCEL_TRANSACTION');
    };

    const handleOnClickStop = () => {
        setDisabledClear(true);
        setDisabledAccept(true);
        setVisibleStop(false);
        setVisibleStartTransaction(true);
        liveCountingCommandHandler(deviceLivePreviewId?.current, 'CANCEL_TRANSACTION');
    };

    const handleOnClickAccept = () => {
        setDisabledClear(true);
        setDisabledStop(true);
        liveCountingCommandHandler(deviceLivePreviewId?.current, 'END_TRANSACTION');
    };

    const handleOnClickStart = () => {
        setDisabledClear(true);
        setDisabledAccept(true);
        liveCountingCommandHandler(deviceLivePreviewId?.current, 'START_COUNT');
    };
    const handleOnClickStartTransaction = () => {
        setDisabledClear(true);
        setDisabledAccept(true);
        liveCountingCommandHandler(deviceLivePreviewId?.current, 'START_TRANSACTION');
    };

    const handleModalCurrencyCancel = () => {
        setVisibleCurrencyModal(false);
    };

    const handleModalCurrencyOk = () => {
        setVisibleCurrencyModal(false);
    };

    const patternContent = (selectedPatternSettings: any) => {
        const st1 = selectedPatternSettings[0] != undefined ? selectedPatternSettings[0] : {};
        const st2 = selectedPatternSettings[1] != undefined ? selectedPatternSettings[1] : {};
        return (
            <table className="live-preview-pattern-table" style={{ width: '100%', marginTop: '10px' }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', color: '#8181A5', backgroundColor: '#E3E8F4' }}>
                            {geti18nText('device.live.counting.view.patern.st1')}
                        </th>
                        <th style={{ textAlign: 'center' }}></th>
                        <th style={{ textAlign: 'center', color: '#8181A5', backgroundColor: '#E3E8F4' }}>
                            {geti18nText('device.live.counting.view.patern.st2')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{st1?.DenomiOrder}</td>
                        <td>{geti18nText('device.live.counting.view.patern.denomination')}</td>
                        <td>{st2?.DenomiOrder}</td>
                    </tr>
                    <tr>
                        <td>{st1?.NoteType}</td>
                        <td>{geti18nText('device.live.counting.view.patern.fitnes')}</td>
                        <td>{st2?.NoteType}</td>
                    </tr>
                    <tr>
                        <td>{st1?.NoteVersion}</td>
                        <td>{geti18nText('device.live.counting.view.patern.direction')}</td>
                        <td>{st2?.NoteVersion}</td>
                    </tr>
                    <tr>
                        <td>{st1?.NoteVersion}</td>
                        <td>{geti18nText('device.live.counting.view.patern.revision')}</td>
                        <td>{st2?.NoteVersion}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <>
            <Modal
                title={<h2>{geti18nText('device.live.counting.view.counting.type')}</h2>}
                visible={visibleCountingTypeModal}
                onCancel={() => {
                    setVisibleCountingTypeModal(false);
                }}
                onOk={() => {
                    setVisibleCountingTypeModal(false);
                }}
                width={1000}
                footer={[
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setVisibleCountingTypeModal(false);
                        }}
                    >
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setCountingType(selectedAutoConting);
                            setVisibleCountingTypeModal(false);
                        }}
                    >
                        {geti18nText('app.default.button.ok')}
                    </Button>,
                ]}
            >
                <div>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: selectedAutoConting == true ? 'white' : '#8181A5',
                            backgroundColor: selectedAutoConting == true ? '#4D7CFE' : '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setSelectedAutoConting(true);
                        }}
                    >
                        {geti18nText('device.live.counting.view.counting.type.auto')}
                    </Button>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: selectedAutoConting == false ? 'white' : '#8181A5',
                            backgroundColor: selectedAutoConting == false ? '#4D7CFE' : '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setSelectedAutoConting(false);
                        }}
                    >
                        {geti18nText('device.live.counting.view.counting.type.manual')}
                    </Button>
                </div>
            </Modal>

            <Modal
                title={<h2>{geti18nText('device.live.counting.view.currency.change')}</h2>}
                visible={visibleCurrencyModal}
                onCancel={handleModalCurrencyCancel}
                onOk={handleModalCurrencyOk}
                width={1000}
                footer={[
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setVisibleCurrencyModal(false);
                        }}
                    >
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setVisibleCurrencyModal(false);
                            let index = currencyList?.findIndex((x: any) => x.CurrencyName == selectedCurrency);
                            if (index > -1) {
                                liveCountingCommandHandler(
                                    deviceLivePreviewId?.current,
                                    'SET_CURRENCY',
                                    currencyList[index]?.CurrencyIndex
                                );
                            }
                        }}
                    >
                        {geti18nText('app.default.button.ok')}
                    </Button>,
                ]}
            >
                <div>
                    {currencyList.map((button: any, index: any) => (
                        <Button
                            style={{
                                fontSize: '26px',
                                color: button.CurrencyName != selectedCurrency ? '#8181A5' : 'white',
                                backgroundColor: button.CurrencyName != selectedCurrency ? '#E3E8F4' : '#4D7CFE',
                                borderWidth: 'thin',
                                borderRadius: '5px',
                                marginLeft: '20px',
                                minWidth: '130px',
                                height: '65px',
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                                cursor: 'pointer',
                                marginBottom: '10px',
                            }}
                            onClick={() => {
                                setSelectedCurrency(button.CurrencyName);
                            }}
                        >
                            {button.CurrencyName}
                        </Button>
                    ))}
                </div>
            </Modal>
            <Modal
                title={<h2>{geti18nText('device.live.counting.view.pattern.change')}</h2>}
                visible={visiblePatternModal}
                width={1000}
                onCancel={() => {
                    setVisiblePatternModal(false);
                }}
                footer={[
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setVisiblePatternModal(false);
                        }}
                    >
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setVisiblePatternModal(false);
                            let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                            if (index > -1) {
                                liveCountingCommandHandler(
                                    deviceLivePreviewId?.current,
                                    'SET_PATTERN',
                                    currencyList[index]?.CurrencyIndex,
                                    selectedPatternIndex
                                );
                            }
                        }}
                    >
                        {geti18nText('app.default.button.ok')}
                    </Button>,
                ]}
            >
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Button
                            style={{
                                fontSize: '26px',
                                color: 'white',
                                backgroundColor: '#4D7CFE',
                                borderColor: '#000',
                                borderRadius: '5px',
                                borderWidth: 'thin',
                                minWidth: '65px',
                                height: '65px',
                                fontStyle: 'italic',
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                                cursor: 'pointer',
                                marginRight: '5px',
                            }}
                            onClick={() => {
                                let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                                if (index > -1) {
                                    liveCountingCommandHandler(
                                        deviceLivePreviewId?.current,
                                        'CHANGE_PATTERN',
                                        currencyList[index]?.CurrencyIndex,
                                        selectedPatternIndex,
                                        -1
                                    );
                                }
                            }}
                        >
                            {'<'}
                        </Button>
                        <Button
                            style={{
                                fontSize: '26px',
                                color: 'white',
                                backgroundColor: '#4D7CFE',
                                borderColor: '#000',
                                borderRadius: '5px',
                                borderWidth: 'thin',
                                minWidth: '350px',
                                height: '65px',
                                fontStyle: 'italic',
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                                cursor: 'pointer',
                            }}
                            onClick={() => {}}
                        >
                            {selectedPattern}
                        </Button>
                        <Button
                            style={{
                                fontSize: '26px',
                                color: 'white',
                                backgroundColor: '#4D7CFE',
                                borderColor: '#000',
                                borderRadius: '5px',
                                borderWidth: 'thin',
                                minWidth: '65px',
                                height: '65px',
                                fontStyle: 'italic',
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                                cursor: 'pointer',
                                marginLeft: '5px',
                            }}
                            onClick={() => {
                                let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                                console.log('currencyList', currencyList);
                                if (index > -1) {
                                    liveCountingCommandHandler(
                                        deviceLivePreviewId?.current,
                                        'CHANGE_PATTERN',
                                        currencyList[index]?.CurrencyIndex,
                                        selectedPatternIndex,
                                        1
                                    );
                                }
                            }}
                        >
                            {'>'}
                        </Button>
                    </div>
                    {selectedPatternSettings && patternContent(selectedPatternSettings)}
                </>
            </Modal>
            <Modal
                visible={visibleRefreshModal || visibleClearModal}
                width={1000}
                onCancel={() => {
                    setVisiblClearModal(false);
                    setVisibleRefreshModal(false);
                }}
                footer={[
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            setVisiblClearModal(false);
                            setVisibleRefreshModal(false);
                        }}
                    >
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginBottom: '10px',
                        }}
                        onClick={() => {
                            if (visibleClearModal) {
                                setVisiblClearModal(false);
                                handleOnClickClear();
                            }
                            if (visibleRefreshModal) {
                                setVisibleRefreshModal(false);
                                handleOnClickClear();
                                window.location.reload();
                            }
                        }}
                    >
                        {geti18nText('app.default.button.ok')}
                    </Button>,
                ]}
            >
                <Alert
                    type="info"
                    message={geti18nText('device.live.counting.view.modal.clear.alert')}
                    banner
                    style={{ fontSize: '28px', color: 'lightgray', padding: '5px' }}
                />
            </Modal>
            <div className="reject-box-container">
                <div className="reject-box" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {rejectExist ? (
                        <ExclamationSvg fill="red" width={50} height={50} />
                    ) : (
                        <CheckSvg fill="green" width={50} height={50} />
                    )}
                </div>
                <div style={{ fontSize: '16px', textAlign: 'center', color: 'gray', textTransform: 'uppercase' }}>
                    {geti18nText('device.live.counting.rejected')}
                </div>
            </div>

            <div
                style={{
                    height: '50px',
                    backgroundColor: 'black',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: '2%',
                }}
            >
                <span>{time?.toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}</span>
            </div>
            {showConnectionError && (
                <Alert
                    type="error"
                    message={geti18nText('device.live.counting.view.connectionErrorAlert')}
                    banner
                    style={{ marginLeft: '20%', fontSize: '14px' }}
                />
            )}
            <div style={{ fontSize: '18px' }} className="gradient-border">
                <span style={{ marginLeft: '10%' }}>{status}</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        marginLeft: '5%',
                        marginTop: '2%',
                        marginRight: '20%',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'white',
                            backgroundColor: '#4D7CFE',
                            borderColor: '#000',
                            borderRadius: '5px',
                            borderWidth: 'thin',
                            minWidth: '350px',
                            height: '65px',
                            fontStyle: 'italic',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSelectedPattern(pattern);
                            setSelectedPatternIndex(patternIndex);
                            let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                            if (index > -1) {
                                liveCountingCommandHandler(
                                    deviceLivePreviewId?.current,
                                    'CHANGE_PATTERN',
                                    currencyList[index]?.CurrencyIndex,
                                    selectedPatternIndex,
                                    0
                                );
                            }
                            setVisiblePatternModal(true);
                        }}
                    >
                        {pattern}
                    </Button>

                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                        }}
                        disabled={!visibleStartTransaction}
                        onClick={() => {
                            setSelectedCurrency(liveDataCurrency);
                            setVisibleCurrencyModal(true);
                        }}
                    >
                        {liveDataCurrency}
                    </Button>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: '#8181A5',
                            backgroundColor: '#E3E8F4',
                            borderWidth: 'thin',
                            borderRadius: '5px',
                            marginLeft: '20px',
                            minWidth: '130px',
                            height: '65px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                        }}
                        disabled={!visibleStartTransaction}
                        onClick={() => {
                            setSelectedAutoConting(autoConting);
                            setVisibleCountingTypeModal(true);
                        }}
                    >
                        {autoConting == true
                            ? geti18nText('device.live.counting.view.counting.type.auto')
                            : geti18nText('device.live.counting.view.counting.type.manual')}
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '25%',
                        marginRight: '25%',
                        marginTop: '1%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#E3E8F4',
                            marginTop: '4%',
                            borderTop: 'thin solid black',
                            borderLeft: 'thin solid black',
                            borderRight: 'thin solid black',
                            borderTopRightRadius: '5px',
                            borderTopLeftRadius: '5px',
                            //width: '100%',
                            height: '130px',
                            justifyContent: 'space-between', // Rasporedit ćemo elemente na suprotnim krajevima
                            alignItems: 'flex-end', // Poravnavamo ih na dno
                            paddingBottom: '2%',
                            paddingLeft: '10%',
                            paddingRight: '10%',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    fontSize: '1.8em',
                                    textTransform: 'uppercase',
                                    color: '#1C1D21',
                                    fontWeight: 'bold',
                                }}
                            >
                                {geti18nText('device.live.counting.view.total')}{' '}
                            </div>
                        </div>
                        <div>
                            <div
                                style={{
                                    fontSize: '46px',
                                    color: '#1C1D21',
                                    fontStyle: 'italic',
                                }}
                            >
                                {formatCurrency(liveDataSum, liveDataCurrency)}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            backgroundColor: '#4D7CFE',
                            minHeight: '200px',
                            border: 'solid',
                            borderWidth: 'thin',
                            borderColor: 'black',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: '2%',
                                paddingRight: '10%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                marginLeft: 'auto',
                            }}
                        >
                            <div style={{ fontSize: '76px', color: 'white' }}>
                                {liveDataCount}
                                <span style={{ fontSize: '36px', paddingLeft: '25px' }}>
                                    {geti18nText('device.live.counting.view.pcs')}{' '}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end', // Poravnava elemente lijevo
                        position: 'fixed', // Postavlja na fiksni položaj
                        bottom: 0, // Postavlja na dno ekrana
                        right: 0, // Poravnava lijevo
                        marginRight: '5%',
                        marginBottom: '2%',
                        width: '100%',
                    }}
                >
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'black',
                            // backgroundColor: !disabledClear ? '#F4BE5E' : '#d3d3d3',

                            marginRight: '10%',
                            borderRadius: '5px',

                            padding: '15px',
                            width: '250px',
                            height: '80px',
                        }}
                        icon={<RedoOutlined />}
                        onClick={() => {
                            setVisibleRefreshModal(true);
                        }}
                    >
                        {geti18nText('device.live.counting.view.button.refresh')}
                    </Button>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'white',
                            backgroundColor: !disabledClear ? '#F4BE5E' : '#d3d3d3',
                            borderColor: 'transparent',
                            marginRight: '2%',
                            borderRadius: '5px',
                            fontStyle: 'italic',
                            padding: '15px',
                            width: '250px',
                            height: '80px',
                        }}
                        disabled={disabledClear || applicationError}
                        onClick={() => {
                            setVisiblClearModal(true);
                        }}
                    >
                        {geti18nText('device.live.counting.view.button.clear')}
                    </Button>

                    {visibleStop && (
                        <Button
                            style={{
                                fontSize: '26px',
                                color: 'white',
                                // backgroundColor: '#F4BE5E',
                                borderColor: 'transparent',
                                marginRight: '2%',
                                borderRadius: '5px',
                                fontStyle: 'italic',
                                padding: '15px',
                                width: '250px',
                                height: '80px',

                                background:
                                    !disabledStop && applicationError
                                        ? 'linear-gradient(to right, #6dab78, #a8d587)'
                                        : '#d3d3d3',
                                //  cursor: 'pointer',
                            }}
                            disabled={disabledStop || applicationError}
                            onClick={handleOnClickStop}
                        >
                            {geti18nText('device.live.counting.view.button.stop')}
                        </Button>
                    )}
                    {visibleStart && (
                        <Button
                            style={{
                                fontSize: '26px',
                                color: 'white',
                                // backgroundColor: '#F4BE5E',
                                borderColor: 'transparent',
                                marginRight: '2%',
                                borderRadius: '5px',
                                fontStyle: 'italic',
                                padding: '15px',
                                width: '250px',
                                height: '80px',

                                background: !applicationError
                                    ? 'linear-gradient(to right, #6dab78, #a8d587)'
                                    : '#d3d3d3',
                                //  cursor: 'pointer',
                            }}
                            disabled={applicationError}
                            onClick={handleOnClickStart}
                        >
                            {geti18nText('device.live.counting.view.button.start')}
                        </Button>
                    )}
                    {visibleStartTransaction && (
                        <Button
                            style={{
                                fontSize: '26px',
                                color: 'white',

                                borderColor: 'transparent',
                                marginRight: '2%',
                                borderRadius: '5px',
                                fontStyle: 'italic',
                                padding: '15px',
                                width: '250px',
                                height: '80px',

                                background: !applicationError
                                    ? 'linear-gradient(to right, #4D7CFE, #65AFFF)'
                                    : '#d3d3d3',
                            }}
                            disabled={applicationError}
                            onClick={handleOnClickStartTransaction}
                        >
                            {geti18nText('device.live.counting.view.button.transaction')}
                        </Button>
                    )}
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'white',
                            // backgroundColor: '#4D7CFE',
                            background:
                                !disabledAccept && !applicationError
                                    ? 'linear-gradient(to right, #4D7CFE, #65AFFF)'
                                    : '#d3d3d3',
                            borderColor: 'transparent',
                            borderRadius: '5px',
                            padding: '15px',
                            width: '250px',
                            height: '80px',
                        }}
                        disabled={disabledAccept || applicationError}
                        onClick={handleOnClickAccept}
                    >
                        {geti18nText('device.live.counting.view.button.accept')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default LiveCountingPreview;
