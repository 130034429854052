import { geti18nText, getColumnSearch, NyUtils } from '@nybble/nyreact';
import { formatCurrencyById, getTimezone } from '../../utils/Utils';
import { setModalOpen } from '../../slices/modalOpenSlice';
import { useDispatch } from 'react-redux';

export function serialColumns() {
    return [
        {
            title: geti18nText('serial.table.column.serial'),
            dataIndex: 'serial',
            width: '15%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('serial.table.column.currency'),
            dataIndex: ['currency', 'currency_code'],
            width: '5%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('serial.table.column.value'),
            dataIndex: 'value',
            width: '10%',
            align: 'center',

            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: string, record: { [index: string]: any }) => {
                if (record.value && record.currency?.currency_id) {
                    return (
                        <>
                            <div>{formatCurrencyById(record.value, record.currency.currency_id)}</div>
                        </>
                    );
                } else if (record.value) {
                    return (
                        <>
                            <div>{record.value}</div>
                        </>
                    );
                }
            },
        },

        {
            title: '',
            width: '50%',
        },
    ];
}

export function AllSerialColumns() {
    const dispatch = useDispatch();
    const deviceClick = (deviceId: any) => {
        dispatch(
            setModalOpen({
                modal: 'deviceEdit',
                visible: true,
                item: deviceId,
            })
        );
    };
    return [
        {
            title: geti18nText('serial.table.column.deviceName'),
            dataIndex: 'deviceName',
            width: '10%',
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <>
                        <a
                            onClick={() => deviceClick(record.deviceId)}
                            style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                        >
                            {record.deviceName != undefined ? record.deviceName : 'Device ' + record.deviceId}
                        </a>
                    </>
                );
            },
        },
        {
            title: geti18nText('serial.table.column.created'),
            dataIndex: 'created',
            width: '10%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.created) {
                    return (
                        <div>{new Date(record.created).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}</div>
                    );
                }
            },
        },
        {
            title: geti18nText('serial.table.column.serial'),
            dataIndex: 'serial',
            align: 'center',
            width: '10%',
            sorter: (a: any, b: any) => {},
        },
        {
            title: geti18nText('serial.table.column.currency'),
            dataIndex: ['currency', 'currency_code'],
            width: '5%',
            align: 'center',
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('serial.table.column.value'),
            dataIndex: 'value',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.value && record.currency?.currency_id) {
                    return (
                        <>
                            <div>{formatCurrencyById(record.value, record.currency.currency_id)}</div>
                        </>
                    );
                } else if (record.value) {
                    return (
                        <>
                            <div>{record.value}</div>
                        </>
                    );
                }
            },
        },
    ];
}
