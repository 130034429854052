import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, notification, Popconfirm, Tabs, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../../utils/Enums';
import { getTimezone } from '../../../../utils/Utils';
import { CheckOutlined, CloseOutlined, StopOutlined } from '@ant-design/icons';
import TableSettings from '../../../table_settings';
import AdminFirmwareUpdateEdit from './edit';
const AdminFirmwareUpdateIndex = () => {
    const table = TableSettings();
    const [refresh, setRefresh] = useState<any>(null);
    const timerRef = useRef<any>(null);
    function setDefaultFilterValue() {
        return [];
    }

    useEffect(() => {
        if (timerRef.current != null) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            console.log('refreshTable');
            setRefresh(new Date().getTime());
        }, 30 * 1000);

        return function cleanup() {
            clearTimeout(timerRef.current);
        };
    }, []);

    const firmwareUpdateStatus = () => {
        const aTypes = GetEnum({ enumName: 'FIRMWARE_UPDATE_STATUS' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };

                ret.id = aTypes[key];
                ret.text = geti18nText('firmware.update.status.enum.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const firmwareUpdateStatusTag = (status: any) => {
        switch (status) {
            case 0:
                return <Tag color="warning">{geti18nText('firmware.update.status.enum.' + 0)}</Tag>;
            case 1:
                return <Tag color="success">{geti18nText('firmware.update.status.enum.' + 1)}</Tag>;
            case 2:
                return <Tag color="error">{geti18nText('firmware.update.status.enum.' + 2)}</Tag>;
            case 3:
                return <Tag color="error">{geti18nText('firmware.update.status.enum.' + 3)}</Tag>;
            default:
                return <></>;
        }
    };

    const communicationType = () => {
        const aTypes = GetEnum({ enumName: 'COMMUNICATION_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = {};
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const onStopFirmwareUpdate = (record: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.FIRMWARE_UPDATE.CHANGE_STATUS + '/' + record.id, undefined, {
            status: 2,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('firmware.update.table.stop.btn.message.ok'),
                    duration: 3,
                });
                setRefresh(new Date().getTime());
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: geti18nText('firmware.update.table.stop.btn.message.nok'),
                    duration: 3,
                });
            }
        });
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('firmware.update.table.header')}
            url={CONSTANTS_REQ.FIRMWARE_UPDATE.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('firmware.update.table.add')}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_FIRMWARE'])}
            modalComponent={AdminFirmwareUpdateEdit}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            fetchWhenChange={refresh}
            columns={[
                {
                    title: geti18nText('firmware.update.table.id'),
                    dataIndex: 'id',

                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('firmware.update.table.deviceModel'),
                    dataIndex: ['deviceModel', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('firmware.update.table.communication.type'),
                    dataIndex: 'communicationType',
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                {GetEnumNameForValue({
                                    enumName: 'COMMUNICATION_TYPE',
                                    value: record.communicationType,
                                })}
                            </div>
                        );
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchOption(communicationType()),
                },
                {
                    title: geti18nText('firmware.update.table.file'),
                    dataIndex: ['firmwareFile', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('firmware.update.table.start'),
                    dataIndex: 'startTime',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.startTime) {
                            return (
                                <div>
                                    {new Date(record.startTime).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                },
                {
                    title: geti18nText('firmware.update.table.end'),
                    dataIndex: 'endTime',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.endTime) {
                            return (
                                <div>
                                    {new Date(record.endTime).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                },
                {
                    title: geti18nText('firmware.update.table.status'),
                    dataIndex: 'status',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchOption(firmwareUpdateStatus()),

                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.status != undefined) {
                            return firmwareUpdateStatusTag(record.status);
                        }
                    },
                },
                // {
                //     title: geti18nText('firmware.update.table.deviceTotalCount'),
                //     dataIndex: 'deviceCount',
                //     sorter: (a: any, b: any) => {},
                //     ...getColumnSearch('number'),
                //     width: '5%',
                //     render: (text: string, record: { [index: string]: any }) => {
                //         if (record.deviceCount != undefined) {
                //             return <Tag color="blue">{record.deviceCount}</Tag>;
                //         }
                //     },
                // },
                {
                    title: geti18nText('firmware.update.table.deviceUpdatedCount'),
                    dataIndex: 'deviceUpdatedCount',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    width: '5%',
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.deviceUpdatedCount != undefined && record.deviceCount == record.deviceUpdatedCount) {
                            return <Tag color="success">{record.deviceUpdatedCount + '/' + record.deviceCount}</Tag>;
                        } else if (record.updateErrorCount != undefined && record.updateErrorCount > 0) {
                            return <Tag color="error">{record.deviceUpdatedCount + '/' + record.deviceCount}</Tag>;
                        } else {
                            return <Tag color="blue">{record.deviceUpdatedCount + '/' + record.deviceCount}</Tag>;
                        }
                    },
                },
                {
                    title: '',
                    dataIndex: 'action',
                    //  sorter: (a: any, b: any) => {},
                    //...getColumnSearch('number'),
                    width: '5%',
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.status == 0) {
                            return (
                                <Popconfirm
                                    title={geti18nText('firmware.update.table.stop.btn.message')}
                                    onConfirm={() => onStopFirmwareUpdate(record)}
                                >
                                    <Button type="primary" danger icon={<StopOutlined />}>
                                        {geti18nText('firmware.update.table.stop.btn')}
                                    </Button>
                                </Popconfirm>
                            );
                        }
                    },
                },
            ]}
        />
    );
};

export default AdminFirmwareUpdateIndex;
