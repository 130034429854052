import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormatedDatePicker } from '../../../components/formated-date-picker/formatedDatePicker';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum } from '../../../utils/Enums';

const { Title } = Typography;
const HnbSnapshotHistoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('hnb.snapshot.table.edit'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [deviceTypes, setDeviceTypes] = useState([]);

    useEffect(() => {
        getDeviceTypes();
    }, []);

    const onModalClose = () => {
        setEditHeader(geti18nText('hnb.snapshot.table.edit'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        if (dataForm.hnbDeviceLocation != undefined) {
            dataForm.hnbDeviceLocation = {
                id: dataForm.hnbDeviceLocation,
                text: geti18nText('hnb.device.location.' + dataForm.hnbDeviceLocation),
            };
        }
        if (dataForm.hnbDeviceType) {
            dataForm.hnbDeviceType = {
                id: dataForm.hnbDeviceType,
                text: geti18nText('hnb.device.type.' + dataForm.hnbDeviceType),
            };
        }
        if (dataForm.maintenanceDate) {
            const d = dataForm.maintenanceDate;
            dataForm.maintenanceDate = moment(new Date(Date.UTC(d[0], d[1] - 1, d[2], d[3], d[4], d[5])));
        }
        form.setFieldsValue(dataForm);
    }

    const getDeviceTypes = async () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.HNB_DEVICE_TYPE.LIST_ALL, undefined).then((res: any) => {
            let types: any = [];
            if (res.status === RESPONSE.OK && res.data) {
                const aTypes = res.data;

                aTypes.forEach((element: any) => {
                    let ret: any = {};
                    ret.id = element.id;
                    ret.text = element.name;
                    types.push(ret);
                });
            }
            setDeviceTypes(types);
        });
    };

    const deviceLoction = () => {
        const aTypes = GetEnum({ enumName: 'HNB_DEVICE_LOCATION' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: '', text: '' };
                ret.id = aTypes[key];
                ret.text = geti18nText('hnb.device.location.' + aTypes[key]);
                types.push(ret);
            }
        }
        return types;
    };

    function normalize(values: any) {
        let normalized = { ...values };

        normalized.hnbDeviceLocation = normalized.hnbDeviceLocation?.id;
        normalized.hnbDeviceType = normalized.hnbDeviceType?.id;
        if (values.maintenanceDate) normalized.maintenanceDate = moment(normalized.maintenanceDate);
        console.log(normalized);

        return normalized;
    }

    return (
        <NyDataEdit
            layout="horizontal"
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.HNB_SNAPSHOT_HISTORY.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalize}
            {...props}
            hideActivationButtons={true}
        >
            <Divider orientation={'left'} plain>
                <Title level={5}>{geti18nText('hnb.snapshot.table.edit.device')}</Title>
            </Divider>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item label={geti18nText('hnb.snapshot.table.device_id')} name="deviceId">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.device_name')} name="deviceName">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.device_manufacturer')} name="deviceManufacturer">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.device_model')} name="deviceModel">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.ecb')} name="ecbIdentification">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.device.table.count_state')} name="countState">
                        <Input min={1} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.device.table.program_count_state')} name="programCountState">
                        <Input min={1} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label={geti18nText('hnb.snapshot.table.device_inventory_number')}
                        name="deviceInventoryNumber"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.device_serial_number')} name="deviceSerialNumber">
                        <Input min={1} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation={'left'} plain>
                <Title level={5}>{geti18nText('hnb.snapshot.table.edit.hnb_device')}</Title>
            </Divider>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.device.table.hnb_device_type')} name={'hnbDeviceType'}>
                        <NySearchField options={deviceTypes} map={{ id: 'id', label: 'text' }} searchBy="text" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.device.table.hnb_device_location')} name={'hnbDeviceLocation'}>
                        <NySearchField options={deviceLoction()} map={{ id: 'id', label: 'text' }} searchBy="text" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.maintenance_date')} name={'maintenanceDate'}>
                        <FormatedDatePicker showTime={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.software_eur')} name="softwareEur">
                        <Input min={1} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.software_hr')} name="softwareHr">
                        <Input min={1} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.hnb_device_code')} name="hnbDeviceCode">
                        <Input min={1} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation={'left'} plain>
                <Title level={5}>{geti18nText('hnb.snapshot.table.edit.branch')}</Title>
            </Divider>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.branch_id')} name="branchId">
                        <Input min={1} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.branch_name')} name="branchName">
                        <Input min={1} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.branch_external_id')} name="branchExternalId">
                        <Input min={1} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={geti18nText('hnb.snapshot.table.branch_hnb_code')} name="branchHnbCode">
                        <Input min={1} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default HnbSnapshotHistoryEdit;
