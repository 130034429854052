import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';

import TableSettings from '../table_settings';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { formatCurrencyById } from '../../utils/Utils';
import { serialColumns } from './columns';

const CountSerialIndex = (props: any) => {
    const table = TableSettings();
    const [refresh, setRefresh] = useState<any>(undefined);

    useEffect(() => {
        setRefresh(new Date());
    }, [props?.countId]);

    function setDefaultFilterValue() {
        let data: any[] = [{ field: 'active', condition: 'equals_bool', value: 1 }];
        if (props?.countId) {
            data.push({ field: 'count_id', condition: 'equals', value: props.countId });
        }

        if (props?.deviceId) {
            data.push({ field: 'device_id', condition: 'equals', value: props.deviceId });
        }
        return data;
    }

    function onCancelCountDetailsVisible() {
        props.setVisible(false);
    }
    return (
        <Modal
            visible={props.visible}
            footer={null}
            onCancel={onCancelCountDetailsVisible}
            destroyOnClose={true}
            width={700}
        >
            <NyDataTable
                headerTitle={geti18nText('serial.table.header')}
                url={CONSTANTS_REQ.COUNT_SERIAL.LIST}
                hideButtons
                readonly={true}
                setDefaultPageSize={table.setDefaultPageSize(20)}
                onDataLoaded={table.onLoadData}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                exportCSV={true}
                fetchWhenChange={refresh}
                columns={serialColumns()}
            />
        </Modal>
    );
};

export default CountSerialIndex;
