import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Tabs, Tag } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import DeviceErrorColumns from '../../../device_error/columns';
import { getTimezone } from '../../../../utils/Utils';

const DeviceFirmwareUpdateIndex = (props: any) => {
    const { TabPane } = Tabs;

    function setDefaultFilterValue() {
        if (props.deviceId != null) {
            const id = parseInt(props.deviceId);
            if (id != undefined) return [{ field: 'device_id', condition: 'equals', value: id }];
        }

        return [];
    }

    const firmwareUpdateStatusTag = (status: any) => {
        switch (status) {
            case 0:
                return <Tag color="blue">{geti18nText('firmware.update.device.status.enum.' + 0)}</Tag>;
            case 1:
                return <Tag color="warning">{geti18nText('firmware.update.device.status.enum.' + 1)}</Tag>;
            case 2:
                return <Tag color="success">{geti18nText('firmware.update.device.status.enum.' + 2)}</Tag>;
            case 3:
                return <Tag color="error">{geti18nText('firmware.update.device.status.enum.' + 3)}</Tag>;
            default:
                return <></>;
        }
    };

    function columns() {
        return [
            {
                title: geti18nText('firmware.update.table.device.start'),
                dataIndex: 'startTime',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(true, undefined, undefined, true),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.startTime) {
                        return (
                            <div>
                                {new Date(record.startTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('firmware.update.table.device.end'),
                dataIndex: 'endTime',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(true, undefined, undefined, true),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.endTime) {
                        return (
                            <div>
                                {new Date(record.endTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('firmware.update.table.device.status'),
                dataIndex: 'status',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.status != undefined) {
                        return firmwareUpdateStatusTag(record.status);
                    }
                },
            },
            {
                title: geti18nText('firmware.update.table.device.updateName'),
                dataIndex: ['firmwareFile', 'name'],

                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ];
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('device.error.table.header')}
            url={CONSTANTS_REQ.FIRMWARE_UPDATE.DEVICE_UPDATE_LIST}
            setDefaultPageSize={10}
            exportCSV={true}
            showRecordModal={false}
            hideButtons={true}
            showRowSelection={false}
            setDefaultFilterValue={setDefaultFilterValue}
            readonly={true}
            columns={columns()}
        />
    );
};

export default DeviceFirmwareUpdateIndex;
