import {
    geti18nText,
    NyDataEdit,
    NySession,
    NyMaskContent,
    NyRequestResolver,
    RESPONSE,
    NySettings,
    NySearchField,
    NyUtils,
} from '@nybble/nyreact';
import {
    Col,
    Form,
    Input,
    Row,
    Tabs,
    Image,
    Descriptions,
    Tooltip,
    Button,
    Modal,
    InputNumber,
    Checkbox,
    Radio,
    Alert,
} from 'antd';
import L from 'leaflet';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { LatLng, Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ, URL_PREFIX } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import DeviceSearchIndex from '../../device/searchIndex';
import DeviceModelEdit from '../device-model/edit';
import DeviceGroupEdit from '../device_groups/edit';
import AdminLocationEdit from '../office/edit';

const AdminNotificationAlarmEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('notification.alarm.edit.new'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const [deviceValue, setDeviceValue] = useState<any>(undefined);
    const [radioValue, setRadioValue] = useState<any>('device');
    const [notificationType, setNotificationType] = useState(null);
    const [notificationTypeEnum, setNotificationTypeEnum] = useState(null);

    const onModalClose = () => {
        setEditHeader(geti18nText('notification.alarm.edit.new'));
        setDeviceValue(undefined);
        setRadioValue('device');
        setNotificationTypeEnum(null);
        setNotificationType(null);
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(geti18nText('notification.alarm.edit.header'));
        dataForm.language = { id: dataForm.language, text: dataForm.language };
        const notificationType = dataForm.notificationType;
        dataForm.notificationType = {
            id: dataForm.notificationType,
            text: geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + dataForm.notificationType),
        };
        if (dataForm.device != null) {
            dataForm.device = { id: dataForm.device.id, name: dataForm.device.name };
            setDeviceValue(dataForm.device.name);
            setRadioValue('device');
        }
        if (dataForm.office != null) {
            dataForm.office = { id: dataForm.office.id, name: dataForm.office.name };
            setRadioValue('location');
        }
        if (dataForm.deviceGroup != null) {
            dataForm.deviceGroup = { id: dataForm.deviceGroup.id, name: dataForm.deviceGroup.name };
            setRadioValue('deviceGroup');
        }
        if (dataForm.deviceModel != null) {
            dataForm.deviceModel = { id: dataForm.deviceModel.id, name: dataForm.deviceModel.name };
            setRadioValue('deviceModel');
        }
        if (dataForm.additionalFilter) {
            dataForm.interruptionPeriod = parseInt(dataForm.additionalFilter);
            delete dataForm.additionalFilter;
        }
        if (dataForm['users']) {
            dataForm['users'] = dataForm['users'].map((u: any) => {
                return { key: u.id, label: u.fullName };
            });
        }

        if (notificationType) {
            const value: any = GetEnumNameForValue({
                enumName: 'NOTIFICATION_ALARM_TYPE',
                value: parseInt(notificationType),
            });
            setNotificationType(notificationType);
            setNotificationTypeEnum(value);
        }
        form.setFieldsValue(dataForm);
    }

    function normalize(data: any) {
        const values = form.getFieldsValue();
        values.language = values.language.id;
        values.notificationType = values.notificationType.id;
        if (values.device != null) {
            values.device = { id: values.device.id };
        }
        if (values.office != null) {
            values.office = { id: values.office.id };
        }
        if (values.deviceGroup != null) {
            values.deviceGroup = { id: values.deviceGroup.id };
        }
        if (values.deviceModel != null) {
            values.deviceModel = { id: values.deviceModel.id };
        }
        values.active = data.active;
        if (values.language === -1) {
            delete values.language;
        }
        if (values.device && values.device.id && values.device.id === -1) {
            delete values.device;
        }
        if (values.office && values.office.id && values.office.id === -1) {
            delete values.office;
        }
        if (values.deviceGroup && values.deviceGroup.id && values.deviceGroup.id === -1) {
            delete values.deviceGroup;
        }
        if (values.deviceModel && values.deviceModel.id && values.deviceModel.id === -1) {
            delete values.deviceModel;
        }
        if (values.interruptionPeriod) {
            values.additionalFilter = values.interruptionPeriod;
            delete values.interruptionPeriod;
        }

        if (values.users) {
            if (Array.isArray(values.users)) {
                values.usersList = values.users.map((user: any) => {
                    return Number(user.key);
                });
            } else {
                values.usersList = [Number(values.users)];
            }
        }
        delete values.users;
        return values;
    }

    function setIsCreate(val: any) {
        if (val === true) {
            form.setFieldsValue({
                language: NyUtils.getSelectedLocale() == 'hr-HR' ? { id: 'hr', text: 'hr' } : { id: 'en', text: 'en' },
            });
        }
    }

    const languages = () => {
        const aTypes = NySession.getSetting('lang-available');
        let types = [];
        for (var key in aTypes) {
            let ret = { id: undefined, text: '' };
            ret.id = aTypes[key];
            ret.text = aTypes[key];
            types.push(ret);
        }

        return types;
    };

    const notificationAlarmTypes = () => {
        const aTypes = GetEnum({ enumName: 'NOTIFICATION_ALARM_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                if (key !== 'SYSTEM_WARNING_LICENSE_EXPIRATION' && key != 'DEVICE_LOG_REQUEST_END') {
                    ret.id = aTypes[key];
                    ret.text = geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + aTypes[key]);
                    types.push(ret);
                }
            }
        }
        return types;
    };

    function emailValidator(rule: any, value: any, callback: any) {
        var regex =
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/;
        if (!regex.test(value)) {
            callback(geti18nText('notification.alarm.table.recipient.error'));
            return;
        }
        callback();
    }

    function requiredValidator(rule: any, value: any, callback: any) {
        if (value == null || value == '' || value.id == -1) {
            callback(geti18nText('app.default.required'));
            return;
        }
        callback();
    }

    function onRowClickHandler(record: any) {
        form.setFieldsValue({ device: record });
        setDeviceValue(record.name);
    }

    const options = () => {
        let opt = [
            { label: geti18nText('statistic.chart.modal.option.device'), value: 'device' },
            { label: geti18nText('statistic.chart.modal.option.device.group'), value: 'deviceGroup' },
            { label: geti18nText('statistic.chart.modal.option.location'), value: 'office' },
            { label: geti18nText('statistic.chart.modal.option.device.model'), value: 'deviceModel' },
        ];

        return opt;
    };

    function onChangeRadio(val: any) {
        let resetFields = ['device', 'office', 'deviceGroup', 'deviceModel'];
        let r = resetFields.filter((v: any) => {
            return v !== val.target.value;
        });

        form.resetFields(r);
        if (val.target.value !== 'device') {
            setDeviceValue(undefined);
        }
        setRadioValue(val.target.value);
    }
    const onNotificationAlarmChange = (val: any) => {
        setNotificationType(val?.id);
        if (val?.id != undefined) {
            const value: any = GetEnumNameForValue({
                enumName: 'NOTIFICATION_ALARM_TYPE',
                value: parseInt(val.id),
            });

            setNotificationTypeEnum(value);
        } else {
            setNotificationTypeEnum(null);
        }
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 8 }, wrapperCol: { span: 12 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.NOTIFICATION_ALARM.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            normalize={normalize}
            hideButtons={!NySession.hasAnyRole(['RULE_CRUD_NOTIFICATION'])}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input readOnly={true} />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        label={geti18nText('notification.alarm.table.recipient')}
                        name="users"
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            mode="multiple"
                            searchBy="text"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('notification.alarm.table.note.alarm.language')} name="language">
                        <NySearchField
                            style={{ width: '100%' }}
                            options={languages()}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('notification.alarm.table.note.alarm.type')}
                        name="notificationType"
                        rules={[
                            {
                                required: true,
                                validator: requiredValidator,
                            },
                        ]}
                    >
                        <NySearchField
                            style={{ width: '100%' }}
                            options={notificationAlarmTypes()}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={onNotificationAlarmChange}
                        />
                    </Form.Item>
                </Col>
                {notificationType && (
                    <Col span={12}>
                        <Alert
                            message={geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + notificationType)}
                            description={geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + notificationType + '.info')}
                            type="info"
                            showIcon
                            style={{ width: '100%' }}
                        />
                    </Col>
                )}
            </Row>
            {(notificationTypeEnum == 'DISCONNECTING_DEVICE_INFO' || notificationTypeEnum == 'STOP_COUNTING_INFO') && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('notification.alarm.edit.interruption.period')}
                            name="interruptionPeriod"
                            rules={[
                                {
                                    required: true,
                                    validator: requiredValidator,
                                },
                            ]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row>
                <Col offset={4} span={8}>
                    <Radio.Group value={radioValue} options={options()} onChange={onChangeRadio} />
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
                <Col span={12} hidden={radioValue !== 'device'}>
                    <Form.Item label={geti18nText('notification.alarm.edit.device')} name="device">
                        <DeviceSearchIndex deviceValue={deviceValue} onRowClickHandler={onRowClickHandler} />
                    </Form.Item>
                </Col>
                <Col span={12} hidden={radioValue !== 'deviceGroup'}>
                    <Form.Item label={geti18nText('device.group.title')} name="deviceGroup">
                        <NySearchField
                            url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            AddNewModalComponent={DeviceGroupEdit}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} hidden={radioValue !== 'office'}>
                    <Form.Item label={geti18nText('device.table.column.office')} name="office">
                        <NySearchField
                            url={CONSTANTS_REQ.LOCATION.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            AddNewModalComponent={AdminLocationEdit}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} hidden={radioValue !== 'deviceModel'}>
                    <Form.Item label={geti18nText('device.table.column.model')} name="deviceModel">
                        <NySearchField
                            url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            AddNewModalComponent={DeviceModelEdit}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}></Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name="sendOnMail"
                        valuePropName="checked"
                        label={geti18nText('notification.alarm.edit.send.on.mail')}
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AdminNotificationAlarmEdit;
