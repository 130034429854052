import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, notification, Popconfirm, Row, Tabs, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import AdminFirmwareFileEdit from './edit';
import TableSettings from '../../table_settings';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getTimezone } from '../../../utils/Utils';
import { CheckOutlined, CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { GetEnum } from '../../../utils/Enums';
const AdminDeviceLogIndex = (props: any) => {
    const [refresh, setRefresh] = useState<any>(null);
    const timerRef = useRef<any>(null);
    const table = TableSettings();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        console.log('REFRESH');
        if (timerRef.current != null) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            console.log('refreshTable');
            setRefresh(new Date().getTime());
        }, 30 * 1000);

        return function cleanup() {
            clearTimeout(timerRef.current);
        };
    }, []);

    function setDefaultFilterValue() {
        if (props.deviceId != null) {
            const id = parseInt(props.deviceId);
            if (id != undefined) return [{ field: 'device.id', condition: 'equals', value: id }];
        }

        return [];
    }

    const deviceLogRequestStatus = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_LOG_REQUEST_STATUS' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };

                ret.id = aTypes[key];
                ret.text = geti18nText('device.log.request.status.enum.' + ret.id);
                types.push(ret);
            }
        }
        return types;
    };

    const deviceLogRequestStatusTag = (status: any) => {
        switch (status) {
            case 0:
                return <Tag color="warning">{geti18nText('device.log.request.status.enum.' + 0)}</Tag>;
            case 1:
                return <Tag color="success">{geti18nText('device.log.request.status.enum.' + 1)}</Tag>;
            case 2:
                return <Tag color="error">{geti18nText('device.log.request.status.enum.' + 2)}</Tag>;
            default:
                return <></>;
        }
    };

    function downloadFile(record: any) {
        setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DEVICE_LOG_REQUEST.DOWNLOAD,
            { requestId: record.id },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {
                NyUtils.downloadFile(result);
                setLoading(false);
                // okNotification(geti18nText('currency.denomination.export.OK'));
            } else {
                notification['error']({
                    message: geti18nText('app.default.error'),
                    duration: 0,
                });
            }
        });
    }

    function columns() {
        let columns = [
            {
                title: geti18nText('firmware.device.log.request.table.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('firmware.device.log.request.table.device'),
                dataIndex: ['device', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('firmware.device.log.request.table.start'),
                dataIndex: 'startTime',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(true, undefined, undefined, true),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.startTime) {
                        return (
                            <div>
                                {new Date(record.startTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('firmware.device.log.request.table.end'),
                dataIndex: 'endTime',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(true, undefined, undefined, true),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.endTime) {
                        return (
                            <div>
                                {new Date(record.endTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('firmware.device.log.request.table.status'),
                dataIndex: 'status',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(deviceLogRequestStatus()),

                render: (text: string, record: { [index: string]: any }) => {
                    if (record.status != undefined) {
                        return deviceLogRequestStatusTag(record.status);
                    }
                },
            },
            {
                title: '',
                dataIndex: 'action',
                //  sorter: (a: any, b: any) => {},
                //...getColumnSearch('number'),
                width: '5%',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.status == 1) {
                        return (
                            <Button
                                type="primary"
                                loading={loading}
                                onClick={() => downloadFile(record)}
                                icon={<CloudDownloadOutlined />}
                            >
                                {geti18nText('firmware.device.log.request.table.btn.download')}
                            </Button>
                        );
                    }
                },
            },
        ];
        return props.deviceId != undefined ? columns.filter((x: any) => !x.dataIndex.includes('device')) : columns;
    }

    const logRequest = () => {
        if (props.deviceId != undefined) {
            const id = parseInt(props.deviceId);
            NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE_LOG_REQUEST.EDIT, undefined, {
                device: { id: id },
            }).then((result: any) => {
                setLoading(false);

                if (result.status === RESPONSE.CREATED) {
                    setRefresh(new Date().getTime());
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        description: result.data?.message,
                        duration: 0,
                    });
                }
            });
        }
    };

    return (
        <>
            {props.deviceId && props.useParser == true && (
                <Row gutter={24}>
                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={24}>
                        <Button type="primary" style={{ textAlign: 'right' }} onClick={() => logRequest()}>
                            {geti18nText('firmware.device.log.request.table.add')}
                        </Button>
                    </Col>
                </Row>
            )}
            <NyDataTable
                headerTitle={geti18nText('firmware.device.log.request.table.header')}
                url={CONSTANTS_REQ.DEVICE_LOG_REQUEST.LIST}
                showRecordModal={true}
                addNewButtonText={geti18nText('firmware.device.log.request.table.add')}
                hideButtons={!NySession.hasAnyRole(['RULE_DEVICE_LOG_PREVIEW'])}
                modalComponent={AdminFirmwareFileEdit}
                setDefaultPageSize={table.setDefaultPageSize(20)}
                onDataLoaded={table.onLoadData}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                readonly={true}
                fetchWhenChange={refresh}
                hideNewButton={props.deviceId != undefined}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={columns()}
            />
        </>
    );
};

export default AdminDeviceLogIndex;
