import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySearchField,
    NyUtils,
} from '@nybble/nyreact';

import TableSettings from '../table_settings';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import CountingDetailSerialIndex from '../counting/detailsSerial';
import { formatCurrencyById, getTimezone } from '../../utils/Utils';
import { AllSerialColumns, serialColumns } from './columns';
import { useDispatch } from 'react-redux';
import { setModalOpen } from '../../slices/modalOpenSlice';
import moment from 'moment';
import { FormatedDatePicker } from '../../components/formated-date-picker/formatedDatePicker';

const AllCountSerialIndex = (props: any) => {
    const table = TableSettings();

    const [detailsVisible, setDetailsVisible] = useState<any>(false);
    const [detailDeviceVisible, setDetailDeviceVisible] = useState<any>(false);
    const [serial, setSerial] = useState<any>(undefined);
    const [deviceId, setDeviceId] = useState<any>(undefined);
    const [addedData, setAddedData] = useState<any>(undefined);
    const [refresh, setRefresh] = useState<any>(undefined);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    function onRowDetails(record: any) {
        if (record.deviceId) {
            setSerial(record.serial);
            setDeviceId(record.deviceId);
            setDetailsVisible(true);
        }
    }

    function setDefaultFilterValue() {
        if (props?.deviceId) {
            return [
                { field: 'device_id', condition: 'equals', value: props.deviceId },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    function search() {
        // setAddedData(undefined);
        form.validateFields().then((val: any) => {
            let data: any = { serial: val.serial };

            if (val.device && val.device.id > -1) {
                data.deviceId = val.device.id;
            }
            if (val.currency && val.currency.id != -1) {
                data.currencyId = val.currency.id;
            }
            setAddedData(data);
            setRefresh(new Date());
        });
    }

    return (
        <>
            <Form style={{ marginTop: '15px' }} form={form}>
                <Row>
                    <Col span={5}>
                        <Form.Item
                            label={geti18nText('serial.table.filter.serial')}
                            name="serial"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                        <Form.Item label={geti18nText('serial.table.filter.device')} name={'device'}>
                            <NySearchField
                                url={CONSTANTS_REQ.DEVICE.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <Form.Item label={geti18nText('serial.table.filter.currency')} name={'currency'}>
                            <NySearchField
                                url={CONSTANTS_REQ.CURRENCY.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                            />
                        </Form.Item>
                    </Col>
                    <Col offset={1}>
                        <Button type="primary" onClick={() => search()}>
                            {geti18nText('app.default.button.search')}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {addedData && (
                <NyDataTable
                    headerTitle={geti18nText('serial.table.header')}
                    url={CONSTANTS_REQ.COUNT_SERIAL.LIST_BY}
                    hideButtons
                    setDefaultPageSize={table.setDefaultPageSize(20)}
                    onDataLoaded={table.onLoadData}
                    setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    setDefaultSortOrder={table.setDefaultSortOrder()}
                    exportCSV={true}
                    onRowSelect={onRowDetails}
                    addedData={addedData}
                    fetchWhenChange={refresh}
                    columns={AllSerialColumns()}
                    colCSVCustomization={[
                        {
                            created: (value: string) => {
                                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
                            },
                        },
                    ]}
                />
            )}

            <CountingDetailSerialIndex
                deviceId={deviceId}
                serial={serial}
                visible={detailsVisible}
                setVisible={setDetailsVisible}
            ></CountingDetailSerialIndex>
        </>
    );
};

export default AllCountSerialIndex;
