import {
    ApiOutlined,
    CheckOutlined,
    CloseOutlined,
    CommentOutlined,
    PoweroffOutlined,
    StopOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Comment, Popconfirm, Popover, Progress, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { GetEnum } from '../../../../utils/Enums';
import { getTimezone } from '../../../../utils/Utils';

const deviceModels = () => {
    const models = NyUtils.load('DEVICE_MODELS');
    let types: any = [];
    models.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });
    return types;
};

const deviceStatus = (status = undefined) => {
    const aTypes = GetEnum({ enumName: 'DEVICE_STATUS' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret = { id: undefined, text: '' };

            ret.id = aTypes[key];
            ret.text = geti18nText('device.status.enum.' + key);
            if (status == null || status == aTypes[key]) {
                types.push(ret);
            }
        }
    }
    return types;
};

const manufacturers = () => {
    const manufacturers = NyUtils.load('MANUFACTURERS');
    let types: any = [];
    manufacturers.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const offices = () => {
    const manufacturers = NyUtils.load('OFFICE');
    let types: any = [];
    manufacturers.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const groups = (groupIds: any = undefined) => {
    const groups = NyUtils.load('DEVICE_GROUPS');
    let types: any = [];
    groups.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        if (groupIds == null || groupIds.includes(element.id.toString())) {
            types.push(ret);
        }
    });

    return types;
};

function content(text: any) {
    let contentArray = text.split(',');
    return contentArray.map((c: any) => {
        return <div>{c}</div>;
    });
}

const deviceMode = () => {
    const aTypes = GetEnum({ enumName: 'DEVICE_MODE' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret.id = aTypes[key];
            ret.text = key;
            types.push(ret);
        }
    }
    return types;
};

const { Text } = Typography;

const enumUseParser = () => {
    return [
        { id: 1, text: geti18nText('device.table.use.parser.true') },
        { id: 0, text: geti18nText('device.table.use.parser.false') },
    ];
};

const firmwareUpdateStatusTag = (status: any) => {
    switch (status) {
        case 0:
            return <Tag color="blue">{geti18nText('firmware.update.device.status.enum.' + 0)}</Tag>;
        case 1:
            return <Tag color="warning">{geti18nText('firmware.update.device.status.enum.' + 1)}</Tag>;
        case 2:
            return <Tag color="success">{geti18nText('firmware.update.device.status.enum.' + 2)}</Tag>;
        case 3:
            return <Tag color="error">{geti18nText('firmware.update.device.status.enum.' + 3)}</Tag>;
        case 4:
            return <Tag color="error">{geti18nText('firmware.update.device.status.enum.' + 4)}</Tag>;
        case 5:
            return <Tag color="error">{geti18nText('firmware.update.device.status.enum.' + 5)}</Tag>;
        default:
            return <></>;
    }
};

function DeviceFirmwareColumns(
    firmwareUpdate = false,
    onStopFirmwareDeviceUpdate: any = null,
    progres: any = undefined
) {
    let columns: any = [
        {
            dataIndex: 'status',
            ...getColumnSearchOption(deviceStatus(undefined)),
            render: (text: string, record: { [index: string]: any }) => {
                return record.useParser && record.useParser == true ? (
                    <div>
                        {record.status == 0 ? (
                            <PoweroffOutlined style={{ color: 'green', marginRight: '5px' }} />
                        ) : record.status == 1 ? (
                            <PoweroffOutlined className="Blink" style={{ color: 'red', marginRight: '5px' }} />
                        ) : (
                            <PoweroffOutlined style={{ color: 'orange', marginRight: '5px' }} />
                        )}
                    </div>
                ) : (
                    <></>
                );
            },
        },
        {
            title: geti18nText('device.table.column.id'),
            dataIndex: 'id',
            width: '5%',

            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: string, record: { [index: string]: any }) => {
                return <div>{record.id}</div>;
            },
        },
        {
            dataIndex: 'useParser',
            ...getColumnSearchOption(enumUseParser(), 'equals_bool'),
            render: (text: string, record: { [index: string]: any }) => {
                return record.useParser && record.useParser == true ? (
                    <div>
                        <Tooltip title={geti18nText('device.table.use.parser.true')}>
                            <ApiOutlined style={{ color: 'blue' }} />
                        </Tooltip>
                    </div>
                ) : (
                    <div>
                        <Tooltip title={geti18nText('device.table.use.parser.false')}>
                            <ApiOutlined style={{ color: 'gray' }} />
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: geti18nText('device.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },

        {
            title: geti18nText('device.table.column.office'),
            dataIndex: ['office', 'id'],
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.office != null) {
                    return <div>{record.office.name}</div>;
                }
            },
            ...getColumnSearchOption(offices()),
        },

        {
            title: geti18nText('device.table.column.ip'),
            dataIndex: 'ip',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },

        {
            title: geti18nText('device.table.column.serial.number'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.manufacturer'),
            dataIndex: ['manufacturer', 'id'],
            render: (text: string, record: { [index: string]: any }) => {
                if (record.manufacturer != null) {
                    return <div>{record.manufacturer.name}</div>;
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(manufacturers()),
        },
    ];

    if (firmwareUpdate == true) {
        columns.push(
            {
                title: geti18nText('firmware.update.table.device.start'),
                dataIndex: 'startTime',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(true, undefined, undefined, true),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.startTime) {
                        return (
                            <div>
                                {new Date(record.startTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('firmware.update.table.device.end'),
                dataIndex: 'endTime',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(true, undefined, undefined, true),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.endTime) {
                        return (
                            <div>
                                {new Date(record.endTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
            },
            {
                title: geti18nText('firmware.update.table.device.status'),
                dataIndex: 'updateStatus',
                //  sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.updateStatus != undefined) {
                        return record.error != undefined ? (
                            <Popover content={record.error}>{firmwareUpdateStatusTag(record.updateStatus)}</Popover>
                        ) : (
                            firmwareUpdateStatusTag(record.updateStatus)
                        );
                    }
                },
            },
            {
                title: '',
                dataIndex: 'action',
                //  sorter: (a: any, b: any) => {},
                //...getColumnSearch('number'),
                width: '5%',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.updateStatus == 0 || record.updateStatus == 1) {
                        return (
                            <Popconfirm
                                title={geti18nText('firmware.update.table.stop.btn.message')}
                                onConfirm={() => {
                                    console.log('update1', onStopFirmwareDeviceUpdate);
                                    if (onStopFirmwareDeviceUpdate != null) {
                                        console.log('update ');
                                        onStopFirmwareDeviceUpdate(record);
                                    }
                                }}
                            >
                                <Button type="primary" danger icon={<StopOutlined />}>
                                    {geti18nText('firmware.update.table.stop.btn')}
                                </Button>
                            </Popconfirm>
                        );
                    }
                },
            },
            {
                title: '',
                dataIndex: 'progress',
                width: '10%',
                render: (text: string, record: { [index: string]: any }) => {
                    if (progres != undefined && record.updateStatus == 1) {
                        return progres;
                    }
                },
            }
        );
    }

    if (firmwareUpdate == false) {
        columns.push(
            {
                title: geti18nText('firmware.update.table.device.updateName'),
                dataIndex: ['firmeareFile'],

                render: (text: string, record: { [index: string]: any }) => {
                    if (record.firmeareFile != null && record.firmeareFile['value'] != undefined) {
                        const object = JSON.parse(record.firmeareFile.value);
                        if (object) {
                            return <div>{object.name}</div>;
                        }
                    }
                },
            },
            {
                title: geti18nText('firmware.update.table.device.updateTime'),
                dataIndex: 'startTime',

                render: (text: string, record: { [index: string]: any }) => {
                    if (record.firmeareFile != null && record.firmeareFile['value'] != undefined) {
                        const object = JSON.parse(record.firmeareFile.value);
                        if (object) {
                            return (
                                <div>
                                    {new Date(object.end).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                                </div>
                            );
                        }
                    }
                },
            }
        );
    }

    return columns;
}

export default DeviceFirmwareColumns;
