import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Alert, Col, Form, Input, Row } from 'antd';
import { GetEnum } from '../../../../utils/Enums';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
const FirmwareFileSelect = (props: any) => {
    console.log(props);
    const [deviceModel, setDeviceModel] = useState(null);
    //    const [deleted, setDeleted] = useState(props.deleted ? props.deleted : false);

    function onDeviceTypeChange(value: any) {
        console.log(value);
        props.form.resetFields(['fileName']);

        if (value != undefined) {
            props.setDeviceModel(value.id);
            setDeviceModel(value.id);
        }
    }

    function getFilterData() {
        let data: any = deviceModel ? { deviceModelId: deviceModel } : {};
        if (props.isCreate == true) {
            data['status'] = 2;
        }
        console.log(data);
        return data;
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        label={geti18nText('firmware.file.table.device.model')}
                        name="deviceModel"
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                            onChange={onDeviceTypeChange}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('firmware.update.table.file')}
                        name="fileName"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.FIRMWARE.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            addedData={getFilterData()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {props.deleted == true && (
                <Row gutter={24}>
                    <Col span={6} offset={18}>
                        <Alert
                            type="error"
                            message={geti18nText('firmware.update.table.error.nofile')}
                            banner
                            style={{ marginLeft: 30 }}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default FirmwareFileSelect;
