import { CheckOutlined, CloseOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Divider, Form, notification, Row, Tabs, Upload } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum } from '../../../utils/Enums';
import ServiceVendorEdit from '../service-vendor/edit';
import { CSVLink } from 'react-csv';
import React from 'react';
import { CommentComponent } from '../device/columns';
import { getTimezone } from '../../../utils/Utils';

const AdminServiceInformationIndex = () => {
    const { TabPane } = Tabs;
    const [loading, setLoading] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);
    const [added, setAdded] = useState<any>(undefined);
    const [imported, setImported] = useState(0);
    const [form] = Form.useForm();
    const [exportData, setExportData] = useState([]);
    const [deviceTypes, setDeviceTypes] = useState<any>([]);
    const csvLinkEl = useRef<any>();

    useEffect(() => {
        getDeviceTypes();
    }, []);

    function setDefaultFilterValue() {
        if (!NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
            return [
                { field: 'writtenOff', condition: 'equals_bool', value: 0 },
                { field: 'active', condition: 'equals_bool', value: 1 },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    function exportDataCsv() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.EXPORT_SERVICE_INFORMATION, {
            serviceVendorId:
                form.getFieldValue('serviceVendor') != null ? form.getFieldValue('serviceVendor').id : undefined,
        }).then((result: any) => {
            result.data.forEach((d: any) => {
                if (d['hnbDevice'] && d['hnbDevice']['maintenanceDate'] != null) {
                    d['hnbDevice']['maintenanceDate'] = new Date(d['hnbDevice']['maintenanceDate'])
                        .toLocaleString(NyUtils.getSelectedLocale(), getTimezone())
                        .replace(' ', '')
                        .replace(' ', '');
                }
                if (d['hnbDevice'] && d['hnbDevice']['hnbDeviceType'] != null) {
                    d['hnbDevice']['hnbDeviceType'] = geti18nText('hnb.device.type.' + d['hnbDevice']['hnbDeviceType']);
                }
                if (d['hnbDevice'] && d['hnbDevice']['hnbDeviceLocation'] != null) {
                    d['hnbDevice']['hnbDeviceLocation'] = geti18nText(
                        'hnb.device.location.' + d['hnbDevice']['hnbDeviceLocation']
                    );
                }

                return d;
            });
            setExportData(result.data);
            csvLinkEl.current.link.click();
            setLoading(false);
        });
    }

    function okNotification(message: any, description: any) {
        notification['success']({
            message: message,
            duration: 10,
            description: description,
        });
    }

    function error(message: any) {
        notification['error']({
            message: geti18nText('service.information.import.nok'),
            description: message,
            duration: 0,
        });
    }

    function warning(message: any) {
        notification['warning']({
            message: geti18nText('service.information.import.nok'),
            description: message,
            duration: 0,
        });
    }

    function calculateImported() {
        let im = imported;
        im++;
        setImported(im);
    }

    function getErrorContent(errors: any) {
        return (
            <>
                {Object.keys(errors).map(function (element: any) {
                    return (
                        <>
                            <>
                                {geti18nText('service.information.table.import.error') +
                                    ' ' +
                                    element +
                                    ' - ' +
                                    errors[element]}
                            </>
                            <br />
                        </>
                    );
                })}
            </>
        );
    }

    function importData(file: any) {
        setLoadingImport(true);
        var formData = new FormData();
        formData.append('file', file.file);
        NyRequestResolver.requestPostFile(CONSTANTS_REQ.SERVICE_INFORMATION.IMPORT_DATA, undefined, formData).then(
            (result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    file.onSuccess('');
                    calculateImported();

                    okNotification(
                        geti18nText('currency.denomination.import.data.OK'),
                        geti18nText('service.information.table.import.success.record') +
                            ' ' +
                            result.data.updated +
                            ' ' +
                            geti18nText('service.information.table.import.success.record.total')
                    );
                    setLoadingImport(false);
                } else {
                    if (result && result.status === RESPONSE.INTERNAL_SERVER_ERROR) {
                        let message = '';

                        warning(getErrorContent(result.data.errors));
                        calculateImported();
                        setLoadingImport(false);
                    } else {
                        error(geti18nText('service.information.table.import.error.bad.request'));
                        setLoadingImport(false);
                    }
                }
            }
        );
    }
    function onChangeVendor(val: any) {
        if (val.id != -1) {
            setAdded({ serviceVendorId: val.id });
        } else {
            form.setFieldsValue({ serviceVendor: null });
            setAdded(undefined);
        }
        calculateImported();
    }

    const deviceModels = () => {
        const models = NyUtils.load('DEVICE_MODELS');
        let types: any = [];
        models.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });
        return types;
    };
    const manufacturers = () => {
        const manufacturers = NyUtils.load('MANUFACTURERS');
        let types: any = [];
        manufacturers.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });

        return types;
    };

    const getDeviceTypes = async () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.HNB_DEVICE_TYPE.LIST_ALL, undefined).then((res: any) => {
            let types: any = [];
            if (res.status === RESPONSE.OK && res.data) {
                const aTypes: any = res.data;
                aTypes.forEach((element: any) => {
                    let ret: any = {};
                    ret.id = element.id;
                    ret.text = element.name;
                    types.push(ret);
                });
            }
            setDeviceTypes(types);
        });
    };

    const offices = () => {
        const manufacturers = NyUtils.load('OFFICE');
        let types: any = [];
        manufacturers.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });

        return types;
    };

    const deviceLocation = () => {
        const aTypes = GetEnum({ enumName: 'HNB_DEVICE_LOCATION' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: '', text: '' };
                ret.id = aTypes[key];
                ret.text = geti18nText('hnb.device.location.' + aTypes[key]);
                types.push(ret);
            }
        }
        return types;
    };

    function header() {
        let headers: { label: any; key: any }[] | undefined = [];

        if (columns != null) {
            columns.map((item: any) => {
                let itemIndex;

                if (item.dataIndex instanceof Array) {
                    itemIndex = `${item.dataIndex[0]}.${item.dataIndex[1]}`;
                } else {
                    itemIndex = item.dataIndex;
                }

                headers?.push({ label: item.title, key: itemIndex });
            });
        }
        return headers;
    }

    const columns: any = [
        { title: geti18nText('service.information.table.column.id'), dataIndex: 'id' },
        { title: geti18nText('service.information.table.column.name'), dataIndex: 'name' },
        { title: geti18nText('service.information.table.column.model'), dataIndex: ['model', 'name'] },
        { title: geti18nText('device.table.column.office'), dataIndex: ['office', 'name'] },
        {
            title: geti18nText('office.table.column.hnb_unit_code'),
            dataIndex: ['office', 'hnbUnitCode'],
        },
        { title: geti18nText('service.information.table.column.inventory.number'), dataIndex: 'inventoryNumber' },
        {
            title: geti18nText('device.edit.connection_type'),
            dataIndex: 'connectionType',
        },
        {
            title: geti18nText('device.edit.device.mode'),
            dataIndex: 'mode',
        },
        {
            title: geti18nText('device.edit.pbz.id'),
            dataIndex: 'pbzId',
        },
        {
            title: geti18nText('device.edit.moxa_serial_number'),
            dataIndex: 'moxaSerialNumber',
        },
        {
            title: geti18nText('device.edit.maintenance.contract'),
            dataIndex: 'maintenanceContractDate',
        },

        {
            title: geti18nText('device.table.column.vendor'),
            dataIndex: ['vendor', 'name'],
        },
        {
            title: geti18nText('device.edit.tab.details.service.vendor'),
            dataIndex: ['service_vendor', 'name'],
        },
        {
            title: geti18nText('device.edit.warranty'),
            dataIndex: 'warranty_date',
        },
        { title: geti18nText('service.information.table.column.manufacturer'), dataIndex: ['manufacturer', 'name'] },
        { title: geti18nText('service.information.table.column.hnb.code'), dataIndex: ['hnbDevice', 'code'] },
        {
            title: geti18nText('service.information.table.column.device.type'),
            dataIndex: ['hnbDevice', 'hnbDeviceType'],
        },
        {
            title: geti18nText('service.information.table.column.location.type'),
            dataIndex: ['hnbDevice', 'hnbDeviceLocation'],
        },
        { title: geti18nText('service.information.table.column.softver.eur'), dataIndex: ['hnbDevice', 'softwareEur'] },
        { title: geti18nText('service.information.table.column.softver.hrk'), dataIndex: ['hnbDevice', 'softwareHr'] },
        { title: geti18nText('service.information.table.column.count.state'), dataIndex: 'programCountState' },
        {
            title: geti18nText('service.information.table.column.maintenance.date'),
            dataIndex: ['hnbDevice', 'maintenanceDate'],
        },
        {
            title: geti18nText('service.information.table.column.maintenance.comment'),
            dataIndex: ['hnbDevice', 'comment'],
        },
    ];

    return (
        <>
            <Divider orientation="left">
                <b>{geti18nText('service.information.table.header')}</b>
            </Divider>
            <Form form={form}>
                <Row>
                    <Col span={20}>
                        <Form.Item label={geti18nText('device.table.column.service.vendor')} name="serviceVendor">
                            <NySearchField
                                url={CONSTANTS_REQ.SERVICE_VENDOR.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                style={{ width: '30%' }}
                                onChange={onChangeVendor}
                                AddNewModalComponent={ServiceVendorEdit}
                            />
                        </Form.Item>
                    </Col>
                    <Button loading={loading} icon={<DownloadOutlined />} onClick={exportDataCsv} type="primary">
                        {geti18nText('currency.download')}
                    </Button>
                    <CSVLink
                        data={exportData}
                        target="_blank"
                        separator={';'}
                        headers={header()}
                        ref={csvLinkEl}
                        filename={
                            (form.getFieldValue('serviceVendor') != null
                                ? form.getFieldValue('serviceVendor').name
                                : 'all_devices') +
                            '_' +
                            new Date().toLocaleDateString(NyUtils.getSelectedLocale(), getTimezone()) +
                            'csv'
                        }
                    ></CSVLink>

                    <Upload customRequest={importData} showUploadList={false}>
                        <Button
                            type="primary"
                            loading={loadingImport}
                            icon={<UploadOutlined />}
                            style={{ marginLeft: '10px' }}
                        >
                            {geti18nText('currency.upload')}
                        </Button>
                    </Upload>
                </Row>
            </Form>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.LIST}
                readonly={true}
                hideButtons={true}
                setDefaultPageSize={20}
                addedData={added}
                scroll={{ x: 2800 }}
                fetchWhenChange={imported}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('service.information.table.column.id'),
                        dataIndex: 'id',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('service.information.table.column.name'),
                        dataIndex: 'name',
                        width: '5%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    <div style={{ display: 'inline' }}>{record.name}</div>

                                    <div style={{ display: 'inline', marginLeft: '5px' }}>
                                        {record.hasComments == true ? (
                                            <CommentComponent id={record.id}></CommentComponent>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('service.information.table.column.model'),
                        dataIndex: ['model', 'name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(deviceModels()),
                    },
                    {
                        title: geti18nText('device.table.column.office'),
                        dataIndex: ['office', 'name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(offices()),
                    },
                    {
                        title: geti18nText('office.table.column.hnb_unit_code'),
                        dataIndex: ['office', 'hnbUnitCode'],
                        ...getColumnSearch('string'),
                        sorter: (a: any, b: any) => {},
                    },
                    {
                        title: geti18nText('service.information.table.column.inventory.number'),
                        dataIndex: 'inventoryNumber',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('device.edit.connection_type'),
                        dataIndex: 'connectionType',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.connectionType != null) {
                                return (
                                    <div>{geti18nText('app.enum.DEVICE_CONNECTION_TYPE.' + record.connectionType)}</div>
                                );
                            }
                        },
                    },

                    {
                        title: geti18nText('device.edit.device.mode'),
                        dataIndex: 'mode',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('device.edit.pbz.id'),
                        dataIndex: 'pbzId',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('device.edit.moxa_serial_number'),
                        dataIndex: 'moxaSerialNumber',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('device.table.column.vendor'),
                        dataIndex: ['vendor', 'name'],

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(deviceModels()),
                    },
                    {
                        title: geti18nText('device.edit.maintenance.contract'),
                        dataIndex: 'maintenanceContractDate',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.maintenanceContractDate) {
                                return (
                                    <div>
                                        {new Date(record.maintenanceContractDate).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(deviceModels()),
                    },
                    {
                        title: geti18nText('device.edit.tab.details.service.vendor'),
                        dataIndex: ['serviceVendor', 'name'],

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('device.edit.warranty'),
                        dataIndex: 'warranty_date',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.warranty_date) {
                                return (
                                    <div>
                                        {new Date(record.warranty_date).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(deviceModels()),
                    },
                    {
                        title: geti18nText('service.information.table.column.manufacturer'),
                        dataIndex: ['manufacturer', 'name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(manufacturers()),
                    },
                    {
                        title: geti18nText('service.information.table.column.hnb.code'),
                        dataIndex: ['hnbDevice', 'code'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('service.information.table.column.device.type'),
                        dataIndex: ['hnbDevice', 'hnbDeviceType'],
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.hnbDevice && record.hnbDevice.hnbDeviceType) {
                                if (deviceTypes != undefined) {
                                    const typeItemIndex = deviceTypes.findIndex(
                                        (x: any) => x.id == record.hnbDevice.hnbDeviceType
                                    );

                                    if (typeItemIndex > -1) {
                                        return <div>{deviceTypes[typeItemIndex].text}</div>;
                                    }
                                } else {
                                    return (
                                        <div>{geti18nText('hnb.device.type.' + record.hnbDevice.hnbDeviceType)}</div>
                                    );
                                }
                            }
                        },
                        ...getColumnSearchOption(deviceTypes),
                    },
                    {
                        title: geti18nText('service.information.table.column.location.type'),
                        dataIndex: ['hnbDevice', 'hnbDeviceLocation'],
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.hnbDevice && record.hnbDevice.hnbDeviceLocation) {
                                return (
                                    <div>
                                        {geti18nText('hnb.device.location.' + record.hnbDevice.hnbDeviceLocation)}
                                    </div>
                                );
                            }
                        },
                        ...getColumnSearchOption(deviceLocation()),
                    },
                    {
                        title: geti18nText('service.information.table.column.softver.eur'),
                        dataIndex: ['hnbDevice', 'softwareEur'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        align: 'right',
                    },
                    {
                        title: geti18nText('service.information.table.column.softver.hrk'),
                        dataIndex: ['hnbDevice', 'softwareHr'],
                        align: 'right',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('service.information.table.column.count.state'),
                        dataIndex: 'programCountState',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.programCountState) {
                                return <div>{NyUtils.formatNumber(record.programCountState)}</div>;
                            }
                        },
                    },

                    {
                        title: geti18nText('service.information.table.column.maintenance.date'),
                        dataIndex: ['hnbDevice', 'maintenanceDate'],
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.hnbDevice && record.hnbDevice.maintenanceDate) {
                                return (
                                    <div>
                                        {new Date(record.hnbDevice.maintenanceDate).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                ]}
            />
        </>
    );
};

export default AdminServiceInformationIndex;
