import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DesktopOutlined,
    HistoryOutlined,
    PlayCircleOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Row,
    Statistic,
    Tabs,
    Tag,
    Typography,
} from 'antd';
import { truncate } from 'fs';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SSE from '../../components/layout-main/see';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { formatCurrency, getTimezone } from '../../utils/Utils';
import AdminReferenceEdit from '../admin/device/editReferece';
import CountingIndex, { DetailsCountingCSVColCustomization } from '../counting';
import CountingDetailsIndex from '../counting/details';
import DeviceCountReportIndex from '../device-count-report-preview';
import DeviceArchiveIndex from '../device_archive';
import DeviceErrorIndex from '../device_error';
import DeviceInfoIndex from '../device_information';
import HnbDevicePreview from '../hnb/edit_preview';
import StatisticChartIndex from '../statistic/StatisticIndex';
import LiveCounting from './liveCounting';
import DeviceFirmwareUpdateIndex from '../admin/firmware/update/deviceFirmwareIndex';
import AdminDeviceLogIndex from '../admin/downloadDeviceLog';
import CountSerialIndex from '../serial';
import DeviceCountSerialIndex from '../serial/index_device';
const { Text } = Typography;
const DeviceEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('bank.edit.new'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [deviceId, setDeviceId] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const [deviceItem, setDeviceItem] = useState<any>(null);
    const [deviceInfoModalVisible, setDeviceInfoModalVisible] = useState(false);
    const [deviceLifeCountingShow, setDeviceLifeCountingShow] = useState(false);
    const [deviceArchiveModalVisible, setDeviceArchiveModalVisible] = useState(false);
    const eventSourceRef = useRef<any>(null);
    const [countType, setCountType] = useState<any>(null);
    const [deviceReference, refreshDeviceReference] = useState<number>(0);
    const [countigRefresh, setCountigRefresh] = useState<any>(null);

    const onModalClose = () => {
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setCountType(null);
        if (dataForm.model) {
            if (dataForm.model.image != null) {
                dataForm.model.image = 'data:image/jpeg;base64,' + dataForm.model.image;
            }
        }

        setEditHeader(dataForm.name);
        setDeviceItem(dataForm);
        setDeviceId(dataForm.id);
    }

    const deviceLifeCountingTitle = () => {
        var title = geti18nText('device.live.counting.view.title');
        if (deviceItem != null) {
            title = title + ': ' + deviceItem['id'] + ', ' + deviceItem['name'];
            if (deviceItem['office']) {
                title = title + ', ' + deviceItem['office']['name'];
            }
            if (deviceItem['serialNumber']) {
                title = title + ', ' + deviceItem['serialNumber'];
            }
        }
        return title;
    };

    function startListeningForDeviceInfo() {
        console.log('---');
        if (NySession.isUserAuthenticated()) {
            eventSourceRef.current = new SSE(CONSTANTS_REQ.DEVICE.GET_DEVICE_INFO + '?deviceId=' + deviceId, {
                headers: {
                    Authorization: 'Bearer ' + NySession.getUserToken(),
                },
            });
            eventSourceRef.current.onmessage = (e: any) => {
                console.log(e);
            };

            eventSourceRef.current.onerror = (e: any) => {
                console.log('sse error');
                console.log(e);
                eventSourceRef.current.close();
            };

            eventSourceRef.current.stream();
        }
    }

    function setDefaultFilterValue() {
        return [
            { field: 'device_id', condition: 'equals', value: props.value.id },
            { field: 'countingType', condition: 'equals', value: countType },
        ];
    }

    const currencyes = () => {
        const currency = NyUtils.load('CURRENCY');
        let types: any = [];
        currency.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.currencyCode;
            ret.text = element.currencyCode;
            types.push(ret);
        });
        return types;
    };
    function addedFilter() {
        if (deviceItem && deviceItem.model) {
            return [{ field: 'device_model_id', condition: 'equals', value: deviceItem.model.id }];
        } else return [];
    }

    function csvFileNameLocationHistory() {
        return geti18nText('device.loc.hist.table.title') + '_' + deviceId + '_' + deviceItem.name;
    }

    const IpHistory = (props: any) => {
        const [ipContent, setIpContent] = useState<any>(undefined);
        function ipHistory(dateFrom: any, id: any) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE_ARCHIVE.IP_FROM_RANGE, {
                deviceId: deviceItem.id,
                recordId: id,
                timestampFrom: dateFrom,
            }).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    setIpContent(response.data.ip);
                } else {
                    setIpContent(null);
                }
            });
        }
        return (
            <React.Fragment>
                <Popover
                    content={
                        <React.Fragment>
                            {ipContent &&
                                ipContent.map((item: any) => {
                                    return (
                                        <React.Fragment>
                                            <div>
                                                {item.ip} {'-'}{' '}
                                                {new Date(item.validMin).toLocaleString(
                                                    NyUtils.getSelectedLocale(),
                                                    getTimezone()
                                                )}
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                        </React.Fragment>
                    }
                    title={geti18nText('device.edit.tab.history.loc.title')}
                >
                    <DesktopOutlined
                        style={{ fontSize: '14px', color: 'green' }}
                        onMouseOver={() => ipHistory(props.dateFrom, props.id)}
                    />
                </Popover>
            </React.Fragment>
        );
    };

    function deviceReferenceDelete(reference: any) {
        if (reference.id == null) return;

        NyRequestResolver.requestDelete(CONSTANTS_REQ.REFERENCES.DELETE + '/' + reference.id).then((result) => {
            if (result.status === 200) {
                notification.success({
                    message: geti18nText('app.default.delete.ok'),
                    description: geti18nText('app.default.delete.ok.desc'),
                    duration: 3,
                });
                refreshDeviceReference(new Date().getTime());
            } else {
                notification.error({
                    message: geti18nText('app.default.delete.nok'),
                    duration: 3,
                });
            }
        });
    }

    const DeviceLocationCSVColCustomization: any = () => {
        return [
            {
                date: (value: string) => {
                    return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
                },
            },
            {
                address: (value: any) => {
                    return value;
                },
            },
        ];
    };
    const DeviceLocationHistoryCSVColumnsExport: any = () => {
        return [
            {
                title: geti18nText('device.loc.hist.table.column.id'),
                dataIndex: 'id',
            },
            {
                title: geti18nText('device.loc.hist.table.column.office'),
                dataIndex: 'officeName',
            },
            {
                title: geti18nText('device.loc.hist.table.column.address'),
                dataIndex: 'address',
            },
            {
                title: geti18nText('device.loc.hist.table.column.ip'),
                dataIndex: 'ip',
            },
            {
                title: geti18nText('device.loc.hist.table.column.user'),
                dataIndex: 'username',
            },
            {
                title: geti18nText('device.loc.hist.table.column.date'),
                dataIndex: 'date',
            },
        ];
    };
    function onCancelModalLive() {
        setDeviceLifeCountingShow(false);
    }

    const CountMods = (props: any) => {
        const [detailsVisible, setDetailsVisible] = useState<any>(false);
        const [detailsId, setDetailsId] = useState<any>(undefined);

        function onRowDetails(record: any) {
            setDetailsId(record.id);
            setDetailsVisible(true);
        }

        function csvFileName() {
            const date = new Date();
            const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            return countType + '_' + formatDate;
        }

        function modOnChange(val: any) {
            if (val.id === -1) {
                setCountType(null);
            } else {
                setCountType(val.name.props.children[0].key);
            }
        }

        const customEmployeeRenderOption = (option: any) => (
            <>
                <span key={option.text} style={{ display: 'block' }}>
                    {option.text}
                </span>
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>{option.description}</span>
            </>
        );

        return (
            <>
                <Form form={form}>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label={geti18nText('attendance.edit.employee')}
                                name="mod"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.MODS.SEARCH}
                                    map={{ id: 'id', label: 'name', description: 'description' }}
                                    searchBy="text"
                                    style={{ width: '80%' }}
                                    onChange={modOnChange}
                                    renderOption={customEmployeeRenderOption}
                                    setDefaultFilterValue={addedFilter}
                                    autoFocus={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ width: '100%', marginTop: '10px' }}>
                        <Col span={24}>
                            <NyDataTable
                                url={CONSTANTS_REQ.COUNTING.LIST}
                                hideButtons={true}
                                setDefaultPageSize={20}
                                exportCSV={true}
                                onRowSelect={onRowDetails}
                                CSVFileName={csvFileName()}
                                colCSVCustomization={DetailsCountingCSVColCustomization()}
                                setDefaultFilterValue={setDefaultFilterValue}
                                columns={[
                                    {
                                        title: geti18nText('count.data.table.id'),
                                        dataIndex: 'id',
                                        width: '5%',
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('count.data.table.currency'),
                                        dataIndex: ['currency', 'currency_code'],
                                        width: '5%',

                                        ...getColumnSearchOption(currencyes()),
                                    },
                                    {
                                        title: geti18nText('count.data.table.counting.sum'),
                                        dataIndex: 'sumExport',
                                        align: 'right',
                                        width: '5%',

                                        render: (text: string, record: { [index: string]: any }) => {
                                            if (record.sum) {
                                                return formatCurrency(record.sum, record.currency?.currency_code);
                                            }
                                        },
                                    },
                                    {
                                        title: geti18nText('count.data.table.start.time'),
                                        dataIndex: 'startTime',

                                        sorter: (a: any, b: any) => {},
                                        render: (text: string, record: { [index: string]: any }) => {
                                            if (record.startTime) {
                                                return (
                                                    <div>
                                                        {new Date(record.startTime).toLocaleString(
                                                            NyUtils.getSelectedLocale(),
                                                            getTimezone()
                                                        )}
                                                    </div>
                                                );
                                            }
                                        },
                                        ...getColumnDateOption(true, undefined, undefined, true),
                                    },
                                    {
                                        title: geti18nText('count.data.table.end.time'),
                                        dataIndex: 'endTime',

                                        render: (text: string, record: { [index: string]: any }) => {
                                            if (record.endTime) {
                                                return (
                                                    <div>
                                                        {new Date(record.endTime).toLocaleString(
                                                            NyUtils.getSelectedLocale(),
                                                            getTimezone()
                                                        )}
                                                    </div>
                                                );
                                            }
                                        },
                                        ...getColumnDateOption(true, undefined, undefined, true),
                                    },
                                    {
                                        title: geti18nText('count.data.table.created'),
                                        dataIndex: 'created',
                                        render: (text: string, record: { [index: string]: any }) => {
                                            if (record.created) {
                                                return (
                                                    <div>
                                                        {new Date(record.created).toLocaleString(
                                                            NyUtils.getSelectedLocale(),
                                                            getTimezone()
                                                        )}
                                                    </div>
                                                );
                                            }
                                        },
                                        ...getColumnDateOption(true, undefined, undefined, true),
                                    },
                                    {
                                        title: geti18nText('count.data.table.operator'),
                                        dataIndex: 'operator',
                                        width: '5%',
                                        ...getColumnSearch('string'),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Form>
                <CountingDetailsIndex
                    // deviceId={props.value.id}
                    id={detailsId}
                    visible={detailsVisible}
                    setVisible={setDetailsVisible}
                ></CountingDetailsIndex>
            </>
        );
    };

    const onTabChange = (tab: any) => {
        console.log('device ', deviceId);
        if (tab == '2') {
            setCountigRefresh(new Date());
        }
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            hideButtons={true}
        >
            <Tabs defaultActiveKey="1" type="card" onChange={onTabChange}>
                <TabPane tab={geti18nText('device.edit.tab.info')} key="1">
                    {deviceItem && (
                        <Row>
                            <Col span={6}>
                                <img
                                    width={'220px'}
                                    style={{ paddingLeft: '50px' }}
                                    src={
                                        deviceItem.model != null && deviceItem.model.image != null
                                            ? deviceItem.model.image
                                            : process.env.PUBLIC_URL + 'deviceModels/gfs200.jpeg'
                                    }
                                />
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <Col span={8}>
                                        <Row style={{ marginBottom: '5px' }}>
                                            {deviceItem.active == true ? (
                                                <Tag color="#87d068">
                                                    {geti18nText('device.table.column._active').toUpperCase()}
                                                </Tag>
                                            ) : (
                                                <>
                                                    <Tag color="#f50">
                                                        {geti18nText('device.table.column.inactive').toUpperCase()}
                                                    </Tag>
                                                    {deviceItem.correct == true ? (
                                                        <Tag color="#87d068">
                                                            {geti18nText('device.table.column.valid').toUpperCase()}
                                                        </Tag>
                                                    ) : (
                                                        <>
                                                            <Tag color="#f50">
                                                                {geti18nText(
                                                                    'device.table.column.invalid'
                                                                ).toUpperCase()}
                                                            </Tag>
                                                            {deviceItem.repair == true ? (
                                                                <Tag color="blue">
                                                                    {geti18nText(
                                                                        'device.table.column.repair'
                                                                    ).toUpperCase()}
                                                                </Tag>
                                                            ) : (
                                                                <Tag color="blue">
                                                                    {geti18nText(
                                                                        'device.table.column.no.repair'
                                                                    ).toUpperCase()}
                                                                </Tag>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Row>
                                        {deviceItem.useParser &&
                                            (deviceItem.status == 'ACTIVE' ? (
                                                <Tag color="#87d068">
                                                    {geti18nText('device.message.status.connected')}
                                                </Tag>
                                            ) : deviceItem.status == 'INACTIVE' ? (
                                                <Tag color="#f50">
                                                    {geti18nText('device.message.status.disconnected')}
                                                </Tag>
                                            ) : (
                                                <Tag color="orange">{geti18nText('device.message.status.unknown')}</Tag>
                                            ))}
                                        {NySession.hasAnyRole(['RULE_DEVICE_HISTORY_PREVIEW']) && (
                                            <Button
                                                style={{ marginBottom: '10px', marginRight: '10px' }}
                                                onClick={() => setDeviceArchiveModalVisible(true)}
                                            >
                                                {geti18nText('device.edit.info.device.archive.btn')}
                                                <HistoryOutlined />
                                            </Button>
                                        )}
                                        <Button
                                            type="default"
                                            style={{
                                                marginBottom: '10px',
                                                marginRight: '10px',
                                                backgroundColor: '#02b54c',
                                            }}
                                            onClick={() => {
                                                setDeviceLifeCountingShow(true);
                                                // startListeningForDeviceLiveCounting();
                                            }}
                                        >
                                            {geti18nText('device.live.counting.view')}
                                            <PlayCircleOutlined />
                                        </Button>

                                        <Descriptions column={1}>
                                            <Descriptions.Item label={geti18nText('device.edit.tab.details.name')}>
                                                {deviceItem['name']}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('device.edit.tab.details.model')}>
                                                {deviceItem['model'] != null ? deviceItem['model'].name : ''}
                                            </Descriptions.Item>
                                            {deviceItem['manufacturer'] && (
                                                <Descriptions.Item
                                                    label={geti18nText('device.edit.tab.details.manufacturer')}
                                                >
                                                    {deviceItem['manufacturer'].name}
                                                </Descriptions.Item>
                                            )}

                                            {deviceItem['inventoryNumber'] && (
                                                <Descriptions.Item
                                                    label={geti18nText('device.edit.tab.details.inventory.number')}
                                                >
                                                    {deviceItem['inventoryNumber']}
                                                </Descriptions.Item>
                                            )}
                                            {deviceItem['serialNumber'] && (
                                                <Descriptions.Item
                                                    label={geti18nText('device.table.column.serial.number')}
                                                >
                                                    {deviceItem['serialNumber']}
                                                </Descriptions.Item>
                                            )}

                                            <Descriptions.Item label={geti18nText('device.edit.tab.details.ip')}>
                                                {deviceItem['ip']}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('device.edit.tab.details.port')}>
                                                {deviceItem['port']}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={geti18nText('device.edit.tab.details.last.activity')}
                                            >
                                                {new Date(deviceItem['lastActivity']).toLocaleString(
                                                    NyUtils.getSelectedLocale(),
                                                    getTimezone()
                                                )}
                                            </Descriptions.Item>
                                            {deviceItem['connectingDate'] && (
                                                <Descriptions.Item
                                                    label={geti18nText('device.table.column.last.conncectiongDate')}
                                                >
                                                    {new Date(deviceItem['connectingDate']).toLocaleString(
                                                        NyUtils.getSelectedLocale(),
                                                        getTimezone()
                                                    )}
                                                </Descriptions.Item>
                                            )}
                                            {deviceItem['countingDate'] && (
                                                <Descriptions.Item
                                                    label={geti18nText('device.table.column.last.countingDate')}
                                                >
                                                    {new Date(deviceItem['countingDate']).toLocaleString(
                                                        NyUtils.getSelectedLocale(),
                                                        getTimezone()
                                                    )}
                                                </Descriptions.Item>
                                            )}
                                            <Descriptions.Item label={geti18nText('device.edit.connection_type')}>
                                                {deviceItem['connectionType']}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('device.edit.device.mode')}>
                                                {deviceItem['mode']}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('device.edit.pbz.id')}>
                                                {deviceItem['pbzId']}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('device.edit.moxa_serial_number')}>
                                                {deviceItem['moxaSerialNumber']}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('device.table.column.ecb.label')}>
                                                {deviceItem['ecb'] === true
                                                    ? geti18nText('device.active.label')
                                                    : geti18nText('device.inactive.label')}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>

                                    {deviceItem['office'] && (
                                        <Col span={7}>
                                            <Descriptions
                                                column={1}
                                                title={geti18nText('device.edit.tab.details.office')}
                                            >
                                                <Descriptions.Item
                                                    label={geti18nText('device.edit.tab.details.office.name')}
                                                >
                                                    {deviceItem['office']['name']}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                    label={geti18nText('device.edit.tab.details.office.address')}
                                                >
                                                    {/* {deviceItem['office']['name']},  */}
                                                    {deviceItem['office']['address']}
                                                    {deviceItem['office']['place'] != null
                                                        ? ', ' + deviceItem['office']['place']
                                                        : null}
                                                    {deviceItem['office']['houseNumber'] != null
                                                        ? ', ' + deviceItem['office']['houseNumber']
                                                        : null}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                    label={geti18nText('office.table.column.hnb_unit_code')}
                                                >
                                                    {deviceItem['office']['hnbUnitCode']}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    )}

                                    <Col span={7}>
                                        {deviceItem['deviceGroups'] && (
                                            <Row>
                                                <Descriptions column={1} title={geti18nText('device.group.title')}>
                                                    {deviceItem['deviceGroups'].map((g: any) => {
                                                        return <Descriptions.Item>{g.name}</Descriptions.Item>;
                                                    })}
                                                </Descriptions>
                                                {deviceItem['deviceCompanies'] && (
                                                    <Descriptions column={1} title={geti18nText('device.edit.company')}>
                                                        {deviceItem['deviceCompanies'].map((g: any) => {
                                                            return <Descriptions.Item>{g.name}</Descriptions.Item>;
                                                        })}
                                                    </Descriptions>
                                                )}
                                            </Row>
                                        )}

                                        <Row>
                                            <Descriptions
                                                column={1}
                                                title={geti18nText('device.edit.divider.title.warranty')}
                                            >
                                                {deviceItem['vendor'] && (
                                                    <Descriptions.Item
                                                        label={geti18nText('device.edit.tab.details.vendor')}
                                                    >
                                                        {deviceItem['vendor'].name}
                                                    </Descriptions.Item>
                                                )}
                                                {deviceItem['serviceVendor'] && (
                                                    <Descriptions.Item
                                                        label={geti18nText('device.edit.tab.details.service.vendor')}
                                                    >
                                                        {deviceItem['serviceVendor'].name}
                                                    </Descriptions.Item>
                                                )}
                                                {deviceItem['maintenanceContractDate'] && (
                                                    <Descriptions.Item
                                                        label={geti18nText('device.edit.maintenance.contract')}
                                                    >
                                                        {new Date(deviceItem['maintenanceContractDate']).toLocaleString(
                                                            NyUtils.getSelectedLocale(),
                                                            getTimezone()
                                                        )}
                                                    </Descriptions.Item>
                                                )}
                                                {deviceItem['warrantyDate'] && (
                                                    <Descriptions.Item label={geti18nText('device.edit.warranty')}>
                                                        {new Date(deviceItem['warrantyDate']).toLocaleString(
                                                            NyUtils.getSelectedLocale(),
                                                            getTimezone()
                                                        )}
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        </Row>
                                    </Col>
                                </Row>

                                {(deviceItem['deviceInformation'] || deviceItem['softwareVersions']) && (
                                    <Divider orientation="left">{geti18nText('device.edit.device_info.title')}</Divider>
                                )}
                                <Row>
                                    {deviceItem['deviceInformation'] &&
                                        deviceItem['deviceInformation'].length > 0 &&
                                        deviceItem['deviceInformation'][0] && (
                                            <Col span={8}>
                                                <Card
                                                    title={geti18nText('device.edit.device_info.card.title')}
                                                    extra={
                                                        <Popover
                                                            content={
                                                                <div>
                                                                    {deviceItem['deviceInformation'].map(
                                                                        (item: any) => {
                                                                            return (
                                                                                <div>
                                                                                    <Text>
                                                                                        {item.manufacturer +
                                                                                            ' \t\t ' +
                                                                                            item.model +
                                                                                            ' \t\t ' +
                                                                                            item.total +
                                                                                            ' \t\t ' +
                                                                                            new Date(
                                                                                                item.created
                                                                                            ).toLocaleString(
                                                                                                NyUtils.getSelectedLocale(),
                                                                                                getTimezone()
                                                                                            )}
                                                                                    </Text>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'end',
                                                                            display: 'block',
                                                                        }}
                                                                    >
                                                                        <a
                                                                            onClick={() => {
                                                                                setDeviceInfoModalVisible(true);
                                                                            }}
                                                                        >
                                                                            {geti18nText('device.edit.more')}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <a>
                                                                <HistoryOutlined />
                                                            </a>
                                                        </Popover>
                                                    }
                                                    style={{ width: 300 }}
                                                >
                                                    <Descriptions column={1}>
                                                        {deviceItem['deviceInformation'][0]['manufacturer'] && (
                                                            <Descriptions.Item
                                                                label={geti18nText(
                                                                    'device.edit.tab.details.manufacturer'
                                                                )}
                                                            >
                                                                {deviceItem['deviceInformation'][0]['manufacturer']}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['model'] && (
                                                            <Descriptions.Item
                                                                label={geti18nText('device.edit.tab.details.model')}
                                                            >
                                                                {deviceItem['deviceInformation'][0]['model']}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['total'] && (
                                                            <Descriptions.Item
                                                                label={geti18nText(
                                                                    'device.edit.device_info.card.total'
                                                                )}
                                                            >
                                                                {deviceItem['deviceInformation'][0]['total']}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['created'] && (
                                                            <Descriptions.Item
                                                                label={geti18nText(
                                                                    'device.edit.device_info.card.created'
                                                                )}
                                                            >
                                                                {new Date(
                                                                    deviceItem['deviceInformation'][0]['created']
                                                                ).toLocaleString(
                                                                    NyUtils.getSelectedLocale(),
                                                                    getTimezone()
                                                                )}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['jam'] && (
                                                            <Descriptions.Item label={'jam'}>
                                                                {deviceItem['deviceInformation'][0]['jam']}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['feeding'] && (
                                                            <Descriptions.Item label={'feeding'}>
                                                                {deviceItem['deviceInformation'][0]['feeding']}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['feed'] && (
                                                            <Descriptions.Item label={'feed'}>
                                                                {deviceItem['deviceInformation'][0]['feed']}
                                                            </Descriptions.Item>
                                                        )}
                                                        {deviceItem['deviceInformation'][0]['pocket'] && (
                                                            <Descriptions.Item label={'pocket'}>
                                                                {deviceItem['deviceInformation'][0]['pocket']}
                                                            </Descriptions.Item>
                                                        )}
                                                    </Descriptions>
                                                </Card>
                                            </Col>
                                        )}

                                    {deviceItem['softwareVersions'] && deviceItem['softwareVersions'].length > 0 && (
                                        <Col span={10}>
                                            <Card title={geti18nText('device.edit.software.title')}>
                                                <Descriptions column={1}>
                                                    {deviceItem['softwareVersions'].map((g: any) => {
                                                        return (
                                                            <Descriptions.Item label={g.module}>
                                                                {g.firmware}
                                                            </Descriptions.Item>
                                                        );
                                                    })}
                                                </Descriptions>
                                            </Card>
                                        </Col>
                                    )}
                                    {/* <Col>
                                        <Button onClick={startListeningForDeviceInfo}>{'Get device info'}</Button>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Modal
                        title={geti18nText('device.edit.device_info.card.title')}
                        visible={deviceInfoModalVisible}
                        onCancel={() => {
                            setDeviceInfoModalVisible(false);
                        }}
                        onOk={() => {
                            setDeviceInfoModalVisible(false);
                        }}
                        destroyOnClose={true}
                        okText={geti18nText('customer.edit.ModalOkText')}
                        width={'900px'}
                    >
                        {deviceItem && <DeviceInfoIndex device_id={deviceItem['id']}></DeviceInfoIndex>}
                    </Modal>

                    <Modal
                        title={geti18nText('device.edit.info.device.archive.title')}
                        visible={deviceArchiveModalVisible}
                        onCancel={() => {
                            setDeviceArchiveModalVisible(false);
                        }}
                        onOk={() => {
                            setDeviceArchiveModalVisible(false);
                        }}
                        destroyOnClose={true}
                        okText={geti18nText('customer.edit.ModalOkText')}
                        width={1400}
                    >
                        {deviceItem && <DeviceArchiveIndex deviceId={deviceItem['id']}></DeviceArchiveIndex>}
                    </Modal>
                </TabPane>
                <TabPane tab={geti18nText('counting.panel.tab')} key="2">
                    <CountingIndex countigRefresh={countigRefresh} {...props}></CountingIndex>
                </TabPane>
                <TabPane tab={geti18nText('device.edit.tab.counting.report')} key="3">
                    <DeviceCountReportIndex {...props}></DeviceCountReportIndex>
                </TabPane>
                {/* <TabPane tab={geti18nText('device.edit.tab.settings')} key="3"></TabPane> */}
                {/* <TabPane tab={geti18nText('device.edit.tab.logs')} key="4"></TabPane> */}
                {/* <TabPane tab={geti18nText('device.edit.tab.firmware')} key="5"></TabPane> */}
                <TabPane tab={geti18nText('device.edit.tab.locations.history')} key="6">
                    {deviceItem && (
                        <NyDataTable
                            url={CONSTANTS_REQ.DEVICE_LOCATION_HISTORY.LIST}
                            showRecordModal={true}
                            readonly={true}
                            hideButtons={true}
                            addedData={{ deviceId: deviceItem['id'] }}
                            modalComponent={DeviceEdit}
                            exportCSV={true}
                            colCSVCustomization={DeviceLocationCSVColCustomization()}
                            csvColumns={DeviceLocationHistoryCSVColumnsExport()}
                            CSVFileName={csvFileNameLocationHistory()}
                            setDefaultPageSize={20}
                            columns={[
                                {
                                    title: geti18nText('device.loc.hist.table.column.id'),
                                    dataIndex: 'id',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('device.loc.hist.table.column.office'),
                                    dataIndex: 'officeName',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('office.table.column.hnb_unit_code'),
                                    dataIndex: ['office', 'hnb_unit_code'],

                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('device.loc.hist.table.column.address'),
                                    dataIndex: 'address',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                    render: (text: string, record: { [index: string]: any }) => {
                                        return (
                                            <div>
                                                {record.address
                                                    ? record.address +
                                                      (record.place != null ? ', ' + record.place : '') +
                                                      (record.houseNumber != null ? ', ' + record.houseNumber : '')
                                                    : record.officeName}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: geti18nText('device.loc.hist.table.column.ip'),
                                    dataIndex: 'ip',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                    render: (text: string, record: { [index: string]: any }) => {
                                        if (record.ip) {
                                            return (
                                                <div>
                                                    <div style={{ display: 'inline' }}>{record.ip}</div>

                                                    {
                                                        <div style={{ display: 'inline', marginLeft: '5px' }}>
                                                            {
                                                                <IpHistory
                                                                    dateFrom={record.date}
                                                                    id={record.id}
                                                                ></IpHistory>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        }
                                    },
                                },
                                {
                                    title: geti18nText('device.loc.hist.table.column.user'),
                                    dataIndex: 'username',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('device.loc.hist.table.column.date'),
                                    dataIndex: 'date',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnDateOption(true, undefined, undefined, true),
                                    render: (text: string, record: { [index: string]: any }) => {
                                        if (record.date) {
                                            return (
                                                <div>
                                                    {new Date(record.date).toLocaleString(
                                                        NyUtils.getSelectedLocale(),
                                                        getTimezone()
                                                    )}
                                                </div>
                                            );
                                        }
                                    },
                                },
                                {
                                    title: geti18nText('device.loc.hist.table.column.active'),
                                    dataIndex: 'active',
                                    width: '10%',
                                    render: (text: any, record: { active: any }) => {
                                        if (record.active) {
                                            return <CheckOutlined style={{ color: 'green' }} />;
                                        } else {
                                            return <CloseOutlined style={{ color: 'red' }} />;
                                        }
                                    },
                                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                                },
                            ]}
                        />
                    )}
                </TabPane>
                <TabPane tab={geti18nText('device.edit.tab.reference')} key="7">
                    {deviceItem && (
                        <NyDataTable
                            headerTitle={geti18nText('device.locations.header')}
                            url={CONSTANTS_REQ.REFERENCES.LIST_BY_DEVICE}
                            showRecordModal={true}
                            readonly={!NySession.hasAnyRole(['UPDATE_COMMENTS'])}
                            hideNewButton={!NySession.hasAnyRole(['UPDATE_COMMENTS'])}
                            fetchWhenChange={deviceReference}
                            modalComponent={AdminReferenceEdit}
                            addedData={{ deviceId: deviceItem['id'] }}
                            setDefaultPageSize={20}
                            columns={[
                                {
                                    title: geti18nText('references.table.column.content'),
                                    dataIndex: 'id',
                                    width: '10%',
                                    sorter: (a: any, b: any) => {},
                                    render: (text: string, record: { [index: string]: any }) => {
                                        if (record.text) {
                                            return (
                                                <>
                                                    <span style={{ whiteSpace: 'pre-line' }}>{record.text}</span>
                                                    <div>
                                                        <UserOutlined /> {record.username}{' '}
                                                        {new Date(record.created).toLocaleString(
                                                            NyUtils.getSelectedLocale(),
                                                            getTimezone()
                                                        )}
                                                        {NySession.hasAnyRole(['UPDATE_COMMENTS']) && (
                                                            <>
                                                                <Popconfirm
                                                                    placement="rightTop"
                                                                    title={geti18nText('app.default.button.delete')}
                                                                    okText={geti18nText('app.default.button.yes')}
                                                                    cancelText={geti18nText('app.default.button.no')}
                                                                    onConfirm={(e) => deviceReferenceDelete(record)}
                                                                >
                                                                    <DeleteOutlined
                                                                        style={{
                                                                            fontSize: '20px',
                                                                            color: 'red',
                                                                            marginRight: '10px',
                                                                            marginBottom: '10px',
                                                                            float: 'right',
                                                                        }}
                                                                    />
                                                                </Popconfirm>
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        }
                                    },
                                },
                            ]}
                        />
                    )}
                </TabPane>
                {deviceItem != null && (
                    <TabPane tab={geti18nText('device.edit.tab.details.error')} key="8">
                        <DeviceErrorIndex deviceId={deviceItem['id']}></DeviceErrorIndex>
                    </TabPane>
                )}
                {deviceItem != null && (
                    <TabPane tab={geti18nText('hnb.device.table.header')} key="9">
                        <HnbDevicePreview deviceId={deviceItem['id']}></HnbDevicePreview>
                    </TabPane>
                )}
                {deviceItem != null && (
                    <TabPane tab={geti18nText('device.edit.tab.statistic')} key="10">
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={24}>
                                <StatisticChartIndex
                                    deviceName={deviceItem['name']}
                                    deviceId={deviceItem['id']}
                                ></StatisticChartIndex>
                            </Col>
                        </Row>
                    </TabPane>
                )}
                {NySession.getSetting('mod')['exist'] === true && (
                    <TabPane tab={geti18nText('mods.header')} key="11">
                        <CountMods></CountMods>
                    </TabPane>
                )}
                {deviceItem != null && NySession.hasAnyRole(['RULE_UPDATE_FIRMWARE']) && (
                    <TabPane tab={geti18nText('device.edit.tab.firmware')} key="12">
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={24}>
                                <DeviceFirmwareUpdateIndex
                                    deviceName={deviceItem['name']}
                                    deviceId={deviceItem['id']}
                                ></DeviceFirmwareUpdateIndex>
                            </Col>
                        </Row>
                    </TabPane>
                )}
                {deviceItem != null && NySession.hasAnyRole(['RULE_DEVICE_LOG_PREVIEW']) && (
                    <TabPane tab={geti18nText('device.edit.tab.log.request')} key="13">
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={24}>
                                <AdminDeviceLogIndex
                                    deviceName={deviceItem['name']}
                                    deviceId={deviceItem['id']}
                                    useParser={deviceItem['useParser']}
                                ></AdminDeviceLogIndex>
                            </Col>
                        </Row>
                    </TabPane>
                )}
                {deviceItem != null && (
                    <TabPane tab={geti18nText('device.edit.tab.log.serial')} key="14">
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={24}>
                                <DeviceCountSerialIndex
                                    deviceName={deviceItem['name']}
                                    deviceId={deviceItem['id']}
                                ></DeviceCountSerialIndex>
                            </Col>
                        </Row>
                    </TabPane>
                )}
            </Tabs>
            <Modal
                title={deviceLifeCountingTitle()}
                visible={deviceLifeCountingShow}
                onCancel={() => {
                    onCancelModalLive();
                }}
                style={{ top: '0' }}
                destroyOnClose={true}
                footer={
                    <Button
                        type="default"
                        onClick={() => {
                            onCancelModalLive();
                        }}
                    >
                        {geti18nText('app.default.button.close')}
                    </Button>
                }
                width={'100vw'}
            >
                <LiveCounting deviceId={deviceId}></LiveCounting>
            </Modal>
        </NyDataEdit>
    );
};

export default DeviceEdit;
