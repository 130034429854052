import React, { useState } from 'react';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface iNyFileUpload {
    accept?: 'pdf' | 'zip';
    multiple?: boolean;
    files: [];
    setFiles: (file: any) => void;
}

const NyFileUpload = ({ accept, multiple, files, setFiles }: iNyFileUpload) => {
    const [fileNames, setFileNames] = useState<any>([]);

    const uploadData = {
        name: 'attachment',
        accept: accept === 'pdf' ? '.pdf' : accept === 'zip' ? '.zip' : undefined,
        showUploadList: true,
        multiple: multiple != undefined ? multiple : false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onRemove: (file: any) => {
            let newFileNames = [...fileNames];
            let newFiles = [...files];

            var index = newFileNames.indexOf(file.name);
            if (index !== -1) {
                newFileNames.splice(index, 1);
                newFiles.splice(index, 1);
            }

            if (multiple != undefined && multiple) {
                setFileNames(newFileNames);
                setFiles(newFiles);
            } else {
                setFileNames([]);
                setFiles([]);
            }
        },
        beforeUpload: (file: any) => {
            if (accept === 'pdf' && file.type !== 'application/pdf') {
                message.error(file.name + ' ' + geti18nText('attachment.type.error'));
                return true;
            } else {
                if (accept === 'pdf' && file.size > 7340032) {
                    message.error(file.name + ' ' + geti18nText('attachment.size.error'));
                    return true;
                }

                if (multiple != undefined && multiple) {
                    setFiles([...files, file]);
                    setFileNames([...fileNames, file.name]);
                } else {
                    setFiles([file]);
                    setFileNames([file.name]);
                }
                return false;
            }
        },
        fileNames,
    };

    return (
        <>
            <Upload {...uploadData} fileList={files}>
                <Button icon={<UploadOutlined />}>{geti18nText('attachment.select.button')}</Button>
            </Upload>
        </>
    );
};

export default NyFileUpload;
